import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import axiosFee from '../../../../../../../network/axios-fee-estimation';
import axiosBtc from '../../../../../../../network/axios-btc-node';

const styles = theme => ({
  root: {
    display: "block",
    width: "100%",
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    marginRight: theme.spacing(1)
  },
  formControl: {
    width: '100%'
  }
});

class FeeSelector extends Component {
  constructor(props){
    super(props);
    this.state = {
      blocks: '',
      enabled: props.valid,
      error: false,
      labelWidth: 0
    };
    // Object used to map buckets (denominated in blocks until confirmation) to fee amounts
    this.feeMap = new Map();
  }

  componentWillReceiveProps(nextProps){
    if(this.props.enabled !== nextProps.enabled){
      this.setState({enabled: nextProps.enabled});
    }
  }

  handleChange = (event) => {
    this.props.handleFeeSelection(+event.target.value);
    this.setState({blocks: event.target.value});
  }

  componentDidMount() {
    // this.props.fee_buckets.map(bucket => {
    //   return axiosFee.get(`/n/${bucket.blocks}`)
    //       .then((response) => {
    //         const splitted = response.request.responseURL.split('/');
    //         this.feeMap.set(+splitted[splitted.length - 1], response.data);
    //         this.props.handleFeeMapUpdate(this.feeMap);
    //       })
    //       .catch((e) => {
    //         console.error(e);
    //         this.setState({error:true});
    //       })
    // });
    this.props.fee_buckets.forEach(bucket => {
      // Sends a fee etimation request to the bcoin node.
      // More info: http://bcoin.io/api-docs/index.html?shell--curl#estimatesmartfee
      axiosBtc.post('/estimatesmartfee', {method: 'estimatesmartfee', params:[bucket.blocks]})
        .then(response => {
          const { result } = response.data;
          this.feeMap.set(result.blocks, result.fee);
          this.props.handleFeeMapUpdate(this.feeMap);
        })
        .catch(e => {
          console.error('Error while trying to obtain fee estimation data. Msg: ', e);
        });
    });
    this.setState({labelWidth: ReactDOM.findDOMNode(this.InputLabelRef).offsetWidth,});
  }

  render() {
    const { classes } = this.props;
    const options = this.props.fee_buckets.map((bucket, index) => {
      return (<MenuItem
        key={bucket.blocks}
        value={""+bucket.blocks}>{bucket.label}</MenuItem>
      )
    });

    return (
      <div className={classes.root}>
        <FormControl
          variant='outlined'
          className={classes.formControl}
          disabled={!this.state.enabled && !this.state.error}
          error={this.state.error}>
          <InputLabel
            htmlFor="within-simple"
            ref={ref => {
              this.InputLabelRef = ref;
            }}>
            Within
          </InputLabel>
          <Select
            value={this.state.blocks}
            onChange={this.handleChange}
            input={
              <OutlinedInput
                labelWidth={this.state.labelWidth}
                name="within"
                id="within-simple"
              />}>
            {options}
          </Select>
        </FormControl>
      </div>
    );
  }
}

FeeSelector.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(FeeSelector);
