import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import FormGroup from '@material-ui/core/FormGroup';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import './CurrencySelector.css';

const styles = theme => ({
  root: {
    display: "flex"
  },
  formControl: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  }
});

class CurrencySelector extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      coin: props.currency,
      options: props.options
    };
  }

  handleChange = event => {
    let coin = null;
    if(event.target.value === this.state.options.fiat){
      coin = this.state.options.crypto;
    }else{
      coin = this.state.options.fiat;
    }
    this.props.handleCurrencyChange(coin);
    this.setState({coin: coin});
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <FormGroup row>
          <FormControlLabel
            className={classes.formControl}
            label={this.state.coin}
            control={
              <Switch
                checked={this.state.coin === this.state.options.crypto}
                onChange={this.handleChange}
                value={this.state.coin}/>
            }/>
        </FormGroup>
      </div>
    );
  }
}

CurrencySelector.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(CurrencySelector);
