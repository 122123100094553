import React, { Component } from 'react';
import {BrowserRouter} from 'react-router-dom';
import { ScrollManager, WindowScroller } from 'react-scroll-manager';
import { createBrowserHistory as createHistory } from 'history';
import IdleTimer from 'react-idle-timer'
import ReactGA from 'react-ga';
import Wallet from './containers/Wallet/Wallet';
import { APP_TIMEOUT_VALUE, KEY_HAS_ALERT_BEEN_DISMISSED } from './constants';
import './App.css';

// Key used to store the public keys
const KEY_XPUB = Wallet.getKeyXpub();
// Key used to store a timestamp of the last time the user was active.
const KEY_ACTION_TIMESTAMP = 'key_action_timestamp';

class App extends Component {
  constructor(props){
    super(props);
    this.history = createHistory();
    this.idleTimer = null;
    this.state = {
      logs: []
    }
  }

  static getKeyActionTimestamp(){
    return KEY_ACTION_TIMESTAMP;
  }

  static getDefaultTimeoutValue(){
    return APP_TIMEOUT_VALUE;
  }

  onAction = (e) => {
    // Storing the last time the user interacted with the app
    localStorage.setItem(KEY_ACTION_TIMESTAMP, Date.now());
  }

  onActive = (e) => {}

  onIdle = (e) => {
    localStorage.setItem(KEY_XPUB, '');
    // Resetting the Alert state
    localStorage.removeItem(KEY_HAS_ALERT_BEEN_DISMISSED);
    this.forceUpdate();
  }

  initializeReactGA = () => {
    ReactGA.initialize('UA-131183642-1');
  }

  componentDidMount(){
    this.initializeReactGA();
  }

  render() {
    return (
      <div>
        <IdleTimer
          ref={ref => { this.idleTimer = ref }}
          element={document}
          onActive={this.onActive}
          onIdle={this.onIdle}
          onAction={this.onAction}
          debounce={100}
          timeout={APP_TIMEOUT_VALUE} />
        <ScrollManager history={this.history}>
          <BrowserRouter>
            <WindowScroller>
              <Wallet/>
            </WindowScroller>
          </BrowserRouter>
        </ScrollManager>
      </div>
    );
  }
}

export default App;
