import React from 'react'
import QRCode from 'qrcode.react';
import PropTypes from 'prop-types';
import { QR_CODE_SIZES } from '../../constants';
import { withStyles } from '@material-ui/core/styles';

// Interval between successive QR code changes
const QR_CODE_INTERVAL = 1200;

const styles = theme => ({
  QRCodeContainer: {
    textAlign: 'center',
    flexGrow: 1
  },
  QRCode: {
    paddingLeft: 0,
    paddingRight: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'block',
    [theme.breakpoints.down('sm')]: {
      marginTop: '15%'
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: '5%'
    }
  }
});

class MultipartQRCode extends React.Component {
  constructor(props){
    super(props);

    const serializedData = props.data ? JSON.stringify(props.data) : '';
    let parts = serializedData.match(/.{1,200}/g);
    if(parts === null) parts = [serializedData];

    this.state = {
      type: this.props.type,
      parts: parts,
      index: 0
    }
  }

  componentDidMount(){
    this.intervalId = setInterval(() => {
      if(this.state.parts.length)
        this.setState((prevState, currentProps) => {
          const index = (prevState.index + 1) % prevState.parts.length;
          prevState.index = index;
          return prevState;
        });
    }, QR_CODE_INTERVAL);
  }

  componentWillUnmount(){
    clearInterval(this.intervalId);
  }

  render() {
    const index = this.state.index;
    let qrCodeContent = {
      type: this.props.type,  // The type of QR code that will be generated
      part: index, // The part of this QR code
      total: this.state.parts.length, // The total number of parts to be passed
      data: this.state.parts[index] // The actual data content of this part
    }
    const { classes } = this.props;
    return (
      <div className={classes.QRCodeContainer}>
        <QRCode
          className={classes.QRCode}
          size={QR_CODE_SIZES.DESKTOP}
          value={JSON.stringify(qrCodeContent)}/>
      </div>
    )
  }
}

MultipartQRCode.propTypes = {
  type: PropTypes.number.isRequired,
  data: PropTypes.object
}

export default withStyles(styles)(MultipartQRCode);
