import React from 'react';
import Button from '@material-ui/core/Button';
import QRCodeStep from './QRCodeStep/QRCodeStep';
import VerifiedAddress from './VerifiedAddressStep/VerifiedAddressStep';
import './Verification.css';

class Verification extends React.Component {
  state = {
    steps: [],
    activeStep: 0
  }

  handleBack = () => {
    this.setState((prevState, currentProps) => {
      prevState.activeStep = prevState.activeStep - 1;
      return prevState;
    });
  }

  handleNext = () => {
    const {activeStep, steps} = this.state;
    if(activeStep < steps.length - 1){
      this.setState((prevState, currentProps) => {
        prevState.activeStep = prevState.activeStep + 1;
        return prevState;
      });
    }
  }

  componentDidMount(){
    console.log('componentDidMount. address bundle: ', this.props.addressBundle);
    if(this.props.addressBundle){
      this.setState({
        steps: [
          <QRCodeStep
            index={0}
            newaddress={this.props.addressBundle}/>,
          <VerifiedAddress
            index={1}
            verifiedaddress={this.props.addressBundle.address}/>
        ]
      });
    }
  }

  render(){
    const {activeStep, steps} = this.state;
    let nextButton = null;
    if(activeStep < steps.length - 1){
      nextButton = (
        <Button
          variant="contained"
          color="primary"
          onClick={this.handleNext}>
          Next
        </Button>
      )
    }
    return (
      <React.Fragment>
        <div>
          {this.state.steps[activeStep]}
        </div>
        <div className="navigation-buttons-container" style={{textAlign: 'right'}}>
          {nextButton}
        </div>
      </React.Fragment>
    )
  }
}

export default Verification;
