import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import LinearProgress from '@material-ui/core/LinearProgress';
import './LoadingModal.css';

/*
* Function used to map a value in the [0,+Infinity] range to the [0,1] range.
*/
const indeterminateMapper = (x) => {
  return 100 * (parseFloat(x) / (100.0 + parseFloat(x)));
}

const loadingModal = (props) => {
  return (
    <Dialog open={props.isLoading} fullWidth>
      <DialogTitle>Loading Wallet</DialogTitle>
      <DialogContent style={{textAlign: 'center'}}>
        <LinearProgress
          color='secondary'
          value={indeterminateMapper(props.progress)}
          variant='determinate'
          style={{margin: '10px'}}/>
        <DialogContentText>This may take several minutes.</DialogContentText>
        <br></br>
        <DialogContentText>Please keep this window active.</DialogContentText>
      </DialogContent>
    </Dialog>
  )
}

export default loadingModal;
