import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import { getBlockExplorerUrl } from '../../util';
import './TxDetailsDialog.css';

/**
* Dialog used to display further ETH tx details.
*/
const txDetailsDialog = props => {
  const tx = props.tx;
  if(tx){
    let feeMultiplier = 1;
    if(tx.internalFeeAmount)
      feeMultiplier = 2; // If there is an SelfTrust fee, then we should account for a second miner fee
    return (
      <Dialog open={props.isOpen} onClose={props.onClose}>
        <DialogTitle id="simple-dialog-title">Details</DialogTitle>
        <DialogContent className='progress-dialog-content'>
          <a target='_blank' rel='noopener noreferrer' href={`${getBlockExplorerUrl('ETH')}/tx/` + tx.hash}>
            <TextField
              disabled
              label='Sent amount'
              margin='dense'
              variant='outlined'
              defaultValue={((parseFloat(tx.value) - 2 * parseFloat(tx.minerFeeAmount) - parseFloat(tx.internalFeeAmount)) / 1e18).toFixed(8) + ' ETH'}
            />
          </a>
          <a target='_blank' rel='noopener noreferrer' href={`${getBlockExplorerUrl('ETH')}/tx/` + tx.internalFeeHash}>
            <TextField
              disabled
              label='SelfTrust fee'
              margin='dense'
              variant='outlined'
              defaultValue={(parseFloat(tx.internalFeeAmount) / 1e18).toFixed(8) + ' ETH'}
            />
          </a>
          <TextField
            disabled
            label='Miner fee'
            margin='dense'
            variant='outlined'
            defaultValue={(feeMultiplier * parseFloat(tx.minerFeeAmount) / 1e18).toFixed(8) + ' ETH'}
          />
          <TextField
            disabled
            label='Total'
            margin='dense'
            variant='filled'
            defaultValue={(tx.value / 1e18).toFixed(8) + ' ETH'}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onClose} color='primary'>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    )
  }else{
    return null;
  }
}

export default txDetailsDialog;
