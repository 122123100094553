import {BLOCK_EXPLORERS, INFURA_URL, INFURA_MAINNET} from './constants.js';

/**
* Function used to dynamically obtain the block explorer URL depending on the
* selected coin and taking in consideration the current network.
*
* @param coin The desired coin, currently supported: 'BTC' & 'ETH'.
*/
export const getBlockExplorerUrl = (coin) => {
  if(coin.toUpperCase() === 'BTC'){
    return BLOCK_EXPLORERS.BTC;
  }else{
    if(INFURA_URL === INFURA_MAINNET){
      return BLOCK_EXPLORERS.ETH.MAIN;
    }else{
      return BLOCK_EXPLORERS.ETH.TEST;
    }
  }
}

/**
* Function used to compare version names. The web app ships with a version name
* description that is characterized as 'local' and it fetches a new one from the
* server, which is described as 'remote'.
* The remote version name is assumed to be more recent and thus takes priority.
*
* @return True if the remote version name is greater than the local one. False
*         in case it is equal or lower.
*/
export const compareVersionNames = (local, remote) => {
  const localArray = local.split('\.').map(i => parseInt(i));
  const remoteArray = remote.split('\.').map(i => parseInt(i));
  const shouldUpdate = remoteArray
    .map((v, index) => v > localArray[index])
    .reduce((accum, current) => accum || current, false);
  return shouldUpdate;
}
