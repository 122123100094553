import Web3 from 'web3';
import BigNumber from 'bignumber.js';
import contractMap from 'eth-contract-metadata';
import images from 'eth-contract-metadata';
import { INFURA_URL } from '../constants';
import erc20ABI from './erc20_abi';

const TEST_TOKENS = [{
  isToken: true,
  address: '0x722dd3F80BAC40c951b51BdD28Dd19d435762180',
  name: 'Test Standard Token',
  symbol: 'TST',
  precision: 18,
  logo: 'test.svg',
  price: 1
},{
  isToken: true,
  address: '0xeCCb0D4cd00a33A96bb0121BBFE74653E42311d9',
  name: 'erc20network',
  symbol: 'ERC20',
  precision: 18,
  logo: '',
  price: 1
}];

/**
* Class used to encapsulate all of the ERC-20 token related operations
*/
class Erc20Wallet {
  constructor(address){
    this.walletAddress = address;
    this.web3 = new Web3(new Web3.providers.HttpProvider(INFURA_URL));
  }

  /**
  * Function used to obtain a filtered list of ERC-20 tokens.
  * The filter criteria used is whether or not the current wallet
  * address has a non-zero balance of a given token.
  */
  filterByBalance = async (tokens) => {
    const networkType = await this.web3.eth.net.getNetworkType();
    if(networkType === 'ropsten'){
      // In case we're running on the ropsten testnet,
      // we just want to check for the TST test token.
      tokens = TEST_TOKENS;
    }

    const promises = tokens.map(async token => {
      const contract = new this.web3.eth.Contract(erc20ABI, token.address);
      try{
        const balance = await contract.methods.balanceOf(this.walletAddress).call();
        token.balance = new BigNumber(balance);
      }catch(error){
        console.warn(`Could not get balance of ${token.symbol}. Msg: ${error}`);
        token.balance = 0;
      }
      return token;
    });
    // Filtering the tokens in order to return only the ones with balance
    return (await Promise.all(promises)).filter(token => token.balance > 0);
  }

  /**
  * Function used to load all registered ERC-20 tokens in memeory.
  * @return   Promise instance that sill resolve in to a list of
  *           known ERC-20 tokens.
  */
  loadTokens(){
    const result = new Promise((resolve, reject) => {
      const tokens = [];
      // Building an array of token objects
      Object.keys(contractMap).forEach(address => {
        const token = contractMap[address];
        this.getTokenSymbol(address);
        tokens.push({
          isToken: true,
          address: address,
          name: token.name,
          symbol: token.symbol ? token.symbol.toLowerCase() : '',
          precision: token.decimals,
          logo: token.logo
        });
      });
      // resolve(this.filterByBalance(tokens));
      resolve([]); // Temporally disabling all supported ERC-20 tokens, uncomment to enable them.
    });
    return result;
  }

  /**
  * Function that returns the registered symbol for an ERC-20 token, if it
  * exists. Otherwise returns null.
  */
  getTokenSymbol(addr){
    const address = this.web3.utils.toChecksumAddress(addr);
    let symbol = null;
    if(contractMap.hasOwnProperty(address)){
      if(contractMap[address].symbol)
        symbol = contractMap[address].symbol.toLowerCase();
    }else{
      // In case we didn't find the token in our map of supported ERC-20 tokens
      // we proceed to test against the test tokens.
      symbol = TEST_TOKENS
        .map(token => token.address.toLowerCase() === address.toLowerCase() ? token.symbol.toLowerCase() : null)
        .filter(sym => sym !== null)
        .reduce((accum, current) => accum || current, null)
    }
    return symbol;
  }

  /**
  * Returns a map of all supported tokens that uses their symbol as
  * mapping index.
  */
  static getTokenLogos(){
    let modifiedMap = new Map();
    Object.keys(contractMap).forEach(address => {
      let entry = contractMap[address];
      modifiedMap.set(entry.symbol, entry);
    });
    TEST_TOKENS.forEach(testToken => modifiedMap.set(testToken.symbol.toUpperCase(), testToken));
    TEST_TOKENS.forEach(testToken => modifiedMap.set(testToken.symbol.toLowerCase(), testToken));
    return modifiedMap;
  }

  /**
  * Decodes an ERC-20 token transfer input data for a 'transfer' call and
  * retrieves the amount being transferred and the destination.
  *
  * @returns  Object in the form {amount: BigNumber, destination: string},
  *           or null if unable to decode.
  */
  static decodeTransfer(data){
    data = data.replace(/^0x/, '');
    let transferData = null;
    const functionName = data.slice(0, 8);
    if(functionName === 'a9059cbb'){
      // This is a call to a 'transfer' function
      // 8 first bytes are the function hash
      // The 20 last bytes of the following 64 are the address
      // The final 64 bytes encode the value
      const destination = data.slice(8 + 24, 8 + 64);
      const amount = data.slice(8 + 64);
      transferData = {
        amount: new BigNumber('0x' + amount),
        destination: '0x' + destination
      }
    }
    return transferData;
  }
}

export default Erc20Wallet;
