import React from 'react';
import Typography from '@material-ui/core/Typography';
import MultipartQRCode from '../../../../../components/MultipartQRCode/MultipartQRCode';
import { withStyles } from '@material-ui/core/styles';
import { QRCodesTypes } from '../../../../../constants';

const styles = theme => ({
  message: {
    margin: '24px',
    textAlign: 'center',
    color: theme.palette.primary.main
  }
})

class QRCodeStep extends React.Component {

  render() {
    const { txBundle, classes } = this.props;
    let content = <h3>Loading..</h3>
    if(txBundle.signature){
      content = (
        <MultipartQRCode
          type={QRCodesTypes.TYPE_TRANSFER_PROPOSAL}
          data={txBundle}/>
      )
    }
    return (
      <React.Fragment>
        <Typography className={classes.message}>
          Scan the following QR code(s) from the SelfTrust Offline app.
        </Typography>
        {content}
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(QRCodeStep);
