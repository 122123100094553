import React from 'react';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Snackbar from '@material-ui/core/Snackbar';
import { withStyles } from '@material-ui/core/styles';
import hash from 'object-hash';
import STService from '../../../../../network/axios-verifier';

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: theme.spacing(1)
  },
  textField: {
    margin: theme.spacing(1)
  },
  instructionText: {
    marginLeft: theme.spacing(1.5)
  },
  buttonGroup: {
    margin: theme.spacing(1)
  },
  selectedButton: {
    color: '#ffffff',
    backgroundColor: '#737272'
  },
  selectableButtonRoot: {
    display: 'flex',
    width: '100%'
  },
  baseButton: {
    width: '50%',
    height: '150px',
    margin: '10px',
    borderRadius: '8px',
    border: '1px solid #c4c4c4',
    display: 'flex',
    flexDirection: 'column'
  },
  selectedOption: {
    backgroundColor: '#b7b7b7',
    color: 'white',
  },
  unselectedOption: {
    backgroundColor: '#ffffff',
    color: 'gray'
  },
  contentButton: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flexGrow: 1
  }
});

// Constant used to specify that no transmission plan has been selected yet.
const NO_PLAN_SELECTED = -1;

// Minimum amount of characters we need to start checking for the input validity.
const MIN_VERIFICATION_THRESHOLD = 3;

// Obtained from https://emailregex.com/
const EMAIL_REGEX = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/

const MONTH_NAMES = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

class InputStep extends React.Component {
  constructor(props){
    super(props);
    // If the inputData prop is not null, it means the user came back to this screen.
    const { inputData } = this.props;
    this.state = {
      heirEmail: inputData ? inputData.heir.email : '',
      testatorEmail: inputData ? inputData.testator.email : '',
      testatorEmailConf: inputData ? inputData.testator.email : '',
      affiliateCode: inputData ? inputData.affiliateCode : '',
      heirEmailError: false,
      testatorEmailError: false,
      testatorEmailConfError: false,
      selectedFee: inputData ? inputData.transmissionType : NO_PLAN_SELECTED,
      errors: {
        heir: null,
        testator: null,
        testatorConf: null
      },
      snackbar: {
        open: false,
        message: ''
      }
    }
  }

  handleHeirEmailChange = event => {
    const text = event.target.value.toLowerCase();
    const stateClone = Object.assign({}, this.state);
    if(text.length >= MIN_VERIFICATION_THRESHOLD) {
      stateClone.heirEmailError = true;
      if(!text.match(EMAIL_REGEX)) {
        stateClone.errors.heir = 'Invalid e-mail.';
      }else if(stateClone.testatorEmail !== '' && text === stateClone.testatorEmail) {
        stateClone.errors.heir = 'Heir’s email must differ from testator’s.';
      }else {
        stateClone.errors.heir = null;
        stateClone.heirEmailError = false;
      }
    }else{
      stateClone.heirEmailError = false;
      stateClone.errors.heir = null;
    }
    stateClone.heirEmail = text;
    this.setState(stateClone);
  }

  handleTestatorEmailChange = event => {
    const text = event.target.value.toLowerCase();
    const stateClone = Object.assign({}, this.state);
    const matchesEmail = text.match(EMAIL_REGEX)
    if(text.length >= MIN_VERIFICATION_THRESHOLD) {
      const { state } = this;
      stateClone.testatorEmailError = true;
      if(!matchesEmail) {
        stateClone.errors.testator = 'Invalid e-mail.';
      }else if(text === state.heirEmail) {
        stateClone.errors.testator = 'Testator’s email must differ from heir’s.';
      }else if(text !== state.testatorEmailConf && state.testatorEmailConf !== '') {
        stateClone.errors.testator = 'Email confirmation must match.';
      }else {
        stateClone.errors.testator = null;
        stateClone.testatorEmailError = false;
      }
    }else{
      stateClone.testatorEmailError = false;
      stateClone.errors.testator = null;
    }
    stateClone.testatorEmail = text;
    this.setState(stateClone);
  }

  handleTestatorEmailConfirmationChange = event => {
    const text = event.target.value.toLowerCase();
    const stateClone = Object.assign({}, this.state);
    const matchesEmail = text.match(EMAIL_REGEX);
    if(text.length >= MIN_VERIFICATION_THRESHOLD) {
      const { state } = this;
      stateClone.testatorEmailConfError = true;
      if(!matchesEmail) {
        stateClone.errors.testatorConf = 'Invalid e-mail.';
      } else if(text !== stateClone.testatorEmail) {
        stateClone.errors.testatorConf = 'Confirmation must match.';
      } else {
        stateClone.errors.testatorConf = null;
        stateClone.testatorEmailConfError = false;
      }
    }else{
      stateClone.testatorEmailConfError = false;
    }
    stateClone.testatorEmailConf = text;
    this.setState(stateClone);
  }

  handleAffiliateCodeChange = event => {
    this.setState({affiliateCode: event.target.value}); 
  }

  onFeeSelected = event => {
    const { utxos, btcPrice, transmissionPlans } = this.props;
    // Calculating balance in satoshis
    const totalSatBalance = utxos.reduce((accum, item) => accum + item.value, 0);
    // Calculating balance in USD
    const usdBalance = btcPrice * totalSatBalance / 1e8;
    // Extracting the selected plan cost
    const plan =  transmissionPlans.find(item => parseInt(item.id) === parseInt(event.currentTarget.id));
    if(Number(plan.cost) < usdBalance){
      // Only updating the state in case there's enough funds
      // to cover for the transfer costs.
      this.setState({ selectedFee: parseInt(event.currentTarget.id) });
    }else{
      this.setState({snackbar:{open:true, message: 'You don\'t have enough funds to pay for this transmission plan'}});
    }
  }

  onCloseSnackbar = () => {
    this.setState({snackbar:{open:false, message: ''}});
  }

  componentDidMount(){
    const { isResumed, inputData } = this.props;
    if(isResumed && inputData === null){
      // Requesting existing transmission data from the server only if we
      // don't have a locally cached version in the form of the 'inputData' prop
      STService.get('/transmission/' + this.props.walletId).then(resp => {
        const { data } = resp;
        this.setState({
          isResumed: true,
          heirEmail: data.heir.email,
          testatorEmail: data.testator.email,
          testatorEmailConf: data.testator.email
        });
      });
    }
  }

  componentDidUpdate(prevProps, prevState){
    const { state } = this;
    // In case the 'isResumed' prop changed from true to false,
    // then we just had the user reset the form
    const wasReset = prevProps.isResumed && !this.props.isResumed;
    if (wasReset) {
      // If the form was reset, we just need to change the state to reflect that.
      this.setState({
        heirEmail: '',
        testatorEmail: '',
        testatorEmailConf: '',
        isResumed: false
      });
    }else{
      const validForm = !state.heirEmailError &&
        !state.testatorEmailError &&
        !state.testatorEmailConfError &&
        state.testatorEmail.length > MIN_VERIFICATION_THRESHOLD &&
        state.testatorEmailConf.length > MIN_VERIFICATION_THRESHOLD &&
        state.selectedFee !== NO_PLAN_SELECTED &&
        state.testatorEmail !== '';
      const stateChanged = hash(prevState) !== hash(state);
      // Data that will be propagated to the parent component.
      const data = {
        heir: { email: state.heirEmail },
        testator: { email: state.testatorEmail, phone: ''},
        transmissionType: this.state.selectedFee
      }
      if(stateChanged) this.props.handleStepResult(validForm, 0, validForm ? data : null);
    }
  }

  static getDerivedStateFromProps(props, state) {
    const { heirEmail, testatorEmail, testatorEmailConf } = state;
    if(props.wasReset && props.inputData === null && heirEmail === '' && testatorEmail === '' && testatorEmailConf === ''){
        state.selectedFee = NO_PLAN_SELECTED;
    }
  }

  render(){
    const { classes, isResumed, transmissionPlans } = this.props;
    const { selectedFee, errors } = this.state;
    return (
      <React.Fragment>
        <form className={classes.container} noValidate autoComplete="off">
          <Typography
            className={classes.instructionText}
            color="primary"
            align="left">
            Transmit to:
          </Typography>
          <TextField
            id="heir-email"
            label="Heir's e-mail"
            className={classes.textField}
            value={this.state.heirEmail}
            onChange={this.handleHeirEmailChange}
            margin="normal"
            variant="outlined"
            error={this.state.heirEmailError}
            helperText={errors.heir}
            disabled={false}
            fullWidth={true}
            disabled={isResumed}
          />
          <div className={classes.selectableButtonRoot}>
            {transmissionPlans.map(plan => {
              const expiration = new Date(Date.now() + plan.time * 1000);
              return (
                <div key={plan.id}
                  id={plan.id}
                  onClick={this.onFeeSelected}
                  className={`${classes.baseButton} ${selectedFee === plan.id ? classes.selectedOption : classes.unselectedOption}`}>
                  <div key={plan.id} className={classes.contentButton}>
                    <p>On {MONTH_NAMES[expiration.getMonth()]} {expiration.getDate()} {expiration.getFullYear()}</p>
                    <p>(fee: usd {plan.cost})</p>
                  </div>
                </div>
              )
            })}
          </div>
          <Typography
            className={classes.instructionText}
            color="primary"
            align="left">
            If I do not renew or cancel this transmission by then—when contacted at:
          </Typography>
          <TextField
            id="testator-email"
            label="Testator's e-mail"
            className={classes.textField}
            value={this.state.testatorEmail}
            onChange={this.handleTestatorEmailChange}
            margin="normal"
            variant="outlined"
            error={this.state.testatorEmailError}
            helperText={errors.testator}
            disabled={isResumed}
            fullWidth={true}
          />
          <TextField
            id="testator-email-confirmation"
            label="Testator's e-mail confirmation"
            className={classes.textField}
            value={this.state.testatorEmailConf}
            onChange={this.handleTestatorEmailConfirmationChange}
            margin="normal"
            variant="outlined"
            error={this.state.testatorEmailConfError}
            disabled={isResumed}
            helperText={errors.testatorConf}
            fullWidth={true}
          />
          <TextField
            id="affiliate-code"
            label="Affiliate code"
            className={classes.textField}
            value={this.state.affiliateCode}
            onChange={this.handleAffiliateCodeChange}
            margin="normal"
            variant="outlined"
            disabled={isResumed}
            fullWidth/>
        </form>
        <Snackbar
          anchorOrigin={{ vertical:'bottom', horizontal:'center' }}
          open={this.state.snackbar.open}
          autoHideDuration={1500}
          onClose={this.onCloseSnackbar}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={this.state.snackbar.message}
        />
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(InputStep);
