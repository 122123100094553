import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Github from '../../../../assets/img/github.svg';
import Medium from '../../../../assets/img/medium.svg';
import Reddit from '../../../../assets/img/reddit.svg';
import Telegram from '../../../../assets/img/telegram.svg';
import Youtube from '../../../../assets/img/youtube.svg';
import Twitter from '../../../../assets/img/twitter.svg';

const styles = theme => ({
  root: {
    width: '100%',
    display: 'flex',
    alignItems: 'center'
  },
  logo: {
    margin: '10px',
    opacity: '0.5',
    '&:hover': {
      opacity: '1.0'
    }
  },
  copyright: {opacity: '0.5'},
  [theme.breakpoints.down('sm')]:{
    root: {
      flexDirection: 'column'
    }
  },
  [theme.breakpoints.up('sm')]:{
    root: {
      flexDirection: 'row-reverse',
      justifyContent: 'space-between'
    }
  }
})

const platforms = [
  {
    name: 'Github',
    logo: Github,
    url: 'https://github.com/selftrustme'
  },
  {
    name: 'Medium',
    logo: Medium,
    url: 'https://medium.com/@selftrust.me'
  },
  {
    name: 'Reddit',
    logo: Reddit,
    url: 'https://www.reddit.com/r/selftrust'
  },
  {
    name: 'Telegram',
    logo: Telegram,
    url: 'https://t.me/SelfTrust_Me'
  },
  {
    name: 'YouTube',
    logo: Youtube,
    url: 'https://www.youtube.com/channel/UCq9M8XeiIvii6oKRMIK_nQA'
  },
  {
    name: 'Twitter',
    logo: Twitter,
    url: 'https://twitter.com/SelftrustMe'
  }
]

const footer = props => {
  const {classes} = props;
  return (
    <AppBar position="static">
      <Toolbar>
        <div className={classes.root}>
          <div>{platforms.map(platform => (
            <img className={classes.logo}
              key={platform.name}
              src={platform.logo}
              alt={platform.name}
              onClick={() => window.open(`${platform.url}`, '_blank')}/>
          ))}</div>
        <Typography className={classes.copyright} variant='caption'>© 2020 selftrust.me</Typography>
        </div>
      </Toolbar>
    </AppBar>
  )
}

export default withStyles(styles)(footer);
