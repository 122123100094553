import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import './ProgressDialog.css';

const progressDialog = (props) => {

    return (
      <Dialog
        open={props.isOpen}>
        <DialogTitle id="simple-dialog-title">{props.title}</DialogTitle>
        <DialogContent className='progress-dialog-content'>
          <DialogContentText id="alert-dialog-description">
            {props.content}
          </DialogContentText>
          <CircularProgress className='progress-dialog-circle' color='secondary'/>
        </DialogContent>
      </Dialog>
    )
}

export default progressDialog;
