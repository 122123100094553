import React, {Component} from 'react';
import Web3 from 'web3';
import TextField from '@material-ui/core/TextField';
import CurrencySelector from '../CurrencySelector/CurrencySelector';
import BigNumber from 'bignumber.js';
import CryptoInput from '../../../../../../components/CryptoInput/CryptoInput';
import FeeSelector from '../EthTxBuilder/FeeSelector/FeeSelector';
import { withStyles } from '@material-ui/core/styles';
import axiosVerifier from '../../../../../../network/axios-verifier';
import hash from 'object-hash';
import { isMobile } from 'react-device-detect';
import { ETH_GAS_LIMIT, ACTIVE_INTERNAL_FEE_ADDRESS } from '../../../../../../constants';

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5)
  }
});

class ERC20Builder extends Component {
  constructor(props){
    super(props);
    this.state = {
      currency: props.coin.symbol.toUpperCase(),
      price: props.coin.price,
      destination: {value: '', valid: false},
      amount: {value: '', valid: false},
      insufficientBalance: false,
      outputs: {
        destination: 0,
        internalFee: 0,
        minerFee: 0
      },
      selectedFeeBucket: null,
      gasPrice: 0,
      displayError: false,
      feeDetailsVisible: false
    }
  }

  handleCurrencyChange = (currency) => {
    this.currencyChanged = true;
    this.setState({currency: currency});
  }

  handleDestinationChange = event => {
    const typedValue = event.target.value;
    const isValid = this.verifyInput('destination', typedValue);
    this.setState({destination: {value: typedValue, valid: isValid}});
  }

  verifyInput = (name, value) => {
    if(name === 'destination'){
      return Web3.utils.isAddress(value);
    }else if(name === 'amount'){
      try{
          const floatValue = parseFloat(value);
          return true;
      }catch(error){
        console.error('Caught error while parsing amount value. Msg: ', error);
        return false;
      }
    }
    return false;
  }

  handleAmountChange = event => {
    // Skipping mouse scroll events on desktop
    if(event.nativeEvent.inputType === undefined && !isMobile) return;
    const coin = this.props.coin;
    const tokenSymbol = coin.symbol.toUpperCase();
    const typedValue = event.target.value;
    const isValid = this.verifyInput('amount', typedValue);
    const hasEnough = this.hasEnough(typedValue, this.state.outputs.minerFee);
    this.setState((prevState, currentProps) => {
      this.overrideUpdate = true;
      prevState.insufficientBalance = !hasEnough;
      prevState.amount = { value: typedValue, valid: isValid }
      if(isValid){
        if(prevState.currency === tokenSymbol)
          prevState.outputs.destination = +typedValue;
        else
          prevState.outputs.destination = (+typedValue) / prevState.price;
      }else{
        prevState.outputs.destination = 0;
      }
      return prevState;
    });
  }

  hasEnough = (toSend, minerFee) => {
    const coin = this.props.coin;
    const tokenSymbol = coin.symbol.toUpperCase();
    toSend = isNaN(parseFloat(toSend)) ? 0 : parseFloat(toSend);
    if(this.state.currency !== tokenSymbol){
      toSend = toSend / this.state.price;
    }
    // Specifying all our costs
    const weiInternalFee = new BigNumber(this.state.outputs.internalFee).multipliedBy(Math.pow(10, coin.precision));
    const weiMinerFee = new BigNumber(minerFee).multipliedBy(Math.pow(10, coin.precision));
    const weiToSend = new BigNumber(toSend).multipliedBy(Math.pow(10, coin.precision));
    // Obtaining available balance
    const availableBalance = this.props.coin.balance;
    // Finally checking if the available balance is enough to cover all costs
    // return availableBalance > weiInternalFee + weiMinerFee + weiToSend;
    return weiInternalFee.plus(weiMinerFee).plus(weiToSend).isLessThanOrEqualTo(availableBalance);
  }

  // Handles the user-selected fee bucket (denominated in block until confirmation)
  handleFeeSelection = (bucket) => {
    // The gas price is expressed in Gwei (wei * 10^9), so we multiply it for
    // that value in order to get the price in weis.
    // Then we need to multiply the result by the amount of gas units required for
    // a token transfer, which can 50K and 105K. So we use a safe value specified in
    // the constant TOKEN_TRANSFER.
    // Finally, in order to obtain the price in ETH we divide everything by 10^18.
    const ethFee = new BigNumber(bucket.gasPrice)
                        .multipliedBy(1e9)
                        .multipliedBy(ETH_GAS_LIMIT.TOKEN_TRANSFER).dividedBy(1e18);
    this.setState((prevState, currentProps) => {
      this.overrideUpdate = true;
      prevState.outputs.minerFee = ethFee.toNumber();
      prevState.gasPrice = bucket.gasPrice;
      prevState.selectedFeeBucket = bucket;
      return prevState;
    });
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    // We only need to request the SelfTrust fee in case it is zero and the price
    // has already been resolved
    if(this.state.outputs.internalFee === 0 && this.props.eth.price){
      // Requesting the SelfTrust fee value
      axiosVerifier.get('/amount')
      .then(response => {
        const value = response.data.data[0].amount;
        // We change the state by updating the SelfTrust fee value
        this.setState((prevState, currentProps) => {
          prevState.price = this.props.eth.price;
          this.overrideUpdate = true;
          const price = prevState.price;
          const precision = this.props.eth.precision;
          let internalFee = Math.floor((value / price) * Math.pow(10, precision)) / Math.pow(10, precision);
          return prevState.outputs.internalFee = internalFee;
        })
      }).catch(e => {
        console.error(e);
        this.setState({missingInternalFee: true})
      });
    }else if(!prevState.displayError){
      this.checkForm();
    }
  }

  checkForm = async () => {
    const {internalFee, minerFee, destination} = this.state.outputs;
    const insufficientBalance = this.state.insufficientBalance;
    console.log(`internalFee: ${internalFee}, minerFee: ${minerFee}, insufficientBalance: ${insufficientBalance}`);
    // Decide whether or not to allow the NEXT button to be enabled
    const isValid = this.state.outputs.internalFee !== 0 &&
                        this.state.outputs.minerFee !== 0 &&
                        this.state.outputs.destination !== 0 &&
                        this.state.destination.valid && !this.state.insufficientBalance;
    let txBundle = null;
    console.log('checkForm. is valid: ', isValid);
    if(isValid) {
      // If the form is valid, we must proceed to build a proposed transaction
      // and send it to the verification server
      txBundle = this.buildTransaction();
      txBundle.hash = hash(txBundle);
      console.log('txBundle: ', txBundle);
      this.props.handleStepResult(this.props.index, isValid, txBundle);
    }
  }

  buildTransaction = () => {
    return {
      asset: this.props.coin.symbol,
      tx: {
        from: this.props.ethAccount,
        to: this.state.destination.value,
        amount: Math.round(parseFloat(this.state.amount.value) * 1e18).toString(),
        nonce: this.props.ethNonce,
        gasPrice: this.state.gasPrice,
        feeBucket: this.state.selectedFeeBucket.key,
        minerFee: Math.round(parseFloat(this.state.outputs.minerFee) * 1e18).toString(),
        internalFeeAmount: Math.round(parseFloat(this.state.outputs.internalFee) * 1e18).toString(),
        internalFeeDestination: ACTIVE_INTERNAL_FEE_ADDRESS,
        contractAddress: this.props.coin.address
      }
    };
  }

  render(){
    const coin = this.props.coin;
    const CRYPTO_SYMBOL = coin.symbol.toUpperCase();
    const enableFeeSelector = this.props.isloaded && this.hasEnough(this.state.amount.value, this.state.outputs.minerFee) && this.state.amount.valid;
    const { classes } = this.props;

    let toSend = this.state.outputs.destination;
    let totalFees = this.state.outputs.minerFee + this.state.outputs.internalFee;
    let valuePrecision = 8;
    if(this.state.currency !== CRYPTO_SYMBOL){
      toSend = this.state.outputs.destination * this.state.price;
      totalFees = totalFees * this.state.price;
      valuePrecision = 2;
    }
    const totalSpent = toSend + totalFees;

    // Copy the contents of this attribute in order to be able to reset it
    let updateAmount = this.currencyChanged;
    this.currencyChanged = false;
    const amountToSend = updateAmount ? toSend.toFixed(valuePrecision) : this.state.amount.value;
    if(amountToSend < 0){
      console.log(`updateAmount: ${updateAmount}, toSend: ${toSend}, this.state.amount.value: ${this.state.amount.value}`);
    }
    return(
      <React.Fragment>
        <form>
          <CurrencySelector
            disabled={!this.props.isloaded}
            currency={this.state.currency}
            options={{fiat: 'USD', crypto: coin.symbol.toUpperCase()}}
            handleCurrencyChange={this.handleCurrencyChange}/>
          <TextField
            id="outlined-address"
            label="Pay To"
            className={classes.textField}
            value={this.state.destination.value}
            onChange={this.handleDestinationChange}
            margin="normal"
            variant="outlined"
            error={!this.state.destination.valid && this.state.destination.value !== ''}
            disabled={!this.props.isloaded}
            fullWidth={true}
          />
        <CryptoInput
            id="outlined-amount"
            label="Amount"
            precision={coin.precision}
            className={classes.textField}
            value={updateAmount ? toSend.toFixed(valuePrecision) : this.state.amount.value}
            onChange={this.handleAmountChange}
            margin="normal"
            variant="outlined"
            fullWidth={true}
            type="text"
            error={(!this.state.amount.valid || this.state.insufficientBalance) && this.state.amount.value !== ''}
            disabled={!this.state.destination.valid || this.state.destination === ''}
          />
          <FeeSelector
            handleFeeSelection={this.handleFeeSelection}
            enabled={enableFeeSelector}
            fee_buckets={this.props.feeBuckets}/>
          <TextField
            label="Fees"
            className={classes.textField}
            margin="normal"
            InputProps={{
              readOnly: true
            }}
            onClick={() => this.setState({feeDetailsVisible: true})}
            value={totalFees > 0 ? (totalFees).toFixed(valuePrecision) : 0}
            fullWidth={true}
            variant="filled"
          />
          <TextField
            id="output-total-spent"
            label="Total Spent"
            className={classes.textField}
            margin="normal"
            InputProps={{
              readOnly: true,
            }}
            value={totalSpent.toFixed(valuePrecision)}
            fullWidth={true}
            variant="filled"
            error={this.state.insufficientBalance}
          />
        </form>
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(ERC20Builder);
