import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import bitcoin from 'bitcoinjs-lib';
import QRCodeScanner from '../../../../../components/QRCodeScanner/QRCodeScanner';
import STService from '../../../../../network/axios-verifier';
import TransmissionSuccess from './TransmissionSuccess/TransmissionSuccess';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5)
  },
  progressContainer: {
    textAlign: 'center',
    margin: '30%'
  },
  progress: {
    margin: theme.spacing(2),
  }
});

class ScanStep extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      scannedData: null,
      broadcasted: false,
      success: null,
      txHash: null,
      transmissionData: null,
      scanError: false
    }
  }

  /**
  * Callback function to be executed when all QR code segments have been read
  * and assembled by the QRCodeScanner component.
  */
  handleQRCodeData = codeData => {
    // Adding the transmission type attribute to the QR code data
    const payload = JSON.parse(codeData);
    if(payload.tx){
      const tx = bitcoin.Transaction.fromHex(payload.tx);
      payload.transmissionType = this.props.transmissionType;
      STService.patch(`/transmission/${this.props.walletId}`, payload, {headers: {'content-type':'application/json'}})
        .then(resp => {
          if(resp.status === 200){
            this.setState({
              broadcasted: true,
              success: true,
              transmissionData: resp.data,
              txid: tx.getId()
            });
            this.props.onTransmissionSetupResult(true);
          }else{
            this.setState({broadcasted: true, success: false});
            this.props.onTransmissionSetupResult(true);
          }
        })
        .catch(error => {
          console.error('Error while sending transmission package to the server. Error: ', error)
          this.setState({broadcasted: true, success: false});
          this.props.onTransmissionSetupResult(true);
        });
      this.setState({scannedData: codeData});
    }else{
      // Payload is missing a signed transaction
      if(!this.state.scanError){
        // If the error has not yet been marked, we do it here.
        this.setState({scanError: true});
      }
    }
  }

  render(){
    const { classes } = this.props;
    if(this.state.scannedData){
      if(this.state.broadcasted){
        if(this.state.success){
          const { dueDate, heir } = this.state.transmissionData;
          const expirationDate = new Date(dueDate);
          return (
            <TransmissionSuccess
              txid={this.state.txid}
              expirationDate={expirationDate}
              heirEmail={heir.email}/>
          )
        }else{
          return <h1>Error after sending transmission data to the server</h1>
        }
      }else{
        return (
          <div className={classes.progressContainer}>
            <Typography variant='subtitle1'>Please wait</Typography>
            <CircularProgress className={classes.progress} />
          </div>
        )
      }
    }else if(this.state.scanError){
      if(this.state.scanError){
        return (
          <div className={classes.progressContainer}>
            <Typography variant='subtitle1'>Scan error, please try again.</Typography>
          </div>
        )
      }
    }else{
      return <QRCodeScanner
        onResult={this.handleQRCodeData}
        message='Scan the QR codes from the SelfTrust Offline app.'/>
    }
  }
}

export default withStyles(styles)(ScanStep);
