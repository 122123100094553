import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import './BaseStepper.css';

const styles = theme => ({
  root: {
    width: '90%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
});

class BaseStepper extends React.Component {
  constructor(props){
    super(props);
    const childrenWithProps = React.Children.map(props.children, child => {
      return React.cloneElement(child, {
        handleStepResult: this.handleStepResult});
    });
    this.state = {
      steps: childrenWithProps,
      activeStep: 0,
      completed: props.children.map((i) => {return false}),
      clickCounter: props.clickCounter
    };
  }

  handleNext = () => {
    const { activeStep, steps } = this.state;
    if(activeStep < steps.length - 1){
      this.setState({
        activeStep: activeStep + 1
      });
    }else{
      this.handleStepResult(activeStep, true, null);
    }
  };

  handleBack = () => {
    this.setState(state => ({
      activeStep: state.activeStep - 1,
    }));
  };

  handleReset = () => {
    this.setState({
      activeStep: 0,
    });
  };

  handleStepResult = (index, isValid, result) => {
    if(isValid) {
      this.props.onstepresult(index, result);
    }
    if(this.state.completed[index] !== isValid){
      // Only calling setState if the step status has changed
      this.setState((prevState, currentProps) => {
        prevState.completed[index] = isValid;
        return prevState;
      })
    }
  }

  static getDerivedStateFromProps = (props, state) => {
    const newChildren = props.children.map((child, index) => {
      return React.cloneElement(child, {
        handleStepResult: state.steps[index].props.handleStepResult,
        clickCounter: props.clickCounter
      });
    });
    state.steps = newChildren;
    if(props.clickCounter !== state.clickCounter){
      // If the click counter was incremented, this means we should reset the state
      state.activeStep = 0;
      state.completed = props.children.map((i) => {return false});
      state.clickCounter = state.clickCounter + 1;
    }
    return state;
  }

  render(){
    const { classes } = this.props;
    const { activeStep, steps } = this.state;
    if(steps === undefined){
      return <p>Empty stepper</p>
    }
    let nextButton = null;
    if(activeStep < steps.length - 1){
      // Show NEXT button only if not at the last step
      nextButton = (
        <Button
          variant="contained"
          color="primary"
          disabled={!this.state.completed[activeStep]}
          onClick={this.handleNext}
          style={{float: "right"}}
          className={classes.button}>
          {activeStep === steps.length - 1 ? this.props.finallabel : this.props.nextlabel}
        </Button>
      )
    }
    return (
      <React.Fragment>
        <div>
          {this.state.steps[activeStep]}
        </div>
        <div className="btnStepper">
          {nextButton}
        </div>
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(BaseStepper);
