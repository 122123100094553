import React from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import InstructionStep from './InstructionStep/InstructionStep';
import Step1Img1 from '../../../../assets/img/step1-pic1.png';
import Step2Img1 from '../../../../assets/img/step2-pic1.png';
import Step2Img2 from '../../../../assets/img/step2-pic2.png';
import Step3Img1 from '../../../../assets/img/step3-pic1.png';
import Step4Img1 from '../../../../assets/img/step4-pic1.png';
import Step4Img2 from '../../../../assets/img/step4-pic2.png';
import Step5Img1 from '../../../../assets/img/step5-pic1.png';

const steps = [
  {
    title: 'Step 1',
    content: 'Download the free SelfTrust Offline app on any Android device and keep this device on airplane mode as long as the app is installed.',
    images: [Step1Img1],
    link: {text: 'free SelfTrust Offline app', url: 'https://play.google.com/apps/testing/me.selftrust.offline'}
  },
  {
    title: 'Step 2',
    content: [
      {
        type: 'paragraph',
        content: 'Using the SelfTrust Offline app, create your wallet following a flawless entropy scheme that you:',
        link: {text: 'flawless entropy scheme', url: 'https://en.bitcoin.it/wiki/Passphrase_generation'}
      },
      {
        type: 'bullets',
        content: ['only know about yet relies on mathematics certainty;', 'can easily audit (even without reviewing our open-source code).'],
        link: {text: 'audit', url: 'https://selftrust.me/audit'}
      }
    ],
    images: [Step2Img1, Step2Img2]
  },
  {
    title: 'Step 3',
    content: 'From a mobile phone (on iOS, use Safari), at selftrust.me, log on your watch-only wallet by clicking ‘LOGIN’ and scanning the QR code provided by the SelfTrust Offline app (public keys only).',
    images: [Step3Img1]
  },
  {
    title: 'Step 4',
    content: [
      {
        type: 'paragraph',
        content: 'From a mobile phone (on iOS, use Safari), at selftrust.me, once logged in, you may:'
      },
      {
        type: 'bullets',
        content: ['consult your balance and history;', 'generate a transaction, have the SelfTrust Offline app sign it, and selftrust.me broadcast it (online/offline devices interact via QR codes);', 'verify ownership of receive addresses.']
      }
    ],
    bullets: [],
    images: [Step4Img1, Step4Img2]
  },
  {
    title: 'Step 5',
    content: [
      {
        type: 'paragraph',
        content: 'Bequeath your wallet—only after you passed away—via our trust-minimized mechanism (*).\n\n'
      },
      {
        type: 'paragraph',
        content: '(*)'
      },
      {
        type: 'paragraph',
        content: 'On the SelfTrust Offline app, using Shamir Secret Sharing, divide your backup seed phrase into two cryptograms. Anyone with these two cryptograms can rebuild your backup seed phrase and use your wallet—but no one with only one cryptogram can do so.\nThen:',
        link: {text: 'Shamir Secret Sharing', url: 'https://en.wikipedia.org/wiki/Shamir%27s_Secret_Sharing'}
      },
      {
        type: 'bullets',
        content: ['deliver—offline—one cryptogram to your heir,', 'transfer via QR code the other one to selftrust.me.']
      },
      {
        type: 'paragraph',
        content: 'If, within 6 to 12 months (depending on the plan you purchased), you do not renew or cancel your plan, selftrust.me assumes you passed away and automatically emails your heir the cryptogram it holds.'
      },
      {
        type: 'paragraph',
        content: 'With both cryptograms, your heir then rebuilds your backup seed phrase and takes hold of your wallet.'
      }
    ],
    images: [Step5Img1]
  }
]

const styles = theme => ({
  messageText: {
    margin: '40px 30px 40px 30px'
  },
  instructions: {
    margin: '15px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '5em'
  }
})

const instructions = props => {
  const { classes } = props;
  return (
    <div className={classes.instructions}>
      <Typography
        className={classes.messageText}
        variant='h5'
        color='primary'
        align='center'>
          How selftrust.me works
      </Typography>
      {steps.map((step, index) => <InstructionStep stepDetails={step} key={index} index={index}/>)}
    </div>
  )
}

export default withStyles(styles)(instructions);
