import React from 'react';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import ScanStep from './ScanStep/ScanStep';
import STService from '../../../../network/axios-verifier';
import { QRCodesTypes, DEFAULT_TRANSMISSION_FEE_RATE, INTERNAL_FEE_ADDRESS } from '../../../../constants';
import BitcoinWallet from '../../../../common/BitcoinWallet';
import MultipartQRCode from '../../../../components/MultipartQRCode/MultipartQRCode';

const MONTH_NAMES = ['Jan', 'Feb', 'Mar', 'May', 'Apr', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

// Constant used to specify that no transmission plan has been selected yet.
const NO_PLAN_SELECTED = -1;

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: theme.spacing(1)
  },
  textField: {
    margin: theme.spacing(1)
  },
  instructionText: {
    marginLeft: theme.spacing(1.5)
  },
  selectableButtonRoot: {
    display: 'flex',
    width: '100%'
  },
  baseButton: {
    width: '50%',
    height: '150px',
    margin: theme.spacing(1),
    borderRadius: '8px',
    border: '1px solid #c4c4c4'
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    padding: theme.spacing(1)
  },
  button: {
    width: '50%',
    margin: theme.spacing(1)
  },
  selectedOption: {
    backgroundColor: '#b7b7b7',
    color: 'white',
  },
  unselectedOption: {
    backgroundColor: '#ffffff',
    color: 'gray'
  },
  [theme.breakpoints.down('md')]: {
    selectedOption: {
      height: '100px'
    },
    unselectedOption: {
      height: '100px'
    }
  }
});

const STEP_INPUT = 0;
const STEP_QR_CODE = 1;
const STEP_SCAN = 2;

class TransmissionRenewal extends React.Component {

  state = {
    currentStep: STEP_INPUT,
    transmissionType: NO_PLAN_SELECTED,
    transmissionPlans: [],
    txBundle: null,
    scan: false,
    heirEmail: '',
    testatorEmail: '',
    snackbar: {
      open: false,
      message: ''
    }
  }

  componentDidMount(){
    STService.get('/parameters/transmission').then(resp => {
      if(resp.status === 200){
        const transmissionPlans = Object.keys(resp.data.params).map(id => {
          return {
            id: parseInt(id),
            ...resp.data.params[id]
          }
        });
        const { transferData } = this.props;
        this.setState({
          transmissionPlans: transmissionPlans,
          transmissionType: transferData.transmissionType,
          heirEmail: transferData.heir.email,
          testatorEmail: transferData.testator.email
        });
      }else{ console.error('Failed to obtain transmission plans'); }
    }).catch(error => console.error('Error while trying to obtain transmission paramters'));
  }

  onFeeSelected = event => {
    const { utxos, btcPrice } = this.props;
    const { transmissionPlans } = this.state;
    // Calculating balance in satoshis
    const totalSatBalance = utxos.reduce((accum, item) => accum + item.value, 0);
    // Calculating balance in USD
    const usdBalance = btcPrice * totalSatBalance / 1e8;
    // Extracting the selected plan cost
    const plan =  transmissionPlans.find(item => parseInt(item.id) === parseInt(event.currentTarget.id));
    if(Number(plan.cost) < usdBalance){
      // Only updating the state in case there's enough funds
      // to cover for the transfer costs.
      this.setState({ transmissionType: parseInt(event.currentTarget.id) });
    }else{
      this.setState({snackbar:{open:true, message: 'You don\'t have enough funds to pay for this transmission plan'}});
    }
  }

  onCloseSnackbar = () => {
    this.setState({snackbar:{open:false, message: ''}});
  }

  handleRenewRequest = event => {
    try {
      const { utxos, changeAddress } = this.props;
      const { transmissionType } = this.state;
      const wallet = new BitcoinWallet(utxos, changeAddress);
      const outputs = [this.getTransmissionOutput(transmissionType)];
      let incomplete = wallet.buildTransaction(outputs, DEFAULT_TRANSMISSION_FEE_RATE);
      const selectedInputs = wallet.selectUtxos(outputs[0].amount, 1, 1);
  
      const transmissionDetails = {
        tx: incomplete.toHex(),
        transmissionType: transmissionType
      }
      STService.patch('/transmission?renew=true&proposing=true', transmissionDetails)
        .then(resp => {
          if(resp.status === 200){
            // Creating a transaction bundle with the newly obtained signature
            this.setState({
              currentStep: STEP_QR_CODE,
              txBundle: {
                tx: incomplete.toHex(),
                inputs: selectedInputs,
                type: QRCodesTypes.TYPE_RENEW_TRANSFER,
                price: this.props.btcPrice,
                signature: resp.data,
                asset: 'btc'
              }
            });
          }else{
            console.error('Expecting a 201, maybe this wallet already has a transmission?');
          }
        })
        .catch(error => console.error('Error while trying to obtain signature for transmission renewal tx'));
    }catch(err){
      console.error('Got error. Msg: ', err);
      this.setState({snackbar:{open:true, message: 'Not enough funds to pay for this transmission plan\'s transaction fees'}});
    }
  }

  /**
  * Function used to obtain the transmission payment output.
  */
  getTransmissionOutput = (transmissionType) => {
    let usdValue = 0;
    // First we have to look for a transmision plan / type with the same
    // id as the one passed. Once we found it, we have to extract its price
    // in USD.
    this.state.transmissionPlans.forEach(plan => {
      if(plan.id === transmissionType){
        usdValue = plan.cost;
      }
    });
    // Finally, we calculate the amount in satoshis that this output must have
    const satValue = parseInt(1e8 * usdValue / this.props.btcPrice );
    return { address: INTERNAL_FEE_ADDRESS, amount: satValue };
  }

  onNextPressed = event => {
    this.setState({
      currentStep: STEP_SCAN,
      scan: true
    });
  }

  onBackPressed = event => {
    const { currentStep } = this.state;
    if(currentStep === STEP_QR_CODE){
      this.setState({
        currentStep: STEP_INPUT,
        scan: false,
        txBundle: null
      });
    }else if(currentStep === STEP_SCAN){
      this.setState({
        currentStep: STEP_QR_CODE
      });
    }
  }

  getInputStep = () => {
    const { classes } = this.props;
    const { transmissionType } = this.state;
    return (
      <React.Fragment>
        <form className={classes.container} noValidate autoComplete="off">
          <TextField
            id="heir-email"
            label="Transmit my walet to:"
            className={classes.textField}
            value={this.state.heirEmail}
            margin="normal"
            variant="outlined"
            disabled={true}
            fullWidth={true}
          />
          <div className={classes.selectableButtonRoot}>
            {this.state.transmissionPlans.map(plan => {
              const currentExpiration = new Date(this.props.transferData.dueDate);
              const newExpiration = new Date(currentExpiration.getTime() + plan.time * 1000);
              return (
                <div key={plan.id}
                  id={plan.id}
                  onClick={this.onFeeSelected}
                  className={`${classes.baseButton} ${transmissionType === plan.id ? classes.selectedOption : classes.unselectedOption}`}>
                  <div>
                    <p>On {MONTH_NAMES[newExpiration.getMonth()]} {newExpiration.getDate()} {newExpiration.getFullYear()}</p>
                    <p>(fee: usd {plan.cost})</p>
                  </div>
                </div>
              )
            })}
          </div>
          <Typography
            className={classes.instructionText}
            color="primary"
            align="left">
            If I do not renew or cancel this transmission by then—when contacted at:
          </Typography>
          <TextField
            id="testator-email"
            label="Testator's e-mail"
            className={classes.textField}
            value={this.state.testatorEmail}
            margin="normal"
            variant="outlined"
            disabled={true}
            fullWidth={true}
          />
          <div className={classes.buttonContainer}>
            <Button
              variant="contained"
              color="primary"
              disabled={this.state.transmissionType === NO_PLAN_SELECTED}
              onClick={this.handleRenewRequest}
              className={classes.button}>
              Renew
            </Button>
          </div>
          <Snackbar
            anchorOrigin={{ vertical:'bottom', horizontal:'center' }}
            open={this.state.snackbar.open}
            autoHideDuration={1500}
            onClose={this.onCloseSnackbar}
            ContentProps={{
              'aria-describedby': 'message-id',
            }}
            message={this.state.snackbar.message}
          />
        </form>
      </React.Fragment>
    )
  }

  getQRCodeStep = () => {
    const { classes } = this.props;
    const { txBundle } = this.state;
    return (
      <React.Fragment>
        <p className="text-alert" style={{marginBottom: '24px'}}>
          Scan the following QR code(s) from the SelfTrust Offline app.
        </p>
        <MultipartQRCode
          type={QRCodesTypes.TYPE_RENEW_TRANSFER}
          data={txBundle}/>
        <div className={classes.buttonContainer}>
          <Button
            id="qr_code_step"
            variant="contained"
            color="primary"
            onClick={this.onBackPressed}
            className={classes.button}>
            Back
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={this.onNextPressed}
            className={classes.button}>
            Next
          </Button>
        </div>
      </React.Fragment>
    )
  }

  getScanStep = () => {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <ScanStep
          walletId={this.props.walletId}
          transmissionType={this.state.transmissionType}
          onTransmissionRenewed={this.props.onTransmissionRenewed}
        />
        <div className={classes.buttonContainer}>
          <Button
            id="scan_step"
            variant="contained"
            color="primary"
            onClick={this.onBackPressed}
            className={classes.button}>
            Back
          </Button>
        </div>
      </React.Fragment>
    )
  }

  render() {
    const { currentStep } = this.state;
    if(currentStep === STEP_INPUT){
      return this.getInputStep();
    }else if(currentStep === STEP_QR_CODE){
      return this.getQRCodeStep();
    }else{
      return this.getScanStep();
    }
  }
}


export default withStyles(styles)(TransmissionRenewal)
