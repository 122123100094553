import BigNumber from 'bignumber.js';

const TxBuilderCommon = {
  /**
  * Function used to calculate the correct SelfTrust fee.
  *
  * @param {number} value - The value to transfer as the user typed, converted to number.
  * @param {number} price - The asset price.
  * @param {number} precision - The precision to be used with this asset.
  * @param {object} feeParams - The fee parameters object.
  */
  calculateInternalFee: (value, price, precision, feeParams) => {
    BigNumber.set({DECIMAL_PLACES: precision});
    let calculatedFee = 0;
    const fiatToTransfer = new BigNumber(value).multipliedBy(price);
    if(fiatToTransfer.isGreaterThanOrEqualTo(feeParams.min_threshold)){
      // Either flat or proportional fee regimes.
      const minFee = new BigNumber(feeParams.min_fee);
      const proportionalFee = fiatToTransfer.multipliedBy(feeParams.proportion);
      const fiatFeeValue = Math.max(minFee.toNumber(), proportionalFee.toNumber());
      calculatedFee = new BigNumber(fiatFeeValue).dividedBy(price).toNumber();
    }
    return calculatedFee;
  }
}

export default TxBuilderCommon;
