import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import IconButton from '@material-ui/core/IconButton';
import Send from './Send/Send';
import Receive from './Receive/Receive';
import TxHistory from '../Main/TxHistory/TxHistory';
import queryString from 'query-string'
import TxDetailsDialog from '../../../components/TxDetailsDialog/TxDetailsDialog';
import { TX_HISTORY_PAGE_SIZE, CONFIRMATION_THRESHOLD } from '../../../constants';
import { getBlockExplorerUrl } from '../../../util';

class CoinDetails extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      tabsValue: 0,
      firstTime: true,
      processedTxs: [],
      clickCounter: 0,
      showTxDetails: false,
      clickedTx: null
    }
  }

  handleBtcTxBroadcast = (txBundle) => {
    this.props.handleBtcTxBroadcast(txBundle);
  }

  handleTabsChange = (event, tabsValue) => {
    // this.setState({ tabsValue });
    this.setState((prevState, currentProps) => {
      prevState.tabsValue = tabsValue;
      prevState.clickCounter = prevState.clickCounter + 1;
      return prevState;
    });
  };

  onBackClicked = (e) => {
      this.props.history.goBack();
  }

  static getDerivedStateFromProps = (props, state) => {
    if(state.firstTime){
      const params = queryString.parse(props.location.search);
      if(params.tab === 'send'){
        state.tabsValue = 0;
      }else if(params.tab === 'receive'){
        state.tabsValue = 1;
      }else if(params.tab === 'history'){
        state.tabsValue = 2;
      }
      state.firstTime = false;
    }
    return state;
  }

  componentDidMount(){
    const coin = this.props.match.params.coin;
    const txs = this.props.db.processedTxs
      .where({name: coin})
      .sortBy('confirmations')
      .then(txs => {
        const limitedTxs = txs.slice(0, TX_HISTORY_PAGE_SIZE);
        this.setState({processedTxs: limitedTxs});
        // Creating subscriptions
        this.props.db.processedTxs.hook('creating', this.creationHandler);
        this.props.db.processedTxs.hook('updating', this.updateHandler);
      });
  }

  componentWillUnmount(){
    // Removing subscriptions
    this.props.db.processedTxs.hook('creating').unsubscribe(this.creationHandler);
    this.props.db.processedTxs.hook('updating').unsubscribe(this.updateHandler);
  }

  /**
  * Function that will be called whenever a new object is inserted into the 'processedTxs' table
  */
  creationHandler = (primKey, obj, transaction) => {
    const symbol = this.props.match.params.coin;
    if(obj.name === symbol){
      const updatedTxs = [obj, ...this.state.processedTxs];
      if(updatedTxs.length > TX_HISTORY_PAGE_SIZE){
        // Updated tx list is too long, removing the last item
        updatedTxs.pop();
      }
      this.setState({processedTxs: updatedTxs});
    }
  }

  /**
  * Function called whenever an entry in the 'processedTxs' table changes.
  */
  updateHandler = (modifications, primKey, obj, transaction) => {
    if(modifications === {}) return;
    const symbol = this.props.match.params.coin;
    let threshold = CONFIRMATION_THRESHOLD.BTC;
    if(symbol !== 'btc'){
      threshold = CONFIRMATION_THRESHOLD.ETH;
    }
    const txsToUpdate = [...this.state.processedTxs];
    const index = txsToUpdate.findIndex(tx => tx.hash === primKey);
    if(index !== -1){
      const original = txsToUpdate[index];
      const updated = {...original,...modifications};
      txsToUpdate[index] = updated;
      this.setState({processedTxs: txsToUpdate});
    }
  }

  /**
  * Callback executed every time the user clicks on a specific item of the
  * transaction list.
  */
  onTxClicked = (index) => {
    // Fetching the selected transaction
    const tx = this.state.processedTxs[index];
    // Delay introduced in order to allow the ripple animation to play out before
    // we open a new browser tab with the tx details in the block explorer
    const LINK_DELAY = 800;
    if(this.props.match.params.coin === 'eth'){
      // If we have an ethereum transaction with a SelfTrust fee, we must first
      // show a disambiguation dialog (TxDetailsDialog) because the SelfTrust fee
      // is implemented as a separate transaction on the blockchain.
      if(tx.internalFeeAmount){
        this.setState({
          clickedTx: this.state.processedTxs[index],
          showTxDetails: true
        });
      }else{
        setTimeout(() => window.open(getBlockExplorerUrl('ETH') + 'tx/' + tx.hash, '_blank'), LINK_DELAY);
      }
    }else{
      setTimeout(() => window.open(getBlockExplorerUrl('BTC') + 'tx/' + tx.hash, '_blank'), LINK_DELAY);
    }
  }

  /**
  * Called every time the dialog with the transaction details is to be closed.
  */
  onTxDetailsClose = (e) => {
    this.setState({showTxDetails: false});
  }

  render(){
    const { props } = this;
    const { coins } = props;
    const { tabsValue } = this.state;
    const coin = coins[props.match.params.coin];
    if(coin === undefined){
      return (<React.Fragment><h1>Coin not supported</h1></React.Fragment>)
    }

    // Extending the coin object with a 'symbol' attribute
    coin.symbol = props.match.params.coin;
    let cryptoBalance = coin.balance / Math.pow(10, coin.precision);
    let fiatBalance = cryptoBalance * coin.price;
    let cryptoPrecision = 8;
    let coinName = coin.name;

    let content = null;
    if(tabsValue === 0){
      content = (
        <Send {...props}
          ethAccount={props.ethAccount}
          ethNonce={props.ethNonce}
          utxos={props.utxos}
          isLoaded={!props.isLoading}
          coin={coin}
          db={props.db}
          pubkeys={props.pubkeys}
          handleBtcTxBroadcast={this.handleBtcTxBroadcast}
          changeaddr={props.changeAddress}
          handleEthTxBroadcast={props.handleEthTxBroadcast}
          clickCounter={this.state.clickCounter}/>
      )
    }else if(tabsValue === 1){
      content = (
        <Receive
          coin={props.match.params.coin}
          ethAccount={props.ethAccount}
          pubkeys={props.pubkeys}
          addressToPath={props.addressToPath}
          freshAddresses={props.freshAddresses}
          clickCounter={this.state.clickCounter}/>
      )
    }else if(tabsValue === 2){
      content = <TxHistory  {...props}
        onTxClicked={this.onTxClicked}
        transactions={this.state.processedTxs}/>
    }
    return (
      <React.Fragment>
        <TxDetailsDialog
          isOpen={this.state.showTxDetails}
          onClose={this.onTxDetailsClose}
          tx={this.state.clickedTx}/>
        <div id="box-info-balance">
         <div className="info-balance-content">
           <IconButton className="btn-back-toolbar" onClick={this.onBackClicked}>
             <i style={{color: 'white' }} className="fas fa-arrow-left"></i>
           </IconButton>
           <div className="box-balance-icon">
             <i className={"cc "+coin.symbol.toUpperCase()}></i>
             <span>{coinName}</span>
           </div>
           <div className="box-balance-text">
             <p><span className="count">{coin.symbol} {cryptoBalance.toFixed(cryptoPrecision)}</span></p>
             <span>$ {fiatBalance.toFixed(2)}</span>
           </div>
         </div>
        </div>
        <Tabs className="tab-action" variant="fullWidth" value={tabsValue} onChange={this.handleTabsChange}>
            <Tab label="SEND" />
            <Tab label="RECEIVE" />
            <Tab label="HISTORY" />
        </Tabs>
        <section className="home_banner_area">
          {/*<LoadingModal isloading={this.props.isloading} txcount={this.props.txcount}/>*/}
          <div className="container box_1620">
            {content}
          </div>
        </section>
      </React.Fragment>
    )
  }
}

export default CoinDetails;
