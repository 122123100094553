import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import dateFormat from 'dateformat';
import { withStyles } from '@material-ui/core/styles';
import STService from '../../../../network/axios-verifier';

// Minimum amount of characters we need to start checking for the input validity.
const MIN_VERIFICATION_THRESHOLD = 3;

// Obtained from https://emailregex.com/
const EMAIL_REGEX = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: theme.spacing(1)
  },
  textField: {
    margin: theme.spacing(1)
  },
  instructionText: {
    marginLeft: theme.spacing(1.5)
  },
  button: {
    margin: theme.spacing(1)
  },
  buttonContainer: {
    textAlign: 'center'
  }
});

class TransmissionChange extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      heirEmail: '',
      testatorEmail: '',
      testatorEmailConf: '',
      testatorPhone: '',
      heirEmailError: false,
      testatorEmailError: false,
      testatorEmailConfError: false
    }
  }

  handleUpdate = () => {
    const { r, s } = this.props.signature;
    const { challenge } = this.props;
    const url = `/transmission/${this.props.walletId}?edit=true&c=${challenge}&r=${r}&s=${s}`;
    const { state } = this;
    const payload = {
      heir: {
        email: state.heirEmail
      },
      testator: {
        email: state.testatorEmail,
        phone: state.testatorPhone
      }
    }
    STService.patch(url, payload, {headers: {'content-type':'application/json'}})
      .then(resp => {
        if(resp.status === 200){
          this.props.onTransmissionChanged();
        }else{
          console.error('Error while trying to update the transmission details. Non 201 response');
        }
      })
      .catch(error => console.error('Error while trying to update the transmission details. Error: ', error));
  }

  componentDidMount() {
    const { r, s } = this.props.signature;
    const { challenge } = this.props;
    const url = `/transmission/${this.props.walletId}?c=${challenge}&r=${r}&s=${s}`;
    STService.get(url).then(resp => {
      if(resp.status === 200){
        const { data } = resp;
        const { heir, testator, dueDate } = data;
        this.setState({
          heirEmail: heir.email,
          testatorEmail: testator.email,
          testatorEmailConf: testator.email,
          testatorPhone: testator.phone,
          dueDate: new Date(dueDate)
        });
      }
    })
    .catch(error => console.error('Error while trying to obtain authorized transmission data'));
  }

  handleHeirEmailChange = event => {
    const text = event.target.value.toLowerCase();
    const stateClone = Object.assign({}, this.state);
    if(text){
      if(text.length >= MIN_VERIFICATION_THRESHOLD) {
        if(text.match(EMAIL_REGEX)) {
          stateClone.heirEmailError = false;
        }else{
          stateClone.heirEmailError = true;
        }
      }else{
        stateClone.heirEmailError = false;
      }
    }
    stateClone.heirEmail = text;
    this.setState(stateClone);
  }

  handleTestatorEmailChange = event => {
    const text = event.target.value.toLowerCase();
    const stateClone = Object.assign({}, this.state);
    const matchesEmail = text.match(EMAIL_REGEX)
    if(text){
      if(text.length >= MIN_VERIFICATION_THRESHOLD) {
        const { state } = this;
        if((matchesEmail &&
          text !== state.heirEmail &&
          text === state.testatorEmailConf) || state.testatorEmailConf === '') {
          // The testator email must match an e-email pattern and be different from the heir's
          stateClone.testatorEmailError = false;
          stateClone.testatorEmailConfError = false;
        }else{
          stateClone.testatorEmailError = true;
        }
      }else{
        stateClone.testatorEmailError = false;
      }
    }else if(this.state.testatorEmail !== ''){
      stateClone.testatorEmailError = true;
    }
    stateClone.testatorEmail = text;
    this.setState(stateClone);
  }

  handleTestatorEmailConfirmationChange = event => {
    const text = event.target.value.toLowerCase();
    const stateClone = Object.assign({}, this.state);
    const matchesEmail = text.match(EMAIL_REGEX);
    if(text){
      if(text.length >= MIN_VERIFICATION_THRESHOLD) {
        const { state } = this;
        if((matchesEmail &&
          text !== stateClone.heirEmail &&
          text === state.testatorEmail) || state.testatorEmail === '') {
          // The email confirmation must be equal to the one entered in the previous field
          stateClone.testatorEmailConfError = false;
          stateClone.testatorEmailError = false;
        }else{
          stateClone.testatorEmailConfError = true;
        }
      }else{
        stateClone.testatorEmailConfError = false;
      }
    }else if(this.state.testatorEmailConf !== ''){
      stateClone.testatorEmailConfError = true;
    }
    stateClone.testatorEmailConf = text;
    this.setState(stateClone);
  }

  handleTestatorPhoneChange = event => {
    this.setState({testatorPhone: event.target.value});
  }

  render() {
    const { classes } = this.props;
    const { heirEmailError, testatorEmailError, testatorEmailConfError } = this.state;
    const validData = !heirEmailError && !testatorEmailError && !testatorEmailConfError;
    return (
      <React.Fragment>
        <form className={classes.container} noValidate autoComplete="off">
          <TextField
            id="heir-email"
            label="Transmit my walet to:"
            className={classes.textField}
            value={this.state.heirEmail}
            onChange={this.handleHeirEmailChange}
            margin="normal"
            variant="outlined"
            error={this.state.heirEmailError}
            disabled={false}
            fullWidth={true}
          />
          <TextField
            id="due-date"
            label="On this date"
            className={classes.textField}
            value={dateFormat(this.state.dueDate, "dddd, mmmm dS, yyyy, h:MM:ss TT")}
            margin="normal"
            variant="outlined"
            disabled
            fullWidth={true}/>
          <Typography className={classes.text}>
            If I do not renew or cancel this transmission by then—when contacted at:
          </Typography>
          <TextField
            id="testator-email"
            label="Testator's e-mail"
            className={classes.textField}
            value={this.state.testatorEmail}
            onChange={this.handleTestatorEmailChange}
            margin="normal"
            variant="outlined"
            error={this.state.testatorEmailError}
            fullWidth={true}
          />
          <TextField
            id="testator-email-confirmation"
            label="Testator's e-mail confirmation"
            className={classes.textField}
            value={this.state.testatorEmailConf}
            onChange={this.handleTestatorEmailConfirmationChange}
            margin="normal"
            variant="outlined"
            error={this.state.testatorEmailConfError}
            fullWidth={true}
          />
        </form>
        <div className={classes.buttonContainer}>
          <Button
            variant="contained"
            color="primary"
            disabled={!validData}
            onClick={this.handleUpdate}
            className={classes.button}>
            Update
          </Button>
        </div>
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(TransmissionChange);
