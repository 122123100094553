import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import EmptyWallet from '../../../../assets/img/empty-wallet.jpg';

const styles = theme => ({
  container: {
    margin: '10px',
    minHeight: '10em'
  },
  image: {
    maxWidth: '100%',
    margin: '3em'
  },
  [theme.breakpoints.down('sm')]: {
    image: {
      margin: '0px'
    }
  }
});

const noFunds = props => {
  const {classes} = props;
  return (
    <div className={classes.container}>
      <Box textAlign='center' fontWeight='fontWeightMedium'>
        <img className={classes.image} src={EmptyWallet} alt='Image displaying an empty wallet'/>
        <Typography variant='h5'>You need bitcoins to use this service</Typography>
      </Box>
    </div>
  )
}

export default withStyles(styles)(noFunds);
