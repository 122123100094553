import React from 'react';
import QRCode from 'qrcode.react';
import MediaQuery from 'react-responsive';
import {
  QR_CODE_SIZES,
  QRCodesTypes } from '../../../../../../constants';
import './QRCodeStep.css';

const qrCodeStep = (props) => {
  let content = null;
  const freshAddress = props.newaddress;
  if(freshAddress){
    const payload = JSON.stringify({...freshAddress,
      type: QRCodesTypes.TYPE_ADDRESS_VERIFICATION});
    content = (
      <React.Fragment>
        <MediaQuery query="(min-device-width:1224px)">
          <QRCode size={QR_CODE_SIZES.DESKTOP} value={payload} />
        </MediaQuery>
        <MediaQuery query="(max-device-width: 1224px)">
          <QRCode size={QR_CODE_SIZES.MOBILE} value={payload} />
        </MediaQuery>
      </React.Fragment>
    )
  }else{
    content = <p>The content is loading...</p>
  }

  return (
    <React.Fragment>
      <p className="text-alert">Scan this from the SelfTrust Offline app, then click "NEXT".</p>
      <div className='qr-code-container'>
        {content}
      </div>
    </React.Fragment>
  )
}

export default qrCodeStep;
