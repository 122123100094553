import React from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Logo from '../../assets/img/logo-img.svg';
import './HeaderLogo.css';

const styles = {
  textProduct: {
    color: 'white',
    letterSpacing: '0.01em'
  },
  textSlogan: {
    color: 'white',
    letterSpacing: '0.03em'
  }
}

const headerLogo = props => {
  const { classes, logged } = props;
  // Displaying the text only if not logged in.
  const logoText = logged ? null : (
    <div className='header-container-vertical'>
      <Typography fontWeight="fontWeightBold"
        className={classes.textProduct} variant='h4'><strong>SelfTrust.me</strong></Typography>
      <Typography className={classes.textSlogan} variant='subtitle2'>Trustless Transmissible Wallet</Typography>
    </div>
  );
  return (
    <div className='header-container-horizontal'>
      <img src={Logo} className='logo' alt='Logo'/>
      {logoText}
    </div>
  )
}

export default withStyles(styles)(headerLogo);
