import bitcoin from 'bitcoinjs-lib';

const MIN_CONSOLIDATION_THRESHOLD = 3;
const DUST_LIMIT = 546;

/**
* Class used to encapsulate BTC-related wallet funcionalities.
*/
class BitcoinWallet {
  constructor(utxos, changeAddress) {
    this.utxos = utxos;
    this.changeAddress = changeAddress;
    this.consolidationEnabled = true; // Whether or not the UTXO consolidation will be enabled
  }

  /**
  * Function that will give us an unsigned transaction.
  *
  * @param {Object[]} outputs - Array of proposed outputs.
  * @param {number} fee - The desired fee rate, in sats/byte.
  */
  buildTransaction(outputs, feeRate){
    const required = outputs.reduce((accum, current) => accum + current.value, 0);
    const utxos = this.selectUtxos(required, outputs.length, feeRate);
    const txBuilder = new bitcoin.TransactionBuilder(bitcoin.networks.bitcoin);
    utxos.forEach(utxo => {
      const script = Buffer.from(utxo.script, 'hex');
      txBuilder.addInput(utxo.hash, utxo.index, 0xffffffff, script);
    });
    const change = this.createChangeOutput(utxos, outputs, feeRate);
    if(change) outputs.push(change);
    outputs.forEach(output => txBuilder.addOutput(output.address, output.amount));
    return txBuilder.buildIncomplete();
  }

  /**
  * Function that creates a change output given a set of already selected inputs,
  * provided outputs and the desired fee rate. It will return null in case a change
  * output could not be created.
  *
  * @param {Object[]} inputs - The array of selected inputs.
  * @param {Object[]} outputs - The array of required outputs.
  * @param {number} feeRate - The desired fee rate, in sat/byte.
  * @throws {Error} Will throw an exception in case the input sum is less than
  *                 the output or the fee rate would effectively be less than 1 sat/byte.
  */
  createChangeOutput(inputs, outputs, feeRate){
    let txSize = this.calculateTxSize(inputs.length, outputs.length + 1); // +1 to account for the change output
    const inputSum = inputs.reduce((accum, current) => accum + current.value, 0);
    const outputSum = outputs.reduce((accum, current) => accum + current.amount, 0);
    if(inputSum <= outputSum) throw new Error('Input amount cannot be less than or equal to output');
    const unassignedValue = inputSum - outputSum;
    const requiredFeeValue = txSize * feeRate;
    if(unassignedValue > requiredFeeValue){
      // Happy case, the unasigned value is larger than the required value
      const changeValue = unassignedValue - requiredFeeValue;
      if(changeValue > DUST_LIMIT){
        // We only create a change output if its value is above the dust limit.
        return { address: this.changeAddress, amount: changeValue }
      }else{
        return null;
      }
    }else{
      // The unassigned value can be below the nominally required fee value in case
      // the UTXO consolidation added very low valued UTXOs and the nominally required
      // fee was too high.
      // In this case no change output will be created, but we should just
      // double-check here that the real fee rate ends up staying above the
      // minRelayTxFee.
      // For this we first recalculate the tx size (this time without the extra change output)
      txSize = this.calculateTxSize(inputs.length, outputs.length);
      const realFeeRate = unassignedValue / txSize;
      console.warn(`Requested fee rate: ${feeRate}, Effective fee rate: ${realFeeRate}`);
      if(realFeeRate < 1) throw new Error('Effective fee rate would be below 1 sat/byte');
      return null;
    }
  }

  /**
  * Function that will select a number of inputs, until
  * a required amount is reached.
  *
  * @param {number} required - The required amount, in satoshis.
  * @param {number} outputCount - Number of required outputs, without considering the change.
  * @param {feeRage} feeRate - The desired fee rate, in sats/byte.
  */
  selectUtxos(required, outputCount, feeRate) {
    // Basic check
    if(this.utxos.reduce((accum, current) => accum + current.value, 0) <= required){
      return [];
    }
    // Sorting UTXOs by value in decreasing order
    const utxos = this.utxos.sort((a,b) => b.value - a.value);
    const selected = [];
    let available = 0;
    let requiredExcess = this.calculateTxSize(selected.length, outputCount + 1); // outputCount + 1 because we consider the change
    let index = 0;
    do {
      selected.push(utxos[index]);
      available = selected.reduce((accum, current) => accum + current.value, 0);
      let txSize = this.calculateTxSize(selected.length, outputCount + 1);
      // Adding a new input, will increase the size of the transaction, thus we need
      // to recalculate the how much "extra" sats we need to have to pay the miner fee.
      requiredExcess = txSize * feeRate;
      index++;
    } while (available - required < requiredExcess);
    this.addConsolidation(utxos, selected);
    return selected;
  }

  /**
  * This function will check that the consolidation requirements are met and only
  * then add extra UTXOs.
  */
  addConsolidation(utxos, selected) {
    if(this.consolidationEnabled && this.utxos.length > MIN_CONSOLIDATION_THRESHOLD && selected.length === 1)
      selected.push(...utxos.slice(-2));
  }

  /**
  * This assumes the transaction will be spending from inputs
  * that use compressed public keys, as explained here:
  * https://bitcoin.stackexchange.com/questions/1195/how-to-calculate-transaction-size-before-sending-legacy-non-segwit-p2pkh-p2sh
  *
  * It will give the maximum possible size for a transaction.
  * @param {number} inputCount - Number of inputs
  * @param {number} outputCount - Number of outputs
  * @return {number} The maximum expected size of a transaction with the given input & output configuration
  */
  calculateTxSize(inputCount, outputCount){
    return inputCount * 148 + outputCount * 34 + inputCount;
  }
}

export default BitcoinWallet;
