import React, {Component} from 'react';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router';
import Logo from '../../../assets/img/logo-img.svg';
import Feature from './Feature/Feature';
import Footer from './Footer/Footer';
import WelcomeButton from './WelcomeButton/WelcomeButton';
import Easy from '../../../assets/img/easy.svg';
import Safe from '../../../assets/img/safe.svg';
import Bequeathable from '../../../assets/img/bequeathable.svg';
import Instructions from './Instructions/Instructions';
import ReactGA from 'react-ga';
import QrReader from 'react-qr-reader';
import querystring from 'querystring';
import { isIOS, isSafari } from 'react-device-detect';

const APK_URL = 'https://play.google.com/apps/testing/me.selftrust.offline';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  toolbarContainer: {
    margin: '8px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  logoContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  logo: {
    margin: '5px',
    height: '52px'
  },
  messageText: {
    margin: '40px 30px 40px 30px'
  },
  featuresContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    flexGrow: 1
  },
  scanContainer: {
    width: '400px',
    margin: '10px auto'
  },
  [theme.breakpoints.down('sm')]:{
    featuresContainer: {
      flexDirection: 'column'
    },
    '@media (orientation: landscape)': {
      root: {
        minHeight: '180vh'
      }
    },
    scanContainer: {
      width: '90%'
    }
  },
  [theme.breakpoints.up('sm')]:{
    featuresContainer: {
      flexDirection: 'row'
    }
  }
})

const features = [
  {
    title: 'Safe',
    icon: Safe,
    items: ['Create & store secrets—offline', 'Use your own entropy', 'Minimize hardware risks']
  },
  {
    title: 'Easy',
    icon: Easy,
    items: ['Standard & multicoin', 'Auditable by anyone', 'Affordable to anyone']
  },
  {
    title: 'Bequeathable',
    icon: Bequeathable,
    items: ['Non-custodial', 'Change your will at any time', 'Enjoy your coins till the end']
  }
];

/**
* Test payload. This emulates the contents of the login QR code, which would
* be carried by the login QR code present at the offline app.
*/
const TEST_LOGIN_PAYLOAD = {
  "btc":"xpub6Bz8ZKPQJmS6Ah8134mRBDWaeRaL9yatoWJt2JA6H4P6iayk1GpVVT4khGSa5sRmyMZWW5KXVXF3euhGJuUt1DEGUBWZ2KME3EfiUGJwMao",
  "eth":"xpub6CxPFWT8Dp6iiarWxqmJbrMwADhdpuKXwGmEyGqeh5pda7eN8RNXM4fTnbWiFuJBZPbeHqJwyxrSdk4vApzRirsZ2HrRbHxGFVVDK2MSCr6",
  "id": "02a0364a8606ea8e9775612a99e270f6e93580a2bec6e4f17734ec5b967d262c66",
  "version": "0.1.6"
};

class Welcome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scanning: false,
      result: null,
      error: null
    };

    // Automatically track all routes
    props.history.listen(location => ReactGA.pageview(location.pathname));
  }

  handleScan = data => {
    let errorMsg = null;
    try{
      if (data != null) {
        const parsed = JSON.parse(data);
        if(parsed.btc && parsed.eth){
          this.props.handleLogin(parsed);
          return;
        }
      }
    }catch(e){
      console.error(e);
      errorMsg = <p style={{color:'red'}}>Not a valid login QR code</p>;
    }
    if(errorMsg != null){
      this.setState({
        scanning: false,
        result: data,
        error: errorMsg
      });
    }
  }

  handleError = (err) => {
    console.error(err);
    this.setState({
      error: `Error. Msg: ${err.message}`
    });
  }

  onLoginClicked = event => {
    this.setState((prevState, currentProps) => {
      prevState.scanning = !prevState.scanning;
      return {...prevState};
    });
  }

  onTestLoginClicked = event => {
    this.props.handleLogin(TEST_LOGIN_PAYLOAD);
  }

  onDownloadClicked = event => {
    this.props.history.push('/releases');
  }

  componentDidMount() {
    const { location } = this.props;
    if(location.search === '?scan=true') {
      this.setState({scanning: true});
    }
  }

  render() {
    const { classes, location } = this.props;
    const { scanning } = this.state;

    // Setting up a test login button
    let testButton = null;
    const params = querystring.parse(location.search.substring(1));
    if(params.test === 'true') {
      testButton = (
        <WelcomeButton type='filled' onClick={() => this.onTestLoginClicked()}>
          Test Login
        </WelcomeButton>
      )
    }

    let content = null;
    if(scanning){
      if(isIOS && !isSafari){
        content = (
          <div className={classes.scanContainer}>
            <h4 style={{color:'red'}}>On iOS only the Safari browser is supported</h4>
          </div>
        )
      }else{
        let scanError = <h4 style={{color :'red'}}>{this.state.error}</h4>;
        content = (
          <div className={classes.scanContainer}>
            <QrReader
              delay={500}
              facingMode='environment'
              onError={this.handleError}
              onScan={this.handleScan}
              style={{ width: "100%" }}/>
            {scanError}
          </div>
        )
      }
    }else{
      content = (
        <React.Fragment>
          <Typography
            className={classes.messageText}
            variant='h5'
            color='primary'
            align='center'>
              Store, transfer, bequeath your crypto-assets—right
          </Typography>
          <div
            className={classes.featuresContainer}>
            {features.map(feature => (
              <Feature
                key={feature.title}
                icon={feature.icon}
                title={feature.title}
                items={feature.items}/>
            ))}
          </div>
          <Instructions/>
          <Footer/>
        </React.Fragment>
      )
    }
    return (
      <div className={classes.root}>
        <AppBar position="static">
            <div className={classes.toolbarContainer}>
              <div className={classes.logoContainer}>
                <img src={Logo} className={classes.logo} alt='Logo'/>
                <Typography>selftrust.me</Typography>
              </div>
              <div>
                <WelcomeButton type='outlined' onClick={() => this.onDownloadClicked()}>
                  Download
                </WelcomeButton>
                {params.test === 'true' ? null : (
                  <WelcomeButton type='filled' onClick={() => this.onLoginClicked()}>
                    {scanning ? 'Cancel' : 'Login'}
                  </WelcomeButton>
                )}
                {testButton}
              </div>
            </div>
        </AppBar>
        {content}
      </div>
    )
  }
}

export default withStyles(styles)(withRouter(Welcome));
