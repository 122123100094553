import React, {Component} from 'react';
import App from '../../App';
import {Route, Redirect, Switch} from 'react-router-dom';
import bip32 from 'bip32';
import ReactGA from 'react-ga';
import Main from './Main/Main';
import CoinDetails from './CoinDetails/CoinDetails';
import Faq from './Faq/Faq';
import Settings from './Settings/Settings';
import Transmission from './Transmission/Transmission';
import Welcome from './Welcome/Welcome';
import Releases from './Releases/Releases';
import bitcoin from 'bitcoinjs-lib';
import Web3 from 'web3';
import Dexie from 'dexie';
import CoinGecko from 'coingecko-api';
import axiosBtcNode from '../../network/axios-btc-node';
import EtherscanApi from '../../network/axios-etherscan-api'
import utils from 'ethereumjs-util';
import WalletWorker from './keys.worker';
import { IntlProvider, addLocaleData } from 'react-intl';
import intlEN from 'react-intl/locale-data/en';
import intlZH from 'react-intl/locale-data/zh';
import intlMessagesEN from '../../i18n/en.json';
import intlMessagesZH from '../../i18n/zh-CN.json';
import { ETH_TX_BATCH_SIZE, INFURA_URL, INFURA_WSS_URL,
  POSIBLE_INTERNAL_FEE_ADDRESSES, KEY_WALLET_ID,
  CONFIRMATION_THRESHOLD, KEY_HAS_ALERT_BEEN_DISMISSED } from '../../constants';
import Erc20Wallet from '../../js/erc20';
import BigNumber from 'bignumber.js';
import axiosVersion from '../../network/axios-version';
import { version } from '../../../package.json';

const KEY_LAST_VERSION_CHECK = 'key_last_version_check';
const VERSION_CHECK_PERIOD = 10 * 60 * 1000;

addLocaleData([...intlEN, ...intlZH]);
/* Define your default translations */
let i18nConfig = {
    locale: 'en',
    messages: intlMessagesEN
};

// Constants used to define the state of prices, balances and cached data
const NOT_LOADED = 0;
const LOADING = 1;
const LOADED = 2;

const KEY_XPUB = 'key.xpub';
const KEY_DERIVATION_PATH = 'key.derivation_path';
const KEY_FRESH_EXTERNAL_ADDRESS = 'key.fresh_external_addresses';
const KEY_FRESH_INTERNAL_ADDRESS = 'key.fresh_internal_addresses';
const KEY_COINGECKO_LAST_UPDATE = 'key.coingecko.last_update';
const COINGECKO_REFRESH_PERIOD = 1000 * 60 * 60 * 24;
const WALLET_GAP = 20;
const BIP_49_BASE_PATH = 'M/49H/0H/0H/';
const WALLET_REFRESH_PERIOD = 60000;

// Loading constants
const LOAD_BTC = 'load_btc';
const LOAD_ETH = 'load_eth';

class Wallet extends Component {
  constructor(props){
    super(props)
    const stored = localStorage.getItem(KEY_XPUB);
    let cachedPubkeys = null;
    if(stored && stored !== ''){
      cachedPubkeys = JSON.parse(stored);
    }
    this.taskCounter = 0;
    this.state = {
      id: localStorage.getItem(KEY_WALLET_ID),
      pubkeys: cachedPubkeys,
      coins: {
        btc: {
          name: 'bitcoin',
          symbol: 'btc',
          price: null,
          balance: 0,
          precision: 8,
          priority: 1
        },
        eth: {
          name: 'ethereum',
          symbol: 'eth',
          price: null,
          balance: new BigNumber(0),
          precision: 18,
          priority: 2
        }
      },
      utxos: [],
      ethAccount: null,
      ethNonce: -1,
      changeAddress: null,
      freshAddresses: [],
      transactions: [],
      locale: 'en',
      loadingState: {
        cacheLoaded: NOT_LOADED,
        balancesLoaded: NOT_LOADED
      },
      progress: 0,
      networkError: null,
      test: false
    }
    // Used to store all known addresses of this wallet
    this.knownAddresses = {
      internal: [],
      external: []
    }
    // Used to keep track of the derivation path of each address
    this.addressToPath = new Map();

    // Creating a dexie db reference
    this.db = new Dexie('selftrust');
    this.db.version(1).stores({
      addresses:'&path',
      txs:'&hash',
      processedTxs: '&hash,*name,time',
      utxos: '&[hash+index]',
      coingecko: 'symbol'
    });
    this.db.version(2).stores({
      coins: '&symbol,priority,name'
    });
    this.db.version(3).stores({
      addresses:'&path,address',
      processedTxs: '&hash,*name,time,confirmations'
    });
    this.db.version(4).stores({
      processedTxs:'&hash,*name,time,confirmations,[confirmations+name]'
    });

    // Initiate the CoinGecko API Client
    this.coinGeckoClient = new CoinGecko();

    // Flag used to indicate whether the BTC & ETH balances are loading
    this.isLoadingBtc = false;
    this.isLoadingEth = false;

    // Web3 HTTP instance
    this.web3 = new Web3(new Web3.providers.HttpProvider(INFURA_URL));

    // Web3 websocket instance
    this.websocketWeb3 = new Web3(new Web3.providers.WebsocketProvider(INFURA_WSS_URL));
    this.blockSubscription = this.websocketWeb3.eth.subscribe('newBlockHeaders', (error, result) => {
      if(!error){
        this.handleEthBlockUpdate(result);
      }else{
        console.error('Subscribe to new block headers returned error. Err: ', error);
      }
    });
    this.blockSubscription.on('error', console.error);
  }

  static getKeyXpub(){
    return KEY_XPUB;
  }

  static getKeyDerivationPath(){
    return KEY_DERIVATION_PATH;
  }

  handleEthBlockUpdate = async (result) => {
    let pendingTxs = await this.db.processedTxs.where('confirmations').below(CONFIRMATION_THRESHOLD.ETH).toArray();
    pendingTxs = pendingTxs.filter(tx => tx.name === 'eth');
    const promises = pendingTxs.map(tx => {
      return this.web3.eth.getTransaction(tx.hash);
    });
    const responses = await Promise.all(promises);
    responses.forEach(updatedTx => {
      if(updatedTx && updatedTx.blockNumber){
        const changes = {
          height: updatedTx.blockNumber,
          confirmations: (1 + result.number - updatedTx.blockNumber)
        }
        const promise = this.db.processedTxs.update(updatedTx.hash, changes);
        promise.then(number => console.log('Number of entries updated: ', number));
        promise.catch(error => console.error('Error while trying to update processed tx. Error: ', error));
      }
    });
  }

  componentDidMount(){
    // Will update the list of ERC-20 tokens if necessary
    this.checkCoingeckoCoinList();

    // Subscribing to coin creation events
    this.db.coins.hook('creating', (primKey, obj, transaction) => {
      if(!(obj instanceof Promise)){
        // Obtaining a reference to the current 'coins' object from the
        // state and updating it.
        const coinsClone = Object.assign({}, this.state.coins);
        coinsClone[obj.symbol] = obj;
        this.setState({coins: coinsClone});
      }else{
        console.warn('Obj returned was promise. primKey: ', primKey);
      }
    });

    // Subscribing to coin update events
    this.db.coins.hook('updating', (modifications, primKey, obj, transaction) => {
      // Cloning the 'coins' object so we can
      const coinsClone = Object.assign({}, this.state.coins);
      coinsClone[obj.symbol] = obj;
      Object.keys(modifications).forEach(key => {
        coinsClone[obj.symbol][key] = modifications[key];
      });
      this.setState({coins: coinsClone});
    });

    // Subscribing to coin removal events
    this.db.coins.hook('deleting', (primKey, obj, transaction) => {
      const coinsClone = Object.assign({}, this.state.coins);
      delete coinsClone[obj.symbol];
      this.setState({coins: coinsClone});
    });

    this.db.coins.orderBy('priority').toArray().then(coins => {
      if(coins.length === 0){
        // Start getting coins
        this.maybeRefreshWallet();
      }else{
        // Here we will be setting the state with the coin data
        // retrieved from the indexed db right away.
        // But first we must transform an array of coins into an object
        // containing each coin object as the value mapped by a key which
        // is the coin symbol. E.g.: [{..},{..}] => {btc:{..}, eth: {..}}
        const stateCoins = {};
        coins.forEach(coin => {
          stateCoins[coin.symbol] = coin;
        });
        this.setState({
          coins: stateCoins
        });

        // Calling the Coingecko API in order to obtain a price update
        this.updatePrices(stateCoins);
      }
    });
  }

  componentWillUnmount(){
    this.blockSubscription.unsubscribe((error, success) => {
      if(success){
        console.log('unsubscribe returned: ', success);
      }
    });
  }

  /**
  * Function that will contact the coingecko API and obtain a list of supported coins.
  */
  checkCoingeckoCoinList = async () => {
    const coingeckoCoinCount = await this.db.coingecko.count();
    let missingMandatoryCoins = false;
    try {
      // Here we just check that the mandatory assets area loaded in the 'coingecko' table
      const mandatoryCoins = await this.db.coingecko.where('symbol').anyOf('btc','eth').toArray();
      const mandatorySymbols = mandatoryCoins.map(coin => coin.symbol);
      missingMandatoryCoins = mandatorySymbols.indexOf('eth') === -1 || mandatorySymbols.indexOf('btc') === -1;
    }catch(error){ console.error('Error while trying to query mandatory coins'); }

    const storedDate = localStorage.getItem(KEY_COINGECKO_LAST_UPDATE);
    if(coingeckoCoinCount === 0 || missingMandatoryCoins || storedDate === null || storedDate === '' || new Date(JSON.parse(storedDate).date) <= new Date().getTime() - COINGECKO_REFRESH_PERIOD){
      // If we don't have the list of coingecko's supported coins OR if the data is stale
      // we perform a request to obtain it.
      let response = await this.coinGeckoClient.coins.list();
      // Storing the last update timestamp
      const coinListUpdateTimestamp = {date: new Date().getTime()};
      localStorage.setItem(KEY_COINGECKO_LAST_UPDATE, JSON.stringify(coinListUpdateTimestamp));
      // Storing list of coingecko's supported coins in the indexed db
      return this.db.coingecko.bulkPut(response.data.map(cgCoin => {return {id: cgCoin.id, symbol: cgCoin.symbol}}));
    }else{
      return null;
    }
  }

  componentDidUpdate(){
    const KEY_LAST_ACTION = App.getKeyActionTimestamp();
    const now = Date.now();
    const lastActive = localStorage.getItem(KEY_LAST_ACTION) || 0;
    const lastVersionCheck = localStorage.getItem(KEY_LAST_VERSION_CHECK) || 0;
    // If enough time has elapsed, we must check for a newer version of the ST Browser
    if(now - lastVersionCheck > VERSION_CHECK_PERIOD) {
      this.checkVersions();
    }
    const elapsedTime = now - lastActive;
    if(elapsedTime > App.getDefaultTimeoutValue()){
      // If the user has been inactive for any time longer than the
      // DEFAULT_TIMEOUT_VALUE, then we just automatically logout
      localStorage.setItem(KEY_LAST_ACTION, Date.now());
      this.handleLogout();
    }else{
      this.maybeRefreshWallet();
    }
  }

  /**
  * A version callback.
  * @callback versionCallback
  * @param {Object} versions - The 'versions' object, as stored in the state, or null in case no version update was performed.
  */

  /**
  * Function used to request the most up to date versions of the mobile & web apps.
  * @param {versionCallback} [callback] - The function to call whenever the version check is done.
  */
  checkVersions = callback => {
    // Giving a dummy 't' parameter with a timestamp in order to prevent caching
    // of this response on Safari for iOS.
    axiosVersion.get('versions.json' + '?t=' + new Date().getTime()).then(response => {
      if(response.status === 200){
        localStorage.setItem(KEY_LAST_VERSION_CHECK, Date.now());
        console.log('remote version..: ', response.data.web);
        console.log('local version...: ', version);
        const [serverMayor, serverMinor, serverPatch] = response.data.web.split('.');
        const [localMayor, localMinor, localPatch] = version.split('.');
        if(parseInt(serverMayor) > parseInt(localMayor) ||
            parseInt(serverMinor) > parseInt(localMinor) ||
            parseInt(serverPatch) > parseInt(localPatch)) {
          console.log('reloading window');
          // If it turns out that the server has a more recent version
          // than what we're running we must reload the page.
          window.location.reload();
        }else{
          // Reporting the updated remote versions
          const remoteVersions = response.data;
          if(callback) callback(remoteVersions);
        }
      }else{
        console.error('Error while trying to fetch remote versions. Status: ', response.status);
        if(callback) callback(null);
      }
    }).catch(error => {
      console.error('Could not fetch the latest version number. Error: ', error)
      localStorage.setItem(KEY_LAST_VERSION_CHECK, Date.now());
      if(callback) callback(null);
    });
  }

  /**
  * Function used to update the price value of the coins.
  * @param coins The coins currently stored in the state.
  */
  updatePrices = async (coins) => {
    const symbols = Object.keys(coins).map(key => coins[key].symbol);
    const cgCoins = await this.db.coingecko.where('symbol').anyOf(symbols).toArray();
    const ids = cgCoins.map(cgCoin => cgCoin.id);
    if(ids && ids.length > 0){
      let resp = null;
      try{
        resp = await this.coinGeckoClient.simple.price({ids: ids, vs_currencies: 'usd'});
      }catch(error){
        console.error('Failed to fetch price for: ', symbols, ', Error: ', error);
      }
      if(resp && resp.success){
        Object.keys(resp.data).forEach(async (cgName) => {
          try{
            const coin = await this.db.coins.where('name').equalsIgnoreCase(cgName).first();
            if(coin){
              coin.price = resp.data[cgName].usd;
              await this.db.coins.put(coin);
            }
          }catch(error){
            console.error('Caught an indexed db error while trying to update coin. Error: ', error);
            alert(`Caught an indexed db error while trying to update coin. Error: ${error}`);
          }
        });
      }
    }
  }

  /**
  * Function used to reload different parts of this wallet.
  */
  reloadWallet = () => {
    if(!this.isLoadingBtc){
      console.log('> Updating BTC balances <');
      this.updateBalances(LOAD_BTC);
    }else{
      console.warn('Skipping BTC refresh since last one appears to still be running');
    }
    if(!this.isLoadingEth){
      console.log('> Updating ETH balances <');
      this.updateBalances(LOAD_ETH);
    }else{
      console.warn('Skipping ETH refresh since last one appears to still be running');
    }
  }

  maybeRefreshWallet = () => {
    // Retrieving public keys from the local storage
    const stored = localStorage.getItem(KEY_XPUB);
    const {cacheLoaded, balancesLoaded} = this.state.loadingState;
    if(stored && stored !== ''){
      // Retrieving pubkeys from the local storage
      const pubkeys = JSON.parse(stored);
      // This variable will be used to determine whether we should update the state
      // and prevent an infinite loop
      let updateState = false;
      // If the state is already up to date, we should just get balances
      // and current price data
      if(cacheLoaded === NOT_LOADED){
        console.log('> Loading cached data');
        this.loadCachedData();
        updateState = true;
      }
      if(balancesLoaded === NOT_LOADED){
        console.log('> Loading balances');
        this.updateBalances();
        updateState = true;
      }
      if(updateState){
        this.setState((prevState, currentProps) => {
          if(cacheLoaded === NOT_LOADED)
            prevState.loadingState.cacheLoaded = LOADING;
          if(balancesLoaded === NOT_LOADED)
            prevState.loadingState.balancesLoaded = LOADING;
          prevState.pubkeys = pubkeys;
          return prevState;
        });
      }
    }else{
      if(this.state.pubkeys !== null){
        // If the stored pubkeys are now null, but the state is still not, just update it
        console.log('Stored is null, setting state to null as well');
        this.setState({pubkeys: null});
      }
    }

    const xpub = localStorage.getItem(KEY_XPUB, '');
    // Scheduling wallet refresh every WALLET_REFRESH_PERIOD milliseconds in
    // case we already don't have one AND we have pubkeys, which means the
    // wallet is unlocked.
    if(!this.intervalId && xpub !== ''){
      console.log('>> Scheduling refresh task <<')
      this.intervalId = window.setInterval(() => {
        console.log('** Running scheduled task ** - count: ', this.taskCounter);
        this.taskCounter++;
        if(this.state.pubkeys && this.state.pubkeys.length > 0){
          this.reloadWallet();
        }
      }, WALLET_REFRESH_PERIOD);
    }
  }

  loadCachedData = async () => {
    // Retrieving cached UTXO information
    const utxos = [];
    if(this.db.utxos)
      utxos.push(...await this.db.utxos.toArray());

    const totalSatBalance = utxos.reduce((accum, item) => accum + item.value, 0);

    // Loading processed transactions from the cache
    const processedTxs = await this.db.processedTxs.toArray();

    // Loading fresh addresses
    const freshAddresses = localStorage.getItem(KEY_FRESH_EXTERNAL_ADDRESS);

    //Loading stored change addresses
    const changeAddress = localStorage.getItem(KEY_FRESH_INTERNAL_ADDRESS);

    // Setting the state, which will update the UI with the values retrieved from the cache
    this.setState((prevState, currentProps) => {
      prevState.utxos = utxos;
      prevState.changeAddress = JSON.parse(changeAddress);
      if(totalSatBalance > 0 || (processedTxs && processedTxs.length > 0)){
        const hasBtc = processedTxs.map(tx => tx.name).reduce((accum, current) => current === 'btc' || accum, false);
        if(hasBtc){
          prevState.loadingState.cacheLoaded = LOADED;
        }
      }
      if(freshAddresses){
        // Updating state with previously known fresh addresses
        prevState.freshAddresses = JSON.parse(freshAddresses);
      }
      return prevState;
    });
  }

  /**
  * Generic function that can be used to independently trigger either a
  * BTC or ETH wallet reload.
  */
  updateBalances = async (which) => {
    let pubkeys = null;
    // Retrieving pubkeys either from the state or directly from the local storage
    if(this.state.pubkeys){
      pubkeys = this.state.pubkeys;
    }else{
      const strXpub = localStorage.getItem(KEY_XPUB);
      pubkeys = JSON.parse(strXpub);
    }
    switch(which) {
      case LOAD_ETH:
        this.updateEthBalance(pubkeys[1].key);
        this.updateTokenBalance(pubkeys[1].key);
        break;
      case LOAD_BTC:
        this.updateBtcBalance(pubkeys[0].key);
        break;
      default:
        this.updateEthBalance(pubkeys[1].key);
        this.updateTokenBalance(pubkeys[1].key);
        this.updateBtcBalance(pubkeys[0].key);
    }
  }

  getEthAddress = (ethPubKey) => {
    const ethAccountNode = this.getKey(ethPubKey, '0'); // Using Ledger's path M/44'/60'/0'/0
    const ethAccountPubKey = new Buffer(ethAccountNode['__Q']);
    return '0x' + utils.pubToAddress(ethAccountPubKey, true).toString('hex');
  }

  updateTokenBalance = (ethPubKey) => {
    this.isLoadingEth = true;
    const ethAddress = this.getEthAddress(ethPubKey);
    // Instantiating ERC-20 wallet
    this.erc20Wallet = new Erc20Wallet(ethAddress);
    this.erc20Wallet.loadTokens().then(async (tokens) => {
      // Here we're getting a filtered list of ERC-20 tokens that the
      // current account has a non-zero balance for.

      // Obtaining an array of coin symbols
      const symbols = tokens.map(token => token.symbol);
      // Updated the list of coins if necessary
      const promise = this.checkCoingeckoCoinList();
      if(promise) await promise;
      // Performing a query in order to look for the CoinGecko's id for every
      // loaded ERC-20 token
      const cgCoins = await this.db.coingecko.where('symbol').anyOf(symbols).toArray();
      if(cgCoins.length > 0){
        const cgIds = cgCoins.map(cgCoin => cgCoin.id); // Coingecko coin ids array
        // Using the CoinGecko API to obtain the current USD price of every one
        // of the loaded ERC-20 tokens
        const resp = await this.coinGeckoClient.simple.price({ids: cgIds, vs_currencies: 'usd'});
        // After we got a response from the coingecko API, we must iterate over all its keys
        // looking for a matches between our loaded tokens. In case the token names
        // don't match, the price will not be updated.
        Object.keys(resp.data).forEach(cgCoinName => {
          Object.keys(tokens).forEach(symbol => {
            if(tokens[symbol].name.toLowerCase() === cgCoinName.toLowerCase()){
              tokens[symbol].price = resp.data[cgCoinName].usd;
            }
          });
        });
        tokens = tokens.map(token => {
          token.balance = token.balance.toString();
          return token;
        });
        tokens.forEach( async (token) => {
          const thisTokenCount = await this.db.coins.where('symbol').equals(token.symbol).count();
          const totalCoins = await this.db.coins.count();
          if(thisTokenCount === 0){
            // This is a new token, so we must insert an entry for it in the 'coins' table
            token.priority = totalCoins + 1;
            const id = await this.db.coins.put(token);
            console.log('Just inserted token with id: ', id);
          }else{
            const storedToken = await this.db.coins.where('symbol').equals(token.symbol).first();
            // Updating the 'coin' token entry in case the balance has changed.
            if(storedToken.balance !== token.balance.toString()){
              await this.db.coins.put(token);
            }
          }
        });
      }
    });
    this.isLoadingEth = false;
  }

  /**
  * Function that will update the ETH balance of the wallet.
  */
  updateEthBalance = async (ethPubKey) => {
    console.log('updateEthBalance');
    this.isLoadingEth = true;
    const ethAddress = this.getEthAddress(ethPubKey);
    let balance = await this.web3.eth.getBalance(ethAddress);
    const nonce = await this.web3.eth.getTransactionCount(ethAddress, 'pending');

    // In case we don't have any entry in the list of coingecko supported coins, we must
    // obtain it.
    const promise = this.checkCoingeckoCoinList();
    if(promise) await promise;

    let ethCoin = null;
    try{
      // Trying to obtain existing instance of the eth coin
      ethCoin = await this.db.coins.where('symbol').equals('eth').first();
    }catch(error){
      console.error('Error while trying to obtain the current eth coin instance from db. Msg: ', error);
    }
    if(!ethCoin){
      console.log('Creating new eth coin instance');
      // If no instance was found, then maybe this is the first time we're
      // doing this and a new entry has to be created.
      ethCoin = {
        name: 'Ethereum',
        precision: 18,
        symbol: 'eth',
        priority: 2,
        isToken: false
      }
    }
    if(ethCoin.balance !== balance){
      const pendingTxs = await this.db.processedTxs.where({confirmations: 0, name: 'eth'}).toArray();
      if(pendingTxs.length === 0){
        // We only updathe the ETH balance in case we're sure we don't
        // have any pending transaction
        ethCoin.balance = balance.toString();
        await this.db.coins.put(ethCoin);
      }else{
        console.log('Skipping balance update for now since we have pending txs: ', pendingTxs);
      }
    }

    // Updating ETH price
    this.updatePrices({eth: ethCoin});

    // Obtaining the list of transactions from a given account using the
    // etherescan API.
    const txs = [];
    let loadedTxs = false;
    let page = 1;
    while(!loadedTxs){
      try{
        const txResp = await EtherscanApi.getTransactions(ethAddress, 0, 99999999, page, ETH_TX_BATCH_SIZE);
        if(txResp && txResp.status === 200){
          txs.push(...txResp.data.result);
          if(txResp.data.result.length < ETH_TX_BATCH_SIZE){
            loadedTxs = true;
          }
          page++;
        }else{
          if(txResp && txResp.status === '0') throw txResp.message; else throw 'Got error while trying to fetch tx list';
        }
      }catch(error){
        loadedTxs = true;
        console.error('Got error while trying to fetch txlist. Error: ', error);
      }
    }
    // Here we proceed to filter txs because apparently the etherscan API
    // is returning repeated entries.
    const hashMap = new Map();
    const filteredTxs = txs.filter(element => {
      if(hashMap.has(element.hash))
        return false;
      hashMap.set(element.hash, true);
      return true;
    });
    this.processEthTransactions(ethAddress, filteredTxs);
    this.setState((prevState, currentProps) => {
      prevState.ethAccount = ethAddress;
      prevState.ethNonce = nonce;
      return prevState;
    });
    this.isLoadingEth = false;
  }

  updateBtcBalance = async (xpub) => {
    this.isLoadingBtc = true;
    let gotError = false;
    const t0 = performance.now();
    this.knownAddresses.internal = []; // Array that holds all known internal addresses
    this.knownAddresses.external = []; // Array that holds all known external addresses

    // Important constants
    const BATCH_SIZE = WALLET_GAP;
    const RETRY_MAX = 50;

    // Looping over all internal addresses and collecting available UTXOs
    let internalIndex = 0;
    let batchCounter = 0;
    // Instantiating a WebWorker to take care of the received data
    let walletWorker = new WalletWorker();
    // Sending a message to the worker in order to clear all old tx data
    walletWorker.postMessage({type: 'start-tx-load'});
    let endReached = false;
    let freshChangeAddress = null; // TODO: Change this later, fresh addresses should be queried from the db
    // Array with addresses to be included in this batch
    let addressBatch = [];
    let safeCounter = 0;
    console.log('-- INTERNAL ADDRESSES -- ');
    while(!endReached && safeCounter < RETRY_MAX){
      // console.log(`> Trying to obtain address in the range: ${internalIndex}-${BATCH_SIZE * (batchCounter + 1)}`);
      while(internalIndex < BATCH_SIZE * (batchCounter + 1)){
        const path = `1/${internalIndex}`;
        // Creating a promise that will resolve into an address given a xpub & path pairs
        const promise = new Promise((resolve, reject) => {
          const worker = walletWorker;
          worker.postMessage({type:'address-derivation', xpub: xpub, path: path});
          worker.addEventListener('message', key => {
            resolve(key);
          });
        });
        // Creating a P2WKH-in-P2SH address
        const address = (await promise).data;
        // Including newly derived address into the batch
        addressBatch.push(address);
        // Mapping the derivation path -> address relationship
        this.addressToPath.set(address, BIP_49_BASE_PATH + path);
        // Storing this address
        this.knownAddresses.internal.push(address);
        internalIndex++;
      }
      if(!this.state.pubkeys){
        // In case the user has already logged out, we just cancel this operation
        console.log('Cancelling btc update');
        break;
      }
      // Performing network request for the batch of addresses
      try{
        const txsResp = await axiosBtcNode.post('/tx/address', {addresses: addressBatch});
        walletWorker.postMessage({type:'new-tx-batch', txs: txsResp.data});
        // If we don't yet have a fresh change address, we iterate over all the 
        // addresses in the batch until we find one without activity. The first
        // one we find is the chosen one.
        if(freshChangeAddress === null){
          for(let index in addressBatch){
            const address = addressBatch[index];
            let hasActivity = false;
            txsResp.data.forEach(tx => {
              tx.outputs.forEach(output => {
                if(output.address === address){
                  hasActivity = true;
                }
              });
            });
            if(!hasActivity){
              freshChangeAddress = address;
              break;
            }
          }
          localStorage.setItem(KEY_FRESH_INTERNAL_ADDRESS, JSON.stringify(freshChangeAddress));
        }
        // Whenever we find less transactons than addresses in a batch, 
        // it means we reached the end
        if(txsResp.data.length < BATCH_SIZE){
          endReached = true;
        }
        // Updating loading progress
        this.setState((prevState, nextProps) => {
          prevState.progress = prevState.progress + BATCH_SIZE;
          return prevState;
        });
        batchCounter++;
        addressBatch = [];
      }catch(error){
        console.error('Error while trying to fetch tx from internal address batch. Error: ', error);
        // In case of error we want to retry, so we reset the internal index
        internalIndex = internalIndex - BATCH_SIZE;
        // And incremente the safeCounter. This will prevent an infinite loop.
        safeCounter++;
      }
    }
    if(safeCounter === RETRY_MAX){
      console.warn('Network retry limit has been reached.');
      // Recording error
      gotError = true;
      this.setState({networkError: true});
    }

    console.log('-- EXTERNAL ADDRESSES -- ');
    // Looping over all external addresses, and collecting all UTXOs
    // until we find a gap greater than 20 addresses
    let gapFound = false;
    let externalIndex = 0;
    // Resetting counters
    batchCounter = 0;
    safeCounter = 0;
    // Resetting the address batch array
    addressBatch = [];
    let pastAddressBatch = [];
    let pastTxResp = null;
    // Array used to hold simple (unused) addresses
    let unusedExternalAddresses = [];
    // Array used to hold fresh address bundles in the form: {address: <value>, path: <value>}
    const freshAddressBundles = [];
    while(!gapFound && walletWorker && safeCounter < RETRY_MAX){
      // console.log(`> Trying to obtain address in the range: ${externalIndex}-${BATCH_SIZE * (batchCounter + 1)}`);
      while(externalIndex < BATCH_SIZE * (batchCounter + 1)){
        const path = `0/${externalIndex}`;
        // Creating a promise that will resolve into an address given a xpub & path pairs
        const promise = new Promise((resolve, reject) => {
          const worker = walletWorker;
          worker.postMessage({type: 'address-derivation', xpub: xpub, path: path});
          worker.addEventListener('message', event => {
            resolve(event);
          });
        });
        // Creating a P2WKH-in-P2SH address
        const address = (await promise).data;
        // Mapping the derivation path -> address relationship
        this.addressToPath.set(address, BIP_49_BASE_PATH + path);
        // Including newly derived address into the batch
        addressBatch.push(address);
        // Storing this address
        this.knownAddresses.external.push(address);
        externalIndex++;
      }
      try {
        const txsResp = await axiosBtcNode.post('/tx/address', {addresses: addressBatch});
        walletWorker.postMessage({type:'new-tx-batch', txs: txsResp.data});
        if(txsResp.data.length === 0){
          gapFound = true;
          // If the gap has been found we would also like to obtain a
          // batch of unused external addresses
          if(pastTxResp){
            let hasActivity = false;
            // We will iterate on the past address batch backwards in order
            // to find out where the gap actually starts.
            for(let index = pastAddressBatch.length - 1; index >= 0; index--){
              let address = pastAddressBatch[index];
              pastTxResp.data.forEach(tx => {
                tx.outputs.forEach(output => {
                  if(output.address === address){
                    hasActivity = true;
                  }
                });
              });
              if(hasActivity){
                // At the first sign of activity we must break
                if(index < pastAddressBatch.length - 1){
                  // In case we found any stretch of unused address in the
                  // past batch, use it.
                  unusedExternalAddresses = pastAddressBatch.slice(index + 1).concat(addressBatch);
                }else{
                  // If already the last address of the last batch was used,
                  // it means the gap starts with the current address batch.
                  unusedExternalAddresses = addressBatch;
                }
                break;
              }
            }
          }else{
            // If there is no past transaction response it means this is the first
            // batch and that this wallet is empty.
            unusedExternalAddresses = addressBatch;
          }
        }
        // Using the array of unuser external address to construct an array of address bundles.
        // An address bundle contains both the address and the derivation path used to get to
        // that address. This information is going to be passed to the off-line component for it
        // to verify the address.
        unusedExternalAddresses.forEach( addr => {
          freshAddressBundles.push({
            address: addr,
            path: this.addressToPath.get(addr)
          })
        });
        // Updating set of fresh addresses in case we get a batch of unused external addresses
        if(freshAddressBundles.length > 0){
          localStorage.setItem(KEY_FRESH_EXTERNAL_ADDRESS, JSON.stringify(freshAddressBundles));
        }
        // Updating loading progress
        this.setState((prevState, nextProps) => {
          prevState.progress = prevState.progress + BATCH_SIZE;
          return prevState;
        });
        batchCounter++;
        pastAddressBatch = addressBatch;
        addressBatch = [];
        pastTxResp = txsResp;
      }catch(error){
        console.error('Error while trying to fetch tx from external address batch. Error: ', error);
        // In case of error we want to retry, so we reset the internal index
        externalIndex = externalIndex - BATCH_SIZE;
        // And incremente the safeCounter. This will prevent an infinite loop.
        safeCounter++;
      }

      if(!this.state.pubkeys){
        // In case the user has already logged out, we just cancel this operation
        console.log('Cancelling btc update');
        break;
      }
    }
    if(safeCounter === RETRY_MAX){
      console.warn('Network retry limit has been reached.');
      // Recording error
      gotError = true;
      this.setState({networkError: true})
    }
    const promise = new Promise((resolve, reject) => {
      const worker = walletWorker;
      worker.addEventListener('message', event => {
        resolve(event);
      });
      worker.postMessage({type: 'end-tx-load', addresses: this.knownAddresses.internal.concat(this.knownAddresses.external)});
    });
    const t1 = performance.now();
    const event = await promise;
    // Only storing data in case we're still logged in and got no errors. The user could have
    // logged out or wiped out the application at this point.
    if(this.state.pubkeys && !gotError){
      let { utxos } = event.data;
      const {transactions, balance} = event.data;
      // Removing all UTXO data first
      await this.db.utxos.clear();
      // Storing utxos in the dexies utxos table
      await this.db.utxos.bulkPut(utxos.map(utxo => {
        // we add the path attribute to each UTXO
        //TODO: Select which UTXOs attributes we could discard
        return {
          path: this.addressToPath.get(utxo.address),
          ...utxo
        }
      }));
      // Storing the known internal addresses in the dexie addresses table
      await this.db.addresses.bulkPut(this.knownAddresses.internal.map(addr => {
        return {
          address: addr,
          path: this.addressToPath.get(addr)
        }
      }));
      // Storing the known external addresses in the dexie addresses table
      await this.db.addresses.bulkPut(this.knownAddresses.external.map(addr => {
        return {
          address: addr,
          path: this.addressToPath.get(addr)
        }
      }));

      let btc = null;
      try{
        // Trying to obtain an existing instance of btc in the 'coins' table
        btc = await this.db.coins.where('symbol').equals('btc').first();
      }catch(error){
        console.error('Error while trying to obtain the current btc coin instance from db. Error: ', error);
        gotError = true;
      }
      if(!btc){
        console.log('No BTC entry was found, so we are creating a new one');
        // If no instance was found, then maybe this is the first time we're doing this
        // and a new entry must be created
        btc = {
          name: 'Bitcoin',
          precision: 8,
          symbol: 'btc',
          priority: 1,
          isToken: false
        }
      }
      if(btc.balance !== balance){
        // If the new balance is different from the existing one
        btc.balance = balance;
        try{
          await this.db.coins.put(btc);
        }catch(error){
          console.error('Caught error while trying to insert BTC entry in coins table. Msg: ', error);
          gotError = true;
        }
      }
      this.updatePrices({btc: btc});

      // Storing txs in the dexie txs table
      await this.db.txs.bulkPut(transactions);
      // Adding the 'path' attribute to each one of the UTXOs
      utxos = utxos.map(utxo => {
        return { path: this.addressToPath.get(utxo.address), ...utxo};
      });
      // Updating state with all the utxos and the btc balance
      this.setState((prevState, currentProps) => {
        prevState.utxos = utxos;
        prevState.changeAddress = freshChangeAddress;
        prevState.transactions = transactions;
        prevState.freshAddresses = freshAddressBundles;
        prevState.loadingState.balancesLoaded = LOADED;
        return prevState;
      });
      console.log(`Total BTC balance: ${balance}`);
      // We now proceed to process the updated  BTC tx database.
      this.processBtcTransactions(transactions);
    }else if(gotError){
      const { balancesLoaded, cacheLoaded } = this.state.loadingState;
      const isInitialLoad = balancesLoaded === LOADING && cacheLoaded !== LOADED;
      console.warn('Got error, balancesLoaded: ', this.state.loadingState.balancesLoaded);
      // In case we got an error, we must check wether this is the initial load
      // or one of the subsequent periodic updates.
      if(isInitialLoad){
        // In case the error happened during the initial load, we have to warn the user.
        console.error('Irrecoverable error happened due to unstable network connection');
        alert('Error. Please check your Internet connection and try again.');
        this.handleLogout();
      }else{
        // In case the error happened during a periodic update, this can safely be ignored.
        console.warn('Periodic update failed.');
      }
    }
    const t2 = performance.now();
    console.log(`Downloading time...... ${t1 - t0} ms`);
    console.log(`Post-processing time.. ${t2 - t1} ms`);
    ReactGA.timing({
      category: 'Extra data',
      variable: 'TXs network load',
      value: (t1 - t0), // in milliseconds
      label: 'updateBtcBalance'
    });
    this.isLoadingBtc = false;
  }

  /**
  * Function that processes raw ETH transaction data and creates an array of tx
  * data that contains the following attributes:
  *
  *   - isIncoming        : Whether or not the transaction is inbound
  *   - hash              : The transaction hash
  *   - height            : The block height at which this tx was included
  *   - confirmations     : The number of confirmations (could be replaced by just having the current chain tip)
  *   - value             : The transferred value
  *   - time              : The confirmation time (POSIX time)
  *   - name              : The name of the currency being transferred
  *
  * Aditionally, the ETH processed transaction can optionally have 3 extra fields:
  *
  *   - internalFeeHash   : Hash of the internal fee transaction
  *   - internalFeeAmount : Amount sent to the system owner as internal fee
  *   - minerFeeAmount    : Amount paid to miner
  */
  processEthTransactions = async (userAddress, transactions) => {
    console.log('processEthTransactions');
    const processedTxs = [];
    // Variable used to hold the last SelfTrust fee tx
    let lastInternalFeeTx = null;
    let internalFees = POSIBLE_INTERNAL_FEE_ADDRESSES.map(t => t.toLowerCase());
    transactions.forEach((tx, index, array) => {
      if(!internalFees.includes(tx.to)){
        // If the destination 'to' address is not the SelfTrust fee address set, then
        // this is a user tx. It might be incoming or outgoing.
        let symbol = '';
        let erc20Value = 0;
        let ethValue = 0;
        if(tx.input === '0x'){
          // If we have no input, this is regular ETH transfer
          symbol = 'eth';
          const gasCost = new BigNumber(tx.gasUsed).multipliedBy(tx.gasPrice);
          if(tx.from === tx.to){
            // If this is a self sent tx, we didn't really change the balance
            ethValue = gasCost.toNumber();
          }else if(tx.from === userAddress){
            // If it is an outgoing tx, we must add gas costs to the value
            ethValue = gasCost.plus(tx.value).toNumber();
          }else{
            // If it is an incoming tx, the balance increased by the tx value
            ethValue = new BigNumber(tx.value).toString();
          }
        }else{
          // If the tx input is other than '0x', it means that this is an ERC-20 token
          // transfer, in which case the amount will be encoded in the input data.
          const transferData = Erc20Wallet.decodeTransfer(tx.input.replace(/0x/,''));
          if(transferData && tx.from !== transferData.destination){
            // We only consider the amount transferred if the user sent it to someone else
            erc20Value = transferData.amount.toString();
          }
          // If we have some input, it means this is a contract transaction
          symbol = this.erc20Wallet.getTokenSymbol(tx.to);
        }
        let processedTx = {
          isIncoming: tx.from !== userAddress,
          hash: tx.hash,
          height: parseInt(tx.blockNumber),
          time: parseInt(tx.timeStamp),
          confirmations: parseInt(tx.confirmations),
          value: tx.value !== '0' ? ethValue : erc20Value,
          name: symbol
        };
        if(lastInternalFeeTx){
          // If there was a SelfTrust fee associated with this transfer, we
          // proceed to update the 'value' field and specify the 'internalFeeHash',
          // 'internalFeeAmount' and 'minerFeeAmount' fields of the processed transaction.
          processedTx.internalFeeHash = lastInternalFeeTx.hash;
          processedTx.internalFeeAmount = lastInternalFeeTx.value;
          processedTx.minerFeeAmount = parseInt(tx.gasPrice) * parseInt(tx.gas);
          processedTx.value = ethValue + parseInt(lastInternalFeeTx.value) + processedTx.minerFeeAmount;
          lastInternalFeeTx = null;
        }
        processedTxs.push(processedTx);
      }else{
        // If the destination 'to' address is the SelfTrust fee address, then
        // this is a SelfTrust fee tx. These are always accounted as negative.
        // But their value is added to the next user tx, to which they correspond,
        // so here we just keep a reference to them.
        lastInternalFeeTx = tx;
      }
    });
    await this.db.processedTxs.bulkPut(processedTxs);
  }

  /**
  * Function that processes raw transaction data and creates an array of
  * processed tx that contains the following attributes:
  *
  *   - isIncoming    : Whether or not the transaction is inbound
  *   - hash          : The transaction hash
  *   - height        : The block height at which this tx was included
  *   - confirmations : The number of confirmations (could be replaced by just having the current chain tip)
  *   - value         : The transferred value
  *   - time          : The confirmation time (POSIX time)
  *   - name          : The name of the currency being transferred
  */
  processBtcTransactions = async (transactions) => {
    if(!this.state.pubkeys) return;
    const processedTxs = [];
    const intCollection = await this.db.addresses.where('path')
                            .startsWith('M/49H/0H/0H/1')
                            .toArray();
    const extCollection = await this.db.addresses.where('path')
                            .startsWith('M/49H/0H/0H/0')
                            .toArray();
    const internalAddrs = intCollection.map(item => item.address);
    const externalAddrs = extCollection.map(item => item.address);
    transactions = transactions.sort((a, b) => a.time - b.time);
    transactions.forEach((tx) => {
      if(tx.time === 0) {
        // Overriding the 'time' value, which for unconfirmed transactions
        // seems to have changed to 0 now
        tx.time = parseInt(new Date().getTime() / 1000);
      }
      const inputs = tx.inputs;
      const outputs = tx.outputs;
      let incomingValue = 0;
      let outgoingValue = 0
      inputs.forEach((input) => {
        // console.log(`checking input ${input.coin.address}`);
        if(internalAddrs.includes(input.coin.address)){
          // console.log('input is from an internal address');
          outgoingValue += input.coin.value;
        }
        if(externalAddrs.includes(input.coin.address)){
          // console.log('input is from an external address');
          outgoingValue += input.coin.value;
        }
      });
      outputs.forEach((output) => {
        // console.log(`checking output ${output.address}`);
        if(internalAddrs.includes(output.address)){
          // console.log('output is from an internal address');
          incomingValue += output.value;
        }
        if(externalAddrs.includes(output.address)){
          // console.log('output is from an external address');
          incomingValue = output.value;
        }
      });
      if(incomingValue > 0 && outgoingValue === 0){
        processedTxs.push({
          isIncoming: true,
          hash: tx.hash,
          height: parseInt(tx.height),
          confirmations: parseInt(tx.confirmations),
          value: incomingValue,
          time: tx.time,
          name: 'btc'
        });
      }else{
        processedTxs.push({
          isIncoming: false,
          hash: tx.hash,
          height: parseInt(tx.height),
          confirmations: parseInt(tx.confirmations),
          value: (outgoingValue - incomingValue),
          time: tx.time,
          name: 'btc'
        })
      }
    });
    processedTxs.sort((a, b) => b.time - a.time);

    // Storing processed btc transactions in the local db
    await this.db.processedTxs.bulkPut(processedTxs);
  }

  getKey = (xpub, path) => {
    let node = bip32.fromBase58(xpub);
    return node.derivePath(path);
  }

  handleLogin = (props) => {
    // checking the most up to date versions of the mobile & web apps
    this.checkVersions(remoteVersions => {
      const pubkeys = Object.keys(props).map(attr => {
        return {name: attr, key: props[attr]}
      }).filter( item => item.name !== 'version' && item.name !== 'id');
      const prevId = localStorage.getItem(KEY_WALLET_ID);
      const currentId = props.id
      const { test } = props;
      console.log(`handleLogin. prev id: ${prevId}, current id: ${currentId}`);
      // Specifying local versions, this is the current version of the android app and of
      // this web app
      const localVersions = {
        mobile: props.version,
        web: version
      }
      // Storing the public keys in the local storage
      localStorage.setItem(KEY_XPUB, JSON.stringify(pubkeys))
      // Storing the hash of the public keys in the local storage
      localStorage.setItem(KEY_WALLET_ID, currentId);

      if(prevId !== currentId && prevId !== '' && prevId){
        console.warn('Previous id is different, performing a wipeout!');
        this.performWipeout(pubkeys, () => {
          this.setState({
            id: currentId,
            pubkeys: pubkeys,
            versions: {
              local: localVersions,
              remote: remoteVersions
            },
            test: test
          });
        });
      }else{
        // Setting the public keys in the state
        this.setState({
          id: currentId,
          pubkeys: pubkeys,
          versions: {
            local: localVersions,
            remote: remoteVersions
          },
          test: test
        });
      }
    });
  }

  handleLogout = () => {
    // Cancelling the periodic refresh
    window.clearInterval(this.intervalId);
    this.intervalId = undefined;
    // Removing the public keys from the local storage (disk)
    localStorage.setItem(KEY_XPUB, '');
    // Resetting the Alert state
    localStorage.removeItem(KEY_HAS_ALERT_BEEN_DISMISSED);
    // Removing the public keys from the state & restoring loading state
    const stateClone = Object.assign({}, this.state);
    stateClone.id = null;
    stateClone.pubkeys = null;
    stateClone.loadingState = {
      balancesLoaded: NOT_LOADED,
      cacheLoaded: NOT_LOADED
    }
    stateClone.networkError = null;
    this.setState(stateClone);
  }

  performWipeout = async (newPubKeys, onReady) => {
    let t_0 = performance.now();
    console.log('Perform wipeout. pubkeys: ', newPubKeys);
    this.isWipingOut = true;
    // Removing the public keys from local storage
    localStorage.setItem(KEY_XPUB, '');
    // Removing the wallet id from local storage
    localStorage.setItem(KEY_WALLET_ID, '');
    if(newPubKeys){
      // If new keys were provided, we store them. This is required for the case
      // in which the wipeout is triggered by the user just logging in with a different
      // public key. For this situation we want to wipeout all historical data
      // that corresponds to the public keys now being overriden. But we want to
      // keep the new public keys in the local storage in order to avoid having
      // the user being redirected to the Welcome component.
      localStorage.setItem(KEY_XPUB, JSON.stringify(newPubKeys));
    }
    // Removing data stored at the session storage
    sessionStorage.clear();
    const t1 = performance.now();
    // Clearing all dexie's tables
    const p1 = this.db.addresses.clear();
    const p2 = this.db.utxos.clear();
    const p3 = this.db.txs.clear();
    const p4 = this.db.processedTxs.clear();
    const p5 = this.db.coingecko.clear();
    const p6 = this.db.coins.clear();
    await Promise.all([p1, p2, p3, p4, p5, p6]);
    const t2 = performance.now();

    // Resetting coingecko's coin list last update time
    localStorage.setItem(KEY_COINGECKO_LAST_UPDATE, '');

    console.log(`Dexie wipeout took ${t2-t1} ms`);
    // Executing callback, if any
    if(onReady) onReady();
    // Resetting state
    this.setState({
      pubkeys: newPubKeys,
      coins: {
        btc: {
          name: 'bitcoin',
          price: null,
          balance: 0,
          precision: 8,
          symbol: 'btc',
          priority: 1
        },
        eth: {
          name: 'ethereum',
          price: null,
          balance: new BigNumber(0),
          precision: 18,
          symbol: 'eth',
          priority: 2
        }
      },
      utxos: [],
      changeAddress: null,
      freshAddresses: [],
      transactions: [],
      locale: 'en',
      loadingState: {
        cacheLoaded: NOT_LOADED,
        balancesLoaded: NOT_LOADED
      },
      networkError: null,
      progress: 0
    });
    // Cancelling the periodic refresh
    window.clearInterval(this.intervalId);
    this.intervalId = undefined;
    let t_1 = performance.now();
    console.log(`Full wipeout time: ${t_1 - t_0}`);
  }

  // Callback fired by the ScanStep that will update the current balance held in the state
  handleBtcTxBroadcast = async (txBundle) => {
    console.log('handleBtcTxBroadcast. txBundle: ', txBundle);
    const queryCount = await this.db.addresses.where('address').equals(txBundle.destination).count();
    console.log(`destination: ${txBundle.destination}, queryCount: ${queryCount}`);
    const isToMyself = queryCount !== 0;
    const minerFee = this.getMinerFee(txBundle);
    let balanceDeduction = txBundle.outputs.internalFee + minerFee;
    console.log(`is to self: ${isToMyself}, int fee: ${txBundle.outputs.internalFee}, miner fee: ${minerFee}`);
    if(!isToMyself){
      // Only adding the amount sent to the balance deduction in case we are sure
      // this transaction is not being sent to the same wallet.
      balanceDeduction += txBundle.outputs.destination;
    }

    // Updating the BTC balance in the database
    console.log('balance deduction: ', balanceDeduction);
    const balanceSatDeduction = parseInt(balanceDeduction * 1e8);
    const btc = await this.db.coins.where('symbol').equals('btc').first();
    btc.balance = btc.balance - balanceSatDeduction;
    await this.db.coins.put(btc);
    // Deserializing tx data in order to obtain its id
    const tx = bitcoin.Transaction.fromHex(txBundle.signedTx);
    // Creating an entry to the processedTxs table of the indexed db
    const processedTx = {
      isIncoming: false, // Tx is never considered incoming if inserted via this callback
      hash: tx.getId(),  // Even for self-sent txs.
      height: -1,
      confirmations: 0,
      value: balanceSatDeduction,
      time: parseInt(Date.now() / 1000),
      name: 'btc'
    };
    // // Updating the processed txs table
    await this.db.processedTxs.put(processedTx);
  }

  /**
  * Function that will extract the **actual** miner fee paid by a specific transaction.
  * This is required at this point because in some situations the miner fee might receive
  * a small bump because we don't want to create a dust output.
  *
  * For more insight into this see BitcoinTxBuilder#getOutputs().
  */
  getMinerFee = (txBundle) => {
     const tx = bitcoin.Transaction.fromHex(txBundle.tx);
     const inputValue = txBundle.input.reduce((accum, current) => accum + current);
     const outputValue = tx.outs.reduce((accum, current) => accum + current.value, 0);
     const minerSatFee = inputValue - outputValue;
     return minerSatFee / 1e8;
  }

  onChangeLanguage() {
    let lang;
    if(this.state.locale === 'en'){
      lang = 'zh';
      i18nConfig.messages = intlMessagesZH;
    }else{
      lang = 'en';
      i18nConfig.messages = intlMessagesEN;
    }
    this.setState({ locale: lang });
    i18nConfig.locale = lang;
  }

  /**
  * Callback function executed once an ethereum tx has been sent or cancelled.
  * We need to update the current nonce in order to account for either case.
  */
  handleEthTxBroadcast = async (broadcastedTxs) => {
    console.log('handleEthTxBroadcast. broadcasted txs: ', broadcastedTxs);
    const userTx = broadcastedTxs.length === 2 ? broadcastedTxs[1] : broadcastedTxs[0];
    const feeTx = broadcastedTxs.length === 2 ? broadcastedTxs[0] : null;
    const isToken = userTx.data !== '0x';
    const tokenSymbol = this.erc20Wallet.getTokenSymbol(userTx.to);
    let transferredValue = parseInt(userTx.value);
    const isSelfSent = userTx.to === this.state.ethAccount;
    if(isSelfSent || isToken){
      // In case of token tx or self sent txs the transferred value is zero
      transferredValue = 0;
    }
    const processedTx = {
      isIncoming: false,
      hash: userTx.hash,
      height: -1,
      confirmations: 0,
      value: transferredValue,
      time: parseInt(Date.now() / 1000),
      name: isToken ? tokenSymbol : 'eth'
    }
    if(feeTx){
      processedTx.internalFeeHash = feeTx.hash;
      processedTx.internalFeeAmount = feeTx.value.toNumber();
      processedTx.minerFeeAmount = feeTx.gasPrice.mul(feeTx.gasLimit).toNumber();
      processedTx.value = parseInt(transferredValue) + parseInt(feeTx.value) + 2 * processedTx.minerFeeAmount;
    }else{
      processedTx.internalFeeAmount = 0;
      processedTx.minerFeeAmount = userTx.gasPrice.mul(userTx.gasLimit).toNumber();
      processedTx.value = userTx.gasPrice.mul(userTx.gasLimit).add(transferredValue);
    }
    // Updating the processed txs table
    this.db.processedTxs.put(processedTx).then(result => {
      console.log('Inserted processed tx. hash: ', processedTx.hash);
    }).catch(error => console.error('Caught error while trying to insert a new processed tx. Error: ', error));

    // Here we will proceed to update the ETH and optionally the token's balance.
    if(!isToken){
      // If what was sent was not a token, we must update just the ETH balance.
      const miningCost = broadcastedTxs.map(tx => {
        // Here we convert the big number from an instance provided by one library to another
        // one. This is because the 'ethereum-tx-decoder' library makes use of a different big number
        // library than the one used in the rest of the app, which is bignumber.js
        const gasLimit = new BigNumber(tx.gasLimit.toString());
        const gasPrice = new BigNumber(tx.gasPrice.toString());
        return gasPrice.multipliedBy(gasLimit);
      }).reduce((accum, current) => accum.plus(current), new BigNumber(0));
      const currentEthBalance = new BigNumber(this.state.coins.eth.balance);
      const balanceDecrease = new BigNumber(processedTx.value).plus(miningCost);
      this.onBalanceUpdate('eth', currentEthBalance.minus(balanceDecrease));
    }else{
      // If we sent an ERC-20 token, we must update both the token's balance and ETH
      // balance, since it costs a little bit of ETH to pay for miner fees.
      const gasLimit = new BigNumber(userTx.gasLimit.toString());
      const gasPrice = new BigNumber(userTx.gasPrice.toString());
      const ethGasCost = gasLimit.multipliedBy(gasPrice);
      const tokenBalance = this.state.coins[tokenSymbol].balance;
      const transferData = Erc20Wallet.decodeTransfer(userTx.data);
      // Updating ETH and token balances
      const currentEthBalance = new BigNumber(this.state.coins.eth.balance);
      this.onBalanceUpdate('eth', currentEthBalance.minus(ethGasCost));
      // Optionally updating the ERC-20 token balance
      if(transferData.destination !== this.state.ethAccount){
        // We only want to decrement the ERC-20 token balance if the user is
        // sending them to another party, not to himself.
        this.onBalanceUpdate(tokenSymbol, tokenBalance.minus(transferData.amount));
      }
    }

    // Updating the nonce
    const updatedNonce = broadcastedTxs[broadcastedTxs.length - 1].nonce + 1;
    this.setState((prevState, currentProps) => {
      prevState.ethNonce = updatedNonce;
      return prevState;
    });
  }

  /**
  * Callback function that will be called once it is necessary to update
  * the balance of a coin stored in the database.
  * @param symbol   The coin symbol, as stored in the database.
  * @param balance  The updated balance value, as a BigNumber instance.
  * TODO: Make BTC use this function too.
  */
  onBalanceUpdate = async (symbol, balance) => {
    const coin = await this.db.coins.where('symbol').equals(symbol).first();
    coin.balance = balance.toString();
    await this.db.coins.put(coin);
  }

  render(){
    const {pubkeys} = this.state;
    if(!pubkeys){
      // If we don't have pubic keys, we redirect the user to the Login component
      return (
        <IntlProvider key={ i18nConfig.locale } locale={ i18nConfig.locale }  messages={ i18nConfig.messages }>
          <Switch>
            <Route path="/welcome" exact
              render={ props => (
                <Welcome handleLogin={this.handleLogin}/>
              )}
            />
            <Route
              path="/releases" exact
              render={() => (<Releases/>)}
            />
            <Route path="/main/faq" render={(props) =>
              <Faq
                {...props}
                logged={pubkeys && pubkeys.length > 0}
                selectedLanguage={i18nConfig.locale}
                onChangeLanguage={() => this.onChangeLanguage()}/>
            }/>
            <Redirect to='/welcome'/>
          </Switch>
        </IntlProvider>
      )
    }else{
      const { loadingState } = this.state;
      const isLoading = loadingState.balancesLoaded === LOADING && loadingState.cacheLoaded !== LOADED;
      return (
        <IntlProvider key={ i18nConfig.locale } locale={ i18nConfig.locale }  messages={ i18nConfig.messages }>
          <Switch>
            <Route path="/welcome" exact
              render={ () => (<Redirect to="/main"/> )}/>
            <Route path="/main" exact
              render={ props => (
                <Main {...props}
                  networkError={this.state.networkError}
                  coins={this.state.coins}
                  pubkeys={pubkeys}
                  isLoading={isLoading}
                  progress={this.state.progress}
                  db={this.db}
                  versions={this.state.versions}
                  txcount={this.state.transactions.length}
                  handleLogout={this.handleLogout}/>
              )}/>
            <Route
              path="/main/details/:coin"
              render={(props) => (
                <CoinDetails {...props}
                  addressToPath={this.addressToPath}
                  ethAccount={this.state.ethAccount}
                  ethNonce={this.state.ethNonce}
                  handleEthTxBroadcast={this.handleEthTxBroadcast}
                  utxos={this.state.utxos}
                  isLoading={isLoading}
                  coins={this.state.coins}
                  pubkeys={pubkeys}
                  db={this.db}
                  handleBtcTxBroadcast={this.handleBtcTxBroadcast}
                  changeAddress={this.state.changeAddress}
                  freshAddresses={this.state.freshAddresses}/>
              )
            }/>
            <Route path="/main/faq" render={(props) =>
              <Faq
                {...props}
                logged={pubkeys && pubkeys.length > 0}
                selectedLanguage={i18nConfig.locale}
                onChangeLanguage={() => this.onChangeLanguage()}/>
            }/>
            <Route
              path="/main/settings"
              render={(props) => (
                <Settings {...props}
                  performWipeout={this.performWipeout}
                  currentTimeout={this.props.timeoutValue}
                  onTimeoutModified={this.props.onTimeoutModified}/>
              )}/>
            <Route
              path="/main/transmission"
              render={props => (
                <Transmission
                  test={this.state.test === true}
                  coins={this.state.coins}
                  walletId={this.state.id}
                  utxos={this.state.utxos}
                  changeAddress={this.state.changeAddress}/>
              )}/>
            <Redirect from="/" to="/welcome"/>
            {/* Default route */}
            <Route render={() => <h1>Not found</h1>}/>
          </Switch>
        </IntlProvider>
      )
    }
  }
}
export default Wallet;
