import React from 'react';
import BigNumber from 'bignumber.js';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Header from '../../../components/Header/Header';
import Coins from './Coins/Coins';
import TxHistory from './TxHistory/TxHistory';
import LoadingModal from '../../../components/LoadingModal/LoadingModal';
import ConfirmLogoutModal from './ConfirmLogoutModal/ConfirmLogoutModal';
import TxDetailsDialog from '../../../components/TxDetailsDialog/TxDetailsDialog';
import { TX_HISTORY_PAGE_SIZE } from '../../../constants';
import { getBlockExplorerUrl } from '../../../util';
import './Main.css';

class Main extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      tabsValue: 0,
      processedTxs: [],
      showLogoutConfirmation: false,
      showTxDetails: false,
      clickedTx: null
    };
  }

  handleTabsChange = (event, tabsValue) => {
    this.setState({ tabsValue });
  };

  handleLogout = () => {
    this.setState({showLogoutConfirmation: true})
  }

  handleLogoutAction = logout => {
    if(logout){
      if(this.props.handleLogout){
        this.props.handleLogout();
        this.props.history.replace('/login');
      }
    }
    this.setState({showLogoutConfirmation: false})
  }

  componentDidMount(){
    // Loading all types of transactions, ordered by time and limited to
    // TX_HISTORY_PAGE_SIZE entries
    const txs = this.props.db.processedTxs
      .orderBy('time')
      .reverse()
      .limit(TX_HISTORY_PAGE_SIZE)
      .toArray().then( txs => {
        this.setState({processedTxs: txs});
        // Creating subscriptions
        this.props.db.processedTxs.hook('creating', this.creationHandler);
        this.props.db.processedTxs.hook('updating', this.updateHandler);
      });
  }

  /**
  * Function that will be called whenever a new object is inserted
  * into the 'processedTxs' table
  */
  creationHandler = (primKey, obj, transaction) => {
    const txs = Array.from(this.state.processedTxs);
    // Finding the correct place for this new element
    let index = txs.findIndex(tx => tx.time < obj.time);
    // Adjusting the index in case the element should go at the end
    if(index === -1) index = txs.length;
    // Inserting the tx at the right place
    txs.splice(index, 0, obj);
    // Applying a sort, just in case
    if(txs.length > 1 && txs[0].time < txs[1].time){
      txs.sort((a, b) => b.time - a.time);
    }
    // Removing any excess elements
    while(txs.length > TX_HISTORY_PAGE_SIZE) {
      txs.pop();
    }
    this.setState({processedTxs: txs});
  }

  /**
  * Function called whenever an entry in the 'processedTxs' table changes.
  */
  updateHandler = (modifications, primKey, obj, transaction) => {
    if(modifications === {}) return;
    const txsToUpdate = [...this.state.processedTxs];
    const index = txsToUpdate.findIndex(tx => tx.hash === primKey);
    if(index !== -1){
      const original = txsToUpdate[index];
      const updated = {...original,...modifications};
      txsToUpdate[index] = updated;
      this.setState({processedTxs: txsToUpdate});
    }
  }

  componentWillUnmount(){
    // Removing subscriptions
    this.props.db.processedTxs.hook('creating').unsubscribe(this.creationHandler);
    this.props.db.processedTxs.hook('updating').unsubscribe(this.updateHandler);
  }

  /**
  * Callback executed every time the user clicks on a specific item of the
  * transaction list.
  */
  onTxClicked = (index) => {
    // Fetching the selected transaction
    const tx = this.state.processedTxs[index];
    // Delay introduced in order to allow the ripple animation to play out before
    // we open a new browser tab with the tx details in the block explorer
    const LINK_DELAY = 800;
    if(tx.name !== 'btc'){
      // If we have an ethereum transaction with a SelfTrust fee, we must first
      // show a disambiguation dialog (TxDetailsDialog) because the SelfTrust fee
      // is implemented as a separate transaction on the blockchain.
      if(tx.internalFeeAmount){
        this.setState({
          clickedTx: this.state.processedTxs[index],
          showTxDetails: true
        });
      }else{
        setTimeout(() => window.open(getBlockExplorerUrl('ETH') + 'tx/' + tx.hash, '_blank'), LINK_DELAY);
      }
    }else{
      setTimeout(() => window.open(getBlockExplorerUrl('BTC') + 'tx/' + tx.hash, '_blank'), LINK_DELAY);
    }
  }

  /**
  * Called every time the dialog with the transaction details is to be closed.
  */
  onTxDetailsClose = (e) => {
    this.setState({showTxDetails: false});
  }

  render() {
    const { pubkeys, coins, progress, versions } = this.props;
    if(pubkeys){
      // Calculating total balance in FIAT and BTC
      const totalFiatBalance = Object.keys(coins)
                                    .map((symbol) => {
                                      const coin = coins[symbol];
                                      const balance = new BigNumber(coin.balance);
                                      const price = coin.price ? coin.price : 0;
                                      const precision = coin.precision;
                                      return balance.multipliedBy(price).dividedBy(Math.pow(10, precision));
                                    })
                                    .reduce((accumulator, current) => accumulator.plus(current), new BigNumber(0));
      let totalBtcBalance = 0;
      if(coins.btc && coins.btc.price)
        totalBtcBalance = totalFiatBalance / coins.btc.price;

      const { tabsValue } = this.state;
      let content = null;
      if(tabsValue === 0){
        content = (
          <Grid container spacing={2}>
            <Coins {...this.props}
              coins={coins}
              pubkeys={pubkeys}/>
          </Grid>
        )
      }else if(tabsValue === 1){
        content = (
          <TxHistory
            onTxClicked={this.onTxClicked}
            transactions={this.state.processedTxs}/>
        )
      }
      return (
        <React.Fragment>
          <Paper elevation={4}>
            <Header
              networkError={this.props.networkError}
              handleLogout={this.handleLogout}
              totalBtcBalance={totalBtcBalance}
              totalFiatBalance={totalFiatBalance}
              coins={coins}
              logged={true}
              versions={versions}
              primary='usd'/>
            <Tabs className="tab-action" variant="fullWidth" value={tabsValue} onChange={this.handleTabsChange}>
                <Tab label="COINS" />
                <Tab label="HISTORY" />
            </Tabs>
          </Paper>
          <section className="home_banner_area">
            <div className="container box_1620">
              {content}
            </div>
          </section>
          <LoadingModal isLoading={this.props.isLoading} progress={progress}/>
          <ConfirmLogoutModal
            isOpen={this.state.showLogoutConfirmation}
            handleLogoutAction={this.handleLogoutAction}/>
          <TxDetailsDialog
            isOpen={this.state.showTxDetails}
            onClose={this.onTxDetailsClose}
            tx={this.state.clickedTx}/>
        </React.Fragment>
      )
    }else{
      this.props.history.replace('/login');
      return null;
    }
  }
}

export default Main;
