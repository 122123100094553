const axios = require('axios');

// TX verifier domain
const PRODUCTION_DOMAIN = `https://${document.location.hostname}`; // Production
const DEV_DOMAIN = `http://${document.location.hostname}:3030/`;   // Development

const instance = axios.create({
  baseURL: process.env.NODE_ENV === 'development' ? DEV_DOMAIN : PRODUCTION_DOMAIN,
  timeout: 60000
});

export default instance;
