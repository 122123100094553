import React from 'react';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { withStyles } from '@material-ui/core/styles';
import { isMobile } from 'react-device-detect';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0px 0px 50px 0px'
  },
  title: {
    color: theme.palette.primary.main
  },
  content: {
    whiteSpace: 'pre-line',
    textAlign: 'justify',
    color: theme.palette.text.secondary
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    margin: '10px'
  },
  cell: {
    margin: '5px'
  },
  image: {
    width: '100%',
    maxWidth: '100%'
  },
  ul: {
    paddingInlineStart: '1.1em',
    marginBottom: '0'
  },
  li: {
    textAlign: 'justify',
    lineHeight: '1.5',
    color: theme.palette.text.secondary
  },
  [theme.breakpoints.up('sm')]:{
    root: {
      maxWidth: '80%',
      flexDirection: 'row'
    },
    imageContainer: {
      width: '50%'
    },
    textContainer: {
      width: '50%'
    },
    image: {
      maxHeight: '300px',
      width: 'auto',
      height: 'auto'
    }
  }
})

/**
* Function used to combine the content with the link data. If no link is
* provided the funtcion does nothing.
* TODO: Extend to support multiple links in the same paragraph if needed
*/
const linkify = (content, link) => {
  if(link && content.indexOf(link.text) !== -1){
    const parts = content.split(link.text);
    return [
      parts[0],
      <Link key={link.url} href={link.url} target='_blank' rel='noreferrer'>{link.text}</Link>,
      parts[1]
    ];
  }else{
    return content;
  }
}

/**
* Function used to unpack the content into a series of React components.
*/
const unpackContent = (item, link, classes) => {
  if(typeof item === 'string') {
    if(link){
      return <Typography className={classes.content}>{linkify(item, link)}</Typography>
    }
    // In its simplest form, the content is just a string.
    return <Typography className={classes.content}>{item}</Typography>
  }else if(typeof item === 'object') {
    // If we find an object, we know it must be an array with sub-components
    let unpacked = null;
    let array = item;
    let unpackedArray = [];
    array.map((element, index) => {
      switch(element.type) {
        case 'paragraph':
          if(element.link)
            unpacked = <Typography key={index} className={classes.content}>{linkify(element.content, element.link)}</Typography>
          else
            unpacked = <Typography key={index} className={classes.content}>{element.content}</Typography>
          break;
        case 'bullets':
          unpacked =
          (
            <ul key={index} className={classes.ul}>
              {
                element.content.map(bullet =>
                  (
                    <li className={classes.li} key={bullet}>
                      <Typography key={index} className={classes.content}>
                        {linkify(bullet, element.link)}
                      </Typography>
                    </li>
                  )
                )
              }
            </ul>
          )
          break;
      }
      unpackedArray.push(unpacked);
    });
    return unpackedArray;
  }
}

const instructionStep = props => {
  const {classes, index} = props;
  const {title, content, images, extra, link} = props.stepDetails;
  let extraStyle = null;
  if(index % 2 === 0 && !isMobile) {
    extraStyle = {flexDirection: 'row-reverse'}
  }
  return (
    <div className={classes.root} style={extraStyle}>
      <div className={classes.textContainer}>
        <Typography className={classes.title}>{title}</Typography>
        {unpackContent(content, link, classes)}
      </div>
      <div className={classes.imageContainer}>
        {images.map(image => (
          <div className={classes.cell} key={image}>
            <img className={classes.image} src={image} alt={`Image for ${title}`}/>
          </div>
        ))}
      </div>
    </div>
  )
}

export default withStyles(styles)(instructionStep);
