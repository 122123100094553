import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';

const confirmLogoutModal = props => {
  const {isOpen, handleLogoutAction} = props;
  return (
    <Dialog open={isOpen}>
      <DialogTitle>Confirm Logout</DialogTitle>
      <DialogContent>
        <DialogContentText>Are you sure you want to close this session?</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleLogoutAction(false)} color="primary">
          No
        </Button>
        <Button onClick={() => handleLogoutAction(true)} color="primary" autoFocus>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default confirmLogoutModal;
