import React from 'react';
import QrReader from "react-qr-reader";
import bitcoin from 'bitcoinjs-lib';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkmark from '../../../../../components/BroadcastResult/Checkmark/Checkmark';
import ErrorCross from '../../../../../components/BroadcastResult/ErrorCross/ErrorCross';
import Web3 from 'web3';
import {fromWei} from 'web3-utils';
import { INFURA_URL } from '../../../../../constants';
import axiosBtcNode from '../../../../../network/axios-btc-node';
import { decodeTx } from 'ethereum-tx-decoder';
import { withStyles } from '@material-ui/core/styles';
import './ScanStep.css';
import 'cryptocoins-icons/webfont/cryptocoins.css';

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5)
  }
});

class ScanStep extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      segments: [], // Will hold QR code segments
      broadcasted: false,
      success: null,
      txHash: null
    }
  }

  handleScan = (data) => {
    if (data != null) {
      // Extract index, count and payload info from the data
      const segmentIndex = parseInt(data.slice(0,2));
      const segmentCount = parseInt(data.slice(2,4));
      if(isNaN(segmentIndex) || isNaN(segmentCount)) return;
      console.log(`segment index: ${segmentIndex}, segment count: ${segmentCount}`);
      const payload = data.slice(4);
      if(this.state.segments.length === 0){
        // This is the first of many QR codes to be read
        const segments = [ ...Array(segmentCount)].map(i => null);
        segments[segmentIndex - 1] = payload;
        this.setState({
          segments: segments
        })
      }else{
        // This is the Nth segment
        this.setState((prevState, currentProps) => {
          prevState.segments[segmentIndex - 1] = payload;
          return prevState;
        });
      }
    }
  }

  handleError = (err) => {
    console.error(err);
  }

  componentDidUpdate(prevProps, prevState, snapshot){
    const { segments, broadcasted } = this.state;
    const doneScanning = segments.length > 0 && !segments.includes(null);
    if(doneScanning && !broadcasted){
      const signedTx = this.state.segments.join('');
      let txHash = null;
      // This is to make sure we only broadcast the tx once
      if(signedTx !== this.props.signedtx){
        const txBundle = this.props.txBundle;
        txBundle.signedTx = signedTx;
        if(txBundle.asset === 'btc'){
          // Handling BTC transaction broadcast
          axiosBtcNode.post('/broadcast', {tx: signedTx}, {headers: {'Content-Type': 'application/json'}})
            .then((response) => {
              const success = response.status === 200;
              this.props.handleBtcTxBroadcast(txBundle);
              this.setState({success: true});
            })
            .catch(error => {
              console.error('Error while sending tx. Error: ', error);
              this.setState({success: false});
            });
        }else{
          // Handling ETH and ERC-20 tokens broadcast the same way
          const web3 = new Web3(new Web3.providers.HttpProvider(INFURA_URL));
          // The signed tx payload for ethereum contains 2 transactions
          const txs = signedTx.split(',');
          // This callback will be fired once the ETH tx has been included in the blockchain
          const onSuccess = result => {console.log('eth.onSuccess: ', result);}
          const errorHandler = error => {
            console.error('Error while sending tx. Error: ', error);
            this.setState({sent: true, success: false});
          }
          const broadcastedTxs = [];
          for(const tx of txs){
            const receipt = web3.eth.sendSignedTransaction('0x' + tx);
            receipt.then(onSuccess).catch(errorHandler);
            txHash = web3.utils.sha3('0x' + tx);
            const decodedTx = decodeTx('0x' + tx);
            decodedTx.hash = txHash;
            broadcastedTxs.push(decodedTx);
            // Storing a reference to the hash in order to update the state with it
          }
          const lastTx = broadcastedTxs[broadcastedTxs.length - 1];
          // Reporting broadcasted txs to parent container
          this.props.handleEthTxBroadcast(broadcastedTxs);
        }
        this.setState({broadcasted: true, txHash: txHash});
        this.props.handleStepResult(this.props.index, true, signedTx);
      }
    }
  }

  render(){
    const { coin, classes } = this.props;
    const txBundle = this.props.txBundle;
    // Obtains the number of segments already scanned
    const scannedAlready = this.state.segments.filter((item) => item !== null).length;
    // Obtains the total number of segments to scan
    const scanTotal = this.state.segments.length;
    // Variable used to hold the main content of this component
    let content = null;
    if(scannedAlready < scanTotal || scanTotal === 0){
      let message = null
      if(scannedAlready === 0)
        message = (<div className="text-alert">Scan the "Approved Payment" provided by the SelfTrust Offline app. </div>);
      else
        message = ( <div className="text-alert">Scan QR code {scannedAlready + 1} from the SelfTrust Offline app. </div>)
      content = (
        <React.Fragment>
          {message}
          <QrReader className="video-scan"
              delay={500}
              resolution={900}
              onError={this.handleError}
              onScan={(data) => this.handleScan(data)}
              />
        </React.Fragment>
      )
    }else{
      if(!this.state.broadcasted){
        content = (
          <React.Fragment>
            <p>Broadcasting...</p>
          </React.Fragment>
        )
      }else{
        if(this.state.success === false){
          content = (
            <React.Fragment>
              <ErrorCross message='There was an error'/>
            </React.Fragment>
          )
        }else{
          if(this.props.txBundle.asset === 'btc'){
            // Handle btc broadcast
            const tx = bitcoin.Transaction.fromHex(this.props.signedtx);
            // UI to display when the transaction has been succesfully sent
            const explorerUrl = `https://blockstream.info/tx/${tx.getId()}`;
            content = (
              <React.Fragment>
                <Checkmark blockchainLink={explorerUrl}/>
              </React.Fragment>
            )
          }else{
            const txid = this.state.txHash;
            let explorerUrl = `https://etherscan.io/tx/${txid}`;
            if(INFURA_URL.indexOf('ropsten') !== -1){
              explorerUrl = `https://ropsten.etherscan.io/tx/${txid}`;
            }
            content = (
              <React.Fragment>
                <Checkmark blockchainLink={explorerUrl}/>
              </React.Fragment>
            )
          }
        }
      }
    }
    return (
      <React.Fragment>
        <div>
          {content}
        </div>
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(ScanStep);
