import React from 'react';
import QRCode from 'qrcode.react';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import MediaQuery from 'react-responsive';
import CopyButton from '../../../../../../components/CopyButton/CopyButton';
import ButtonBase from '@material-ui/core/ButtonBase';
import CopyAddress from '../../CopyAddress/CopyAddress';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { QR_CODE_SIZES } from '../../../../../../constants';
import './VerifiedAddressStep.css';

class VerifiedAddress extends React.Component {
  state = {
    displayQRCode: false,
    copied: false,
    displayCopiedMsg: false
  }

  handleDisplayClick = (e) => {
    e.preventDefault();
    this.setState((prevState, currentProps) => {
      prevState.displayQRCode = !prevState.displayQRCode;
      return prevState;
    });
  }

  copyHandler = (text, result) => {
    console.log('copyHandler');
    this.setState({copied: true, displayCopiedMsg: true});
  }

  onSnackbarClosed = () => {
    setTimeout(() => {
      this.setState({displayCopiedMsg: false});
    }, 1000);
  }

  render(){
    let qrCode = null;
    if(this.state.displayQRCode){
      qrCode = (
        <React.Fragment>
          <MediaQuery query="(min-device-width:1224px)">
            <QRCode className='centered-qr-code' size={QR_CODE_SIZES.DESKTOP} value={this.props.verifiedaddress} />
          </MediaQuery>
          <MediaQuery query="(max-device-width: 1224px)">
            <QRCode className='centered-qr-code' size={QR_CODE_SIZES.MOBILE} value={this.props.verifiedaddress} />
          </MediaQuery>
        </React.Fragment>
      )
    }
    const message = (this.state.displayQRCode ?
      <p><a href='#' onClick={this.handleDisplayClick}>Hide Address QR code</a></p> :
      <p><a href="#" onClick={this.handleDisplayClick}>Show Address QR code</a></p>)
    return (
      <React.Fragment>
        <div className="address-matches-step">
           <div>
               <p>Check that this address matches the one provided by the SelfTrust app, then safely receive funds on it:</p>
               <div className="receive-container address-container">
                 <CopyAddress address={this.props.verifiedaddress} copyHandler={this.copyHandler}/>
               </div>
               {message}
               {qrCode}
               <Snackbar
                 anchorOrigin={{
                   vertical: 'bottom',
                   horizontal: 'center'
                 }}
                 open={this.state.displayCopiedMsg}
                 autoHideDuration={1500}
                 onClose={this.onSnackbarClosed}
                 message={<span id='message-id'>Address copied</span>}
                 action={[
                   <IconButton
                     key='close'
                     aria-label='Close'
                     color='inherit'
                     onClick={this.onSnackbarClosed}>
                     <i style={{color: 'white' }} className="fas fa-times"></i>
                   </IconButton>]
                 }
               />
           </div>
        </div>
      </React.Fragment>
    )
  }
}

export default VerifiedAddress;
