import React from 'react';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Icon from '@material-ui/core/Icon';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import WipeoutModal from './WipeoutModal/WipeoutModal';
import ProgressDialog from '../../../components/ProgressDialog/ProgressDialog';
import { version } from '../../../../package.json';
import { withStyles } from '@material-ui/core/styles';
import { APK_URL } from '../../../constants';
import './Settings.css'

const MODAL_NONE = 'modal_none';
const MODAL_WIPEOUT = 'modal_wipeout';

const OPTION_TRANSMISSION = 'transmission';
const OPTION_DOWNLOAD = 'download';
const OPTION_WIPEOUT = 'wipeout';
const OPTION_LANGUAGE = 'language';

const styles = theme => ({
  icon: {
    width: theme.spacing(4),
    textAlign: 'center'
  }
});

class Settings extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      currentModal: MODAL_NONE,
      isProgressShown: false
    }
  }

  onTimeoutSelected = (selection) => {
    if(selection){
      this.props.onTimeoutModified(selection.timeout);
    }
    this.setState({currentModal: MODAL_NONE});
  }

  onItemClicked = event => {
    switch(event){
      case OPTION_TRANSMISSION:
        this.props.history.push('/main/transmission');
        break;
      case OPTION_DOWNLOAD:
        window.open(APK_URL, '_blank', 'noopener');
        break;
      case OPTION_WIPEOUT:
        this.setState({currentModal: MODAL_WIPEOUT});
        break;
      default:
    }
  }

  /*
  * Called whenever the user has decided whether or not to wipeout the
  * application data from the browser storage.
  *
  * @param wipeout : True if the user has decided to erase all data, false otherwise.
  */
  onWipeoutResult = async (wipeout) => {
    if(wipeout){
      this.setState({isProgressShown: true, currentModal: MODAL_NONE});
      this.props.performWipeout();
    }else{
      this.setState({currentModal: MODAL_NONE});
    }
  }

  onBackClicked = () => {
    this.props.history.replace('/main');
  }

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <AppBar color="primary" position="static">
          <Toolbar>
            <IconButton className="btn-back-toolbar" onClick={this.onBackClicked}>
              <i style={{color: 'white' }} className="fas fa-arrow-left"></i>
            </IconButton>
            <Typography variant='h5' align='left' style={{color:'white'}}>Settings</Typography>
          </Toolbar>
        </AppBar>
        {this.state.currentModal === MODAL_WIPEOUT ? (
          <WipeoutModal onWipeoutResult={this.onWipeoutResult}/>
        ): null}
        <ProgressDialog
          isOpen={this.state.isProgressShown}
          title='Please wait'
          content='The data is being erased'/>
        <div className='list-settings'>
          <Paper className='settings-paper'>
            <List component='nav'>
              <ListItem button onClick={() => this.onItemClicked(OPTION_TRANSMISSION)}>
                <Icon className={classes.icon + ' fas fa-user-friends transmission'}/>
                <ListItemText primary="Wallet Transmission" />
              </ListItem>
              <ListItem button onClick={() => this.onItemClicked(OPTION_DOWNLOAD)}>
                <Icon className={classes.icon + ' fas fa-download'}/>
                <ListItemText primary="SelfTrust Offline app" />
              </ListItem>
              <ListItem button onClick={() => this.onItemClicked(OPTION_WIPEOUT)}>
                <Icon className={classes.icon + ' fas fa-trash'}/>
                <ListItemText primary="Wipeout wallet" />
              </ListItem>
              <ListItem button onClick={() => this.onItemClicked(OPTION_LANGUAGE)}>
                <Icon className={classes.icon + ' fas fa-tag'}/>
                <ListItemText primary={`Version ${version}`}/>
              </ListItem>
            </List>
          </Paper>
        </div>
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(Settings);
