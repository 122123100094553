import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Logo from '../../../assets/img/logo-img.svg';
import STButton from '../../../components/STButton/STButton';
import GitHubIcon from '@material-ui/icons/GitHub';
import AndroidIcon from '@material-ui/icons/Android';
import WelcomeButton from '../Welcome/WelcomeButton/WelcomeButton';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { APK_URL, GITHUB_APK_URL } from '../../../constants';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  toolbarContainer: {
    margin: '8px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  logoContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  logo: {
    margin: '5px',
    height: '52px'
  },
  logoText: {
    color: 'white'
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '90vh'
  },
  [theme.breakpoints.down('md')]: {
    content: {
      flexDirection: 'column'
    }
  }
});

class Releases extends React.Component {

  onGithubClicked = () => {
    window.open(GITHUB_APK_URL, '_blank');
  }

  onGooglePlayClicked = () => {
    window.open(APK_URL, '_blank');
  }

  onLoginClicked = () => {
    this.props.history.replace('/welcome?scan=true');
  }

  render() {
    const { classes, history } = this.props;
    return (
      <div className={classes.root}>
        <AppBar position="static">
            <div className={classes.toolbarContainer}>
              <Link to='/welcome'>
                <div className={classes.logoContainer}>
                  <img
                    src={Logo}
                    className={classes.logo}
                    alt='Logo'/>
                  <Typography className={classes.logoText}>
                    selftrust.me
                  </Typography>
                </div>
              </Link>
              <div>
                <WelcomeButton type='filled' onClick={this.onLoginClicked}>
                  Login
                </WelcomeButton>
              </div>
            </div>
        </AppBar>
        <div className={classes.content}>
          <STButton
            startIcon={<AndroidIcon color='disabled' style={{fontSize: 40}}/>}
            onClick={this.onGooglePlayClicked}>
            Google Play
          </STButton>
          <STButton
            disabled={true}
            startIcon={<GitHubIcon color='disabled' style={{fontSize: 40}}/>}
            onClick={this.onGithubClicked}>
            Github
          </STButton>
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(withRouter(Releases));
