import React from 'react';
import Typography from '@material-ui/core/Typography';
import Card from "@material/react-card";
import CardActionArea from '@material-ui/core/CardActionArea';
import Grid from '@material-ui/core/Grid';
import BigNumber from 'bignumber.js';
import "cryptocoins-icons/webfont/cryptocoins.css";
import '@material/react-card/dist/card.css';
import './Coins.css';

/**
* Calculates the fiat balance for each card, considering that the balance
* is expressed in base units and thus a BigNumber instance should be used.
*/
const calculateFiatBalance = (balance, price, precision) => {
  let bnBalance = new BigNumber(balance);
  return bnBalance.multipliedBy(price).dividedBy(Math.pow(10, precision));
}

/**
* Calculates the crypto balance in the asset units, given the fact that
* the balance passed by each 'coin' instance is expressed in base units.
* Eg. Satoshis in BTC and Weis in ETH.
*/
const calculateCryptoBalance = (balance, precision) => {
  return new BigNumber(balance).dividedBy(Math.pow(10, precision));
}

const coinGrid = (props) => {
  const coins = Object.keys(props.coins).map(key => props.coins[key]);
  let cards = coins.map( coin => {
    const coinName = coin.name;
    const { balance, symbol, precision } = coin;
    let price = coin.price;
    if(price === null || price === undefined) price = 0;
    // Balance in fiat
    let fiatBalance = calculateFiatBalance(balance, price, precision);
    // Balance in crypto units
    let cryptoBalance = calculateCryptoBalance(balance, precision);

    let logoImage = null;
    if(coin.logo){
      const logoImagePath = require('../../../../assets/img/tokens/' + coin.logo);
      logoImage = <i className='cc c-list-coin'><img src={logoImagePath}/></i>
    }else{
      const coinLogoClass = 'cc c-list-coin '+ symbol.toUpperCase();
      logoImage = <i className={coinLogoClass}></i>
    }
    return (
      <Grid item xs key={coinName}>
        <Card
          className='mdc-card'
          key={coinName}
          onClick={() => props.history.push('/main/details/'+symbol.toLowerCase())}>
          <CardActionArea>
            <div className="card-coins">
                <div className="logo-coin">
                    {logoImage}
                    <Typography>
                      <span className="coin-name">{coinName}</span>
                    </Typography>
                </div>
                <div className="card-unit">
                    <p className="card-coin_name">{symbol} {cryptoBalance.toFixed(8)}</p>
                    <Typography>
                        <span className='coin-name fiat-balance'>usd {fiatBalance.toFixed(2)}</span>
                    </Typography>
                </div>
            </div>
          </CardActionArea>
        </Card>
      </Grid>
    )
  });
  return cards;
}

export default coinGrid;
