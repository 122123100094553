import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';

class WipeoutModal extends React.Component {

  handleClose = (wipeout) => {
    this.props.onWipeoutResult(wipeout === true);
  }

  render(){
    return (
      <Dialog
          open={true}
          onClose={this.handleClose}
          aria-labelledby="simple-dialog-title">
        <DialogTitle id="simple-dialog-title">Wipeout wallet</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This will erase your wallet from this device.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.handleClose(false)} color="primary">
            Disagree
          </Button>
          <Button onClick={() => this.handleClose(true)} color="primary" autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default WipeoutModal;
