import React, {Component} from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';

/**
* Custom input class created to display cryptocurrency amounts taking in
* consideration the precision of each asset.
*/
class CryptoInput extends Component {

  onValueChanged = event => {
    let typedValue = event.target.value;
    // Removing anything that is not a number or a dot
    const ignoreRegex = /[^\d\.]/g;
    typedValue = typedValue.replace(ignoreRegex,'');

    // Splitting the entered value in an integer + . + decimals part
    // in order to prevent arbitrary precision being entered.
    const splitted = typedValue.split('.');
    if(splitted.length === 2){
      const precision = this.props.precision;
      const integers = splitted[0];
      let decimals = splitted[1];
      if(decimals.length > precision){
        decimals = decimals.slice(0, precision);
      }
      typedValue = integers + '.' + decimals;
    }
    event.target.value = typedValue;
    this.props.onChange(event);
  }

  render(){
    return (
      <TextField
        {...this.props}
        onChange={this.onValueChanged}
      />
    )
  }
}

CryptoInput.propTypes = {
  precision: PropTypes.number.isRequired
}

export default CryptoInput;
