import React from 'react'
import QRCode from 'qrcode.react';
import {QR_CODE_SIZES, QRCodesTypes} from '../../../../../constants';
import MultipartQRCode from '../../../../../components/MultipartQRCode/MultipartQRCode';
import './QRCodeStep.css';

class QRCodeStep extends React.Component {

  render(){
    if(this.props.handleStepResult)
      this.props.handleStepResult(this.props.index, true, null);
    const txBundle = this.props.payload ? this.props.payload : '';

    // Making a deep copy of the txBundle in order to be able to modify
    // what goes in the QR code
    let txBundleCopy = JSON.parse(JSON.stringify(txBundle));
    // Deleting a couple of field from the txBundle that don't
    // have to be included in the QR-code
    delete txBundleCopy.utxos;
    delete txBundleCopy.destination;
    delete txBundleCopy.outputs;
    delete txBundleCopy.hash;

    return (
      <div style={{textAlign: 'center'}}>
        <p className="text-alert" style={{marginBottom: '24px'}}>
          Scan the following QR code(s) from the SelfTrust Offline app to approve payment.
        </p>
        <MultipartQRCode
          type={QRCodesTypes.TYPE_UNSIGNED_TX}
          data={txBundleCopy}/>
      </div>
    )
  }
}
export default QRCodeStep;
