import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Alert from '@material-ui/lab/Alert';
import { APK_URL, KEY_HAS_ALERT_BEEN_DISMISSED, KEY_WALLET_ID } from '../../constants';
import { compareVersionNames } from '../../util';
import STService from '../../network/axios-verifier';
import HeaderLogo from '../HeaderLogo/HeaderLogo';
import BequeathIconGreen from '../../assets/img/bequeathable-green.svg';
import BequeathIconRed from '../../assets/img/bequeathable-red.svg';
import './Header.css';

const languageToggle = {
	'zh': 'EN',
	'en': '中文',
};

// Main Header component
class Header extends React.Component {

	state = {
    selectedLanguage: 'en',
    transmittedWallet: false,
		showAlert: true
  };

	logout = () => {
	  if(this.props.handleLogout){
	  	this.props.handleLogout();
	  }
	  return true;
	}

	alertCallback = () => {
		localStorage.setItem(KEY_HAS_ALERT_BEEN_DISMISSED, true);
		this.setState({showAlert: false});
	}

  componentDidMount(){
    const id = localStorage.getItem(KEY_WALLET_ID);
    STService.get('/transmission/' + id)
      .then(resp => {
        if(resp.status === 200){
          if(resp.data && resp.data.executed){
            this.setState({transmittedWallet: true});
          }
        }
      })
      .catch(error => {});
  }

	render() {
		const hasBeenDismissed = localStorage.getItem(KEY_HAS_ALERT_BEEN_DISMISSED);
		const {
			logged,
			primary,
			isFaq,
			selectedLanguage,
			totalFiatBalance,
			versions,
		 	networkError } = this.props;
		let { totalBtcBalance } = this.props;
		let { showAlert, transmittedWallet } = this.state;
		let balancePrimary = null;
		if(primary === 'usd'){
			balancePrimary = <p>usd <span className="count">{totalFiatBalance ? totalFiatBalance.toFixed(2) : '0.00'}</span></p>
		}else{
			balancePrimary = <p>btc <span className="count">{totalBtcBalance.toFixed(8)}</span></p>
		}
		// No alert being shown by default
		let alert = null;
    if(transmittedWallet && showAlert){
      // In case the wallet was already transmitted, the warning takes precedence
      alert = (
        <Alert color="warning" severity="warning" isOpen={true} onClose={this.alertCallback}>
          <p className="alert-content">This wallet was already transmitted.</p>
          <p>For security reasons, we recommend you migrate your funds to a new SelfTrust wallet.</p>
        </Alert>
      )
    }else if(networkError && showAlert) {
			alert = (
				<Alert color="error" severity="error" isOpen={true} onClose={this.alertCallback}>
						Too many network errors detected, please make sure you have a stable connection & reload the page.
				</Alert>
			)
		}else if(versions && versions.local && versions.remote){
			const localMobile = versions.local.mobile;
			const remoteMobile = versions.remote.mobile;
			const shouldUpdate = compareVersionNames(localMobile, remoteMobile);
      // First we make sure the 'versions' object exists and has the expected attributes
			if(shouldUpdate && !hasBeenDismissed && showAlert){
				// Then we only display the alert in case:
				// 1- There's a difference between the user's mobile version and
				// 		the latest published app version.
				// 2- The alert has not yet been dismissed.
				alert = (
					<Alert color="info" isOpen={true} toggle={this.alertCallback}>
						<p className="alert-content">
							Your SelfTrust Offline Android application is outdated. Please, download the newest version <a href={APK_URL} target='_blank' rel='noopener noreferrer'>here</a>.
						</p>
					</Alert>
				)
			}
		}
	const id = localStorage.getItem(KEY_WALLET_ID);
    return (
			<header className="header_area">
				<div className="main_menu">
					<nav className="navbar navbar-expand-lg navbar-right">
						<div className="container box_1620">
							{/* Brand and toggle get grouped for better mobile display */}
							<Link to="/main" className="navbar-brand">
								<HeaderLogo logged={logged}/>
							</Link>
							{/* Collect the nav links, forms, and other content for toggling */}
							<div className="box-nav">
								<ul className="nav navbar-nav navbar-right">
									<span>
	  								{logged && !isFaq ? <li className="nav-item"><Link to="/main/faq"><i className="fas fa-question-circle"></i></Link></li> : null}
										{<li className="nav-item"><a href="#"><i className="fas fa-share-alt"></i></a></li>}
										{logged ? <li className="nav-item"><Link to="/main/settings"><i className="fas fa-cog"></i></Link></li> : null}
										{logged ? <li className="nav-item"><a href="#" onClick={this.logout}><i className="fas fa-power-off"></i></a></li> : null}
										{!logged ? <li className="nav-item"><a href={APK_URL} target='_blank' rel='noopener noreferrer'><i className="fas fa-download"></i></a></li> : null}
										{!logged ?
												<li className="nav-item btn-select-language">
													<a className='lang-form' onClick={this.props.onChangeLanguage} hidden>
														{languageToggle[selectedLanguage]}
													</a>
												</li>
											: null }
										</span>
								</ul>
							</div>
						</div>
					</nav>
				</div>
				{(logged) && (
					<div>
						{alert}
						<div id="box-info-client">
							{logged && !isFaq ? balancePrimary : null}
							<Link to={`/main/transmission/${id}`}>
								<img src={this.state.transmittedWallet ? BequeathIconGreen : BequeathIconRed}/>
							</Link>
						</div>
					</div>
				)}
			</header>
		);
	}
}

// Type checking the props of the component
Header.propTypes = {
	selectedLanguage: PropTypes.string,
	logged: PropTypes.bool,
	primary: PropTypes.string,
	isFaq: PropTypes.bool,
  onLanguageChange: PropTypes.func,
	handleLogout: PropTypes.func
};

// Assign default values to the optional props
Header.defaultProps = {
	selectedLanguage: 'en',
	logged: false,
	primary: 'usd',
	isFaq: false,
  onLanguageChange: () => {}
};

export default Header;
