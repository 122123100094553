import { CORS_PROXY_URL } from './proxy';
const axios = require('axios');

// Tunneling request via the CORS proxy
const FEE_ESTIMATION_URL = CORS_PROXY_URL + '/https://www.etherchain.org/';

const instance = axios.create({
  baseURL: FEE_ESTIMATION_URL,
  headers: {'x-requested-with':'XMLHttpRequest'}
});

export default instance;
