import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import MediaQuery from 'react-responsive';
import QRCode from 'qrcode.react';
import { css } from '@emotion/core';
import CopyButton from '../../../../components/CopyButton/CopyButton';
import ButtonBase from '@material-ui/core/ButtonBase';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Verification from './Verification/Verification';
import CopyAddress from './CopyAddress/CopyAddress';
import { DEFAULT_ETH_PATH, QR_CODE_SIZES } from '../../../../constants';
import './Receive.css';

const CHOICE_NONE = 0;
const CHOICE_VERIFIED = 1;
const CHOICE_UNVERIFIED = 2;

class Receive extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      currentChoice: CHOICE_NONE,
      shouldReveal: false,
      clickCounter: 0,
      displayCopiedMsg: false
    }
  }

  updateChoice = (choice) => {
    this.setState({currentChoice: choice});
  }

  revealToggle = () => {
    this.setState((prevState, currentProps) => {
      prevState.shouldReveal = !prevState.shouldReveal;
      return prevState;
    });
  }

  static getDerivedStateFromProps(props, state){
    let result = null;
    if(props.clickCounter !== state.clickCounter){
      state.currentChoice = CHOICE_NONE;
      state.clickCounter = props.clickCounter;
      result = state;
    }
    return result;
  }

  copyHandler = (text, result) => {
    this.setState({copied: true, displayCopiedMsg: true});
    setTimeout(() => {
      this.setState({displayCopiedMsg: false});
    }, 1000);
  }

  render() {
    const { coin, freshAddresses, ethAccount } = this.props;
    let addressBundle = null;
    if(coin === 'btc'){
      if(freshAddresses && freshAddresses.length > 0){
        // Loading one of the network-provided fresh addresses
        addressBundle = freshAddresses[0];
      }
    }else{
      addressBundle = {
        address: ethAccount,
        path: DEFAULT_ETH_PATH
      }
    }
    let linkMessage = null;
    if(addressBundle){
      const message = this.state.shouldReveal ? 'Hide Address QR code' : 'Show Address QR code';
      linkMessage = (<a color='primary' href="#" onClick={this.revealToggle}>{message}</a>);
    }
    let copiedMsgVisibility = (this.state.copied && this.state.displayCopiedMsg) ? 'blinking' : 'invisible';
    // QR code containing the address to be displayed in case the user chooses to reveal it
    const addressQRCode = this.state.shouldReveal && addressBundle ?
      (<div className="qr-code">
        <MediaQuery query="(min-device-width:1224px)">
          <QRCode size={QR_CODE_SIZES.DESKTOP} value={addressBundle.address} />
        </MediaQuery>
        <MediaQuery query="(max-device-width: 1224px)">
          <QRCode size={QR_CODE_SIZES.MOBILE} value={addressBundle.address} />
        </MediaQuery>
      </div>) : null;
    const verifyPrompt = addressBundle ? (
      <a href="#" color='primary' onClick={() => this.updateChoice(CHOICE_VERIFIED)}>Verify address</a>
    ) : null;
    let content = (
      <div>
        <div className="receive-container address-container">
          <CopyAddress address={addressBundle ? addressBundle.address : null} copyHandler={this.copyHandler}/>
        </div>
        <div className="receive-container qr-code-container">
          {linkMessage}
          {addressQRCode}
          {verifyPrompt}
        </div>
      </div>
    )
    if(this.state.currentChoice === CHOICE_VERIFIED){
      content = <Verification addressBundle={addressBundle}/>
    }
    return (
      <React.Fragment>
        {content}
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          open={this.state.displayCopiedMsg}
          autoHideDuration={1500}
          onClose={this.onSnackbarClosed}
          message={<span id='message-id'>Address copied</span>}
          action={[
            <IconButton
              key='close'
              aria-label='Close'
              color='inherit'
              onClick={this.onSnackbarClosed}>
              <i style={{color: 'white' }} className="fas fa-times"></i>
            </IconButton>]
          }
        />
      </React.Fragment>
    )
  }
}

export default Receive;
