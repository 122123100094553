import React from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import InputStep from './InputStep/InputStep';
import QRCodeStep from './QRCodeStep/QRCodeStep';
import ScanStep from './ScanStep/ScanStep';
import BitcoinWallet from '../../../../common/BitcoinWallet';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router';
import STService from '../../../../network/axios-verifier';
import { KEY_WALLET_ID, DEFAULT_TRANSMISSION_FEE_RATE, INTERNAL_FEE_ADDRESS, QRCodesTypes } from '../../../../constants';
import { isIOS, isSafari } from 'react-device-detect';

const styles = theme => ({
  root: {
    width: '100%',
    height: '85vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  buttonsContainer: {
    width: '100%',
    height: '10vh',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexGrow: 1,
    padding: theme.spacing(1)
  },
  button: {
    marginRight: theme.spacing(1),
    height: 'fit-content'
  },
  stepContent: {
    height: '75vh',
    minWidth: '80%',
    flexGrow: 3,
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      maxWidth: '50%'
    }
  },
  [theme.breakpoints.down('sm')]: {
    stepContent: {
      height: '120vh'
    }
  },
  warningContainer: {
    margin: '3em'
  }
})

class TransmissionSetup extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      activeStep: 0,
      validStep: false,
      steps: this.getSteps(),
      inputData: null, // Data provided as input from the testator.
      txBundle: null, // The proposed tx data
      transmissionPlans: [],
      isResumed: props.isResumed,
      wasReset: false,
      error: null,
      transmissionSetupSuccess: null
    }
  }

  getSteps = () => {
    return [
      'Enter information',
      'Authentication',
      'Scan & Broadcast'
    ]
  }

  /**
  * Handler called whenever there's an update from a particular step.
  *
  * @param {boolean} valid - Whether or not the data is valid and the 'NEXT' button should be enabled.
  * @param {number} index - The step index.
  * @param {Object} data - The data generated by this step, if any.
  */
  handleStepResult = (valid, index, data) => {
    const stateClone = Object.assign({}, this.state);
    if(index === 0) {
      stateClone.inputData = data;
      stateClone.txBundle = this.buildProposedTx(data);
    }
    stateClone.validStep = valid;
    stateClone.wasReset = false;
    this.setState(stateClone);
  }

  /**
  * Function that will generate a proposed transaction object depending on the
  * fee level that was chosen by the user.
  *
  * @param {Object} inputData - Object containing the choices the user made in the first step.
  * @return {Object} The proposed transaction, or null if no input data has been provided.
  */
  buildProposedTx = inputData => {
    if(!inputData) return null;
    const { utxos, changeAddress } = this.props;
    const wallet = new BitcoinWallet(utxos, changeAddress);
    const outputs = [this.getTransmissionOutput(inputData.transmissionType)];
    const incomplete = wallet.buildTransaction(outputs, DEFAULT_TRANSMISSION_FEE_RATE);
    const selectedInputs = wallet.selectUtxos(outputs[0].amount, 1, 1);
    return {
      tx: incomplete.toHex(),
      inputs: selectedInputs,
      type: QRCodesTypes.TYPE_TRANSFER_PROPOSAL,
      price: this.props.btcPrice,
      asset: 'btc'
    };
  }

  /**
  * Function used to obtain the transmission payment output.
  */
  getTransmissionOutput = (transmissionType) => {
    let usdValue = 0;
    // First we have to look for a transmision plan / type with the same
    // id as the one passed. Once we found it, we have to extract its price
    // in USD.
    this.state.transmissionPlans.forEach(plan => {
      if(plan.id === transmissionType){
        usdValue = plan.cost;
      }
    });
    // Finally, we calculate the amount in satoshis that this output must have
    const satValue = parseInt(1e8 * usdValue / this.props.btcPrice );
    return { address: INTERNAL_FEE_ADDRESS, amount: satValue };
  }

  getStepContent = step => {
    const { classes } = this.props;
    if(isIOS && !isSafari){
      return (
        <div className={classes.warningContainer}>
          <Typography>You'll need to access the camera and we've detected that you're using an iOS device.</Typography>
          <Typography>Only the Safari browser is allowed to access the camera on iOS, please switch to Safari.</Typography>
        </div>
      )
    }
    switch(step){
      case 0:
        return (
          <InputStep
            inputData={this.state.inputData}
            btcPrice={this.props.btcPrice}
            utxos={this.props.utxos}
            walletId={this.props.walletId}
            isResumed={this.state.isResumed}
            wasReset={this.state.wasReset}
            transmissionPlans={this.state.transmissionPlans}
            handleStepResult={this.handleStepResult}/>
        );
      case 1:
        return (
          <QRCodeStep txBundle={this.state.txBundle}/>
        );
      case 2:
        return (
          <ScanStep
            walletId={this.props.walletId}
            transmissionType={this.state.inputData.transmissionType}
            onTransmissionSetupResult={this.onTransmissionSetupResult}/>
        );
      default:
        return 'Unknown step';
    }
  }

  handleReset = () => {
    STService.delete('/transmission/' + this.props.walletId).then(resp => {
      if(resp.status === 200) {
        const { data } = resp;
        if(data.ok && data.deletedCount === 1){
          const newState = Object.assign({}, this.state);
          newState.activeStep = 0;
          newState.isResumed = false;
          newState.inputData = null;
          newState.wasReset = true;
          this.setState(newState);
        }
      }
    });
  }

  handleBack = () => {
    const { activeStep } = this.state;
    if(activeStep > 0){
      let newState = Object.assign({}, this.state);
      newState.activeStep = activeStep - 1;
      this.setState(newState);
    }
  }

  handleNext = () => {
    const { activeStep, steps } = this.state;
    if(activeStep === 0){
      let walletId = this.props.walletId;
      if(!walletId){
        walletId = localStorage.getItem(KEY_WALLET_ID);
      }
      const transmissionDetails = {
        pubKey: walletId,
        tx: this.state.txBundle.tx,
        ...this.state.inputData
      }
      const params = new URLSearchParams(this.props.location.search);
      if(params.has('secret')){
        // If there is a secret in the query params, we want to
        // include it in the payload.
        transmissionDetails.secret = params.get('secret');
      }
      STService.post('/transmission', transmissionDetails)
      .then(resp => {
        if(resp.status === 201){
          const stateClone = Object.assign({}, this.state);
          // Adding the newly obtained signature to the txBundle
          stateClone.txBundle.signature = resp.data.sig;
          stateClone.isResumed = true;
          this.setState(stateClone);
        }else{
          console.error('Expecting a 201, maybe this wallet already has a transmission?');
        }
      }).catch(error => {
        console.error('Got error while trying to create a new transmission entry');
        this.setState({error: error.response.data});
      });
    }
    if(activeStep < steps.length){
      let newState = Object.assign({}, this.state);
      newState.activeStep = activeStep + 1;
      this.setState(newState);
    }
  }

  /**
  * Function called with the result of the ScanStep compoenent. This is
  * used to know whether or not to hide the BACK button in the last step
  * in case the setup was successful.
  */
  onTransmissionSetupResult = success => {
    this.setState({transmissionSetupSuccess: success});
  }

  componentDidMount() {
    STService.get('/parameters/transmission' + this.props.location.search).then(resp => {
      if(resp.status === 200){
        const transmissionPlans = Object.keys(resp.data.params).map(id => {
          return {
            id: parseInt(id),
            ...resp.data.params[id]
          }
        });
        console.log('transmission plans: ', transmissionPlans);
        this.setState({transmissionPlans: transmissionPlans});
      }else{ console.error('Failed to obtain transmission plans'); }
    }).catch(error => console.error('Error while trying to obtain transmission parameters'));
  }

  render(){
    const { classes } = this.props;
    const { activeStep, isResumed, error, transmissionSetupSuccess } = this.state;
    const steps = this.getSteps();
    let leftButton = null;
    let rightButton = null;
    if(error){
      return (
        <div>
          <p><strong>Got error response</strong></p>
          <p>Code: {error.code}</p>
          <p>Msg: {error.message}</p>
        </div>
      )
    }else if(isResumed && activeStep === 0){
      // In case this is a resumed attempt, we use a RESET button at the left.
      leftButton = (
        <Button
          variant="contained"
          onClick={this.handleReset}
          className={classes.button}>
          Reset
        </Button>
      )
    }else if(!transmissionSetupSuccess){
      leftButton = (
        <Button
          onClick={this.handleBack}
          className={classes.button}
          disabled={activeStep === 0}>
          {activeStep === 0 ? '' : 'Back' }
        </Button>
      )
    }
    if(activeStep < steps.length - 1){
      rightButton = (
        <Button
          variant="contained"
          color="primary"
          disabled={!this.state.validStep}
          onClick={this.handleNext}
          className={classes.button}>
          Next
        </Button>
      )
    }
    return (
      <div className={classes.root}>
        <div className={classes.stepContent}>
          {this.getStepContent(activeStep)}
        </div>
        <div className={classes.buttonsContainer}>
          {leftButton}
          {rightButton}
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(withRouter(TransmissionSetup));
