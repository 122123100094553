import React, {Component} from 'react';
import {isAddress} from 'web3-utils';
import TextField from '@material-ui/core/TextField';
import FeeSelector from './FeeSelector/FeeSelector';
import FeeDetailsModal from '../../../../../../components/FeeDetailsModal/FeeDetailsModal';
import CryptoInput from '../../../../../../components/CryptoInput/CryptoInput';
import { withStyles } from '@material-ui/core/styles';
import axiosVerifier from '../../../../../../network/axios-verifier';
import hash from 'object-hash';
import BigNumber from 'bignumber.js';
import { isMobile } from 'react-device-detect';
import { ETH_GAS_LIMIT, ACTIVE_INTERNAL_FEE_ADDRESS } from '../../../../../../constants'
import TxBuilderCommon from '../TxBuilderCommon';

BigNumber.set({DECIMAL_PLACES: 8});

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5)
  }
});

class EthTxBuilder extends Component {
  constructor(props){
    super(props);
    this.state = {
      destination: {value: '', valid: false},
      amount: {value: '', valid: false},
      insufficientBalance: false,
      outputs: {
        destination: 0,
        internalFee: 0,
        minerFee: 0
      },
      selectedFeeBucket: null,
      gasPrice: 0,
      displayError: false,
      feeDetailsVisible: false,
      internalFeeParams: null
    }
  }

  static getInternalFeeAddress() {
    return ACTIVE_INTERNAL_FEE_ADDRESS;
  }

  handleDestinationChange = event => {
    const typedValue = event.target.value;
    const isValid = this.verifyInput('destination', typedValue);
    this.setState({destination: {value: typedValue, valid: isValid}});
  }

  handleAmountChange = event => {
    // Skipping mouse scroll events on desktop
    if(event.nativeEvent.inputType === undefined && !isMobile) return;
    const typedValue = event.target.value;
    const isValid = this.verifyInput('amount', typedValue);
    const hasEnough = this.hasEnough(typedValue, this.state.outputs.minerFee);
    const stateClone = Object.assign({}, this.state);
    const value = +typedValue;
    const { price } = this.props.coin;
    const { internalFeeParams } = this.state;
    stateClone.outputs.internalFee = TxBuilderCommon.calculateInternalFee(value, price, 18, internalFeeParams);
    stateClone.insufficientBalance = !hasEnough;
    stateClone.amount = {value: typedValue, valid: isValid};
    if(isValid){
      stateClone.outputs.destination = value;
    }else{
      stateClone.outputs.destination = 0;
    }
    this.setState(stateClone);
  }

  verifyInput = (name, value) => {
    if(name === 'destination'){
      return isAddress(value);
    }else if(name === 'amount'){
      try{
          parseFloat(value);
          return true;
      }catch(error){
        console.error('Caught error while parsing amount value. Msg: ', error);
        return false;
      }
    }
    return false;
  }

  hasEnough = (toSend, minerFee) => {
    toSend = isNaN(parseFloat(toSend)) ? 0 : parseFloat(toSend);
    // Specifying all our costs
    const weiInternalFee = new BigNumber(this.state.outputs.internalFee).multipliedBy(1e18);
    const weiMinerFee = new BigNumber(minerFee).multipliedBy(1e18);
    const weiToSend = new BigNumber(toSend).multipliedBy(1e18);
    // Obtaining available balance
    const availableBalance = new BigNumber(this.props.coin.balance);
    // Finally checking if the available balance is enough to cover all costs
    return availableBalance.isGreaterThan(weiInternalFee.plus(weiMinerFee).plus(weiToSend));
  }

  // Handles the user-selected fee bucket (denominated in block until confirmation)
  handleFeeSelection = (bucket) => {
    const minerFee = new BigNumber(bucket.gasPrice)
                        .multipliedBy(1e9)
                        .multipliedBy(ETH_GAS_LIMIT.ETH_TRANSFER)
                        // The miner fee is multiplied by 2 to consider the SelfTrust fee tx
                        .multipliedBy(2)
                        .dividedBy(1e18);
    const hasEnough = this.hasEnough(this.state.amount.value, minerFee);
    this.setState((prevState, currentProps) => {
      this.overrideUpdate = true;
      prevState.insufficientBalance = !hasEnough;
      prevState.outputs.minerFee = minerFee.toNumber();
      prevState.gasPrice = bucket.gasPrice;
      prevState.selectedFeeBucket = bucket;
      return prevState;
    });
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    // We should not check the form if the previous state had displayError set as true
    if(!prevState.displayError){
      this.checkForm();
    }
    // If we don't yet have the SelfTrust fee parameters, we should load them
    if(this.state.internalFeeParams === null){
      axiosVerifier.get('/parameters/fee')
        .then(response => {
          if(response.data && response.data.params){
            this.setState({internalFeeParams: response.data.params});
          }
        })
        .catch(error => {
          console.error('Got error while trying to fetch the SelfTrust fee parameters');
          this.setState({
            missingInternalFee: true,
            displayError: true,
            verificationError: 'Got error while trying to fetch the SelfTrust fee parameters',
            internalFeeParams: null
          });
        });
      // We set the 'internalFeeParams' to zero to indicate that a fetch procedure is already
      // underway. This prevents repeated API calls in case 'componentDidUpdate' is called
      // several times before the network call is fulfilled.
      this.setState({internalFeeParams: 0});
    }
  }

  checkForm = async () => {
    // Decide whether or not to allow the NEXT button to be enabled
    const isValid = this.state.outputs.internalFee !== null &&
                        this.state.outputs.minerFee !== 0 &&
                        this.state.outputs.destination !== 0 &&
                        this.state.destination.valid && !this.state.insufficientBalance;
    let txBundle = null;
    if(isValid) {
      // If the form is valid, we must proceed to build a proposed transaction
      // and send it to the verification server
      txBundle = this.buildTransaction();
      const resp = await axiosVerifier.post('/verify', txBundle);
      if(resp.data.r && resp.data.s){
        txBundle.signature = { r:resp.data.r, s: resp.data.s };
        txBundle.hash = hash(txBundle);
      }else{
        //TODO: Like with the BTC, activate a snackbar with an error message here.
      }
    }
    this.props.handleStepResult(this.props.index, isValid, txBundle);
  }

  buildTransaction = () => {
    const amount = new BigNumber(this.state.outputs.destination).multipliedBy(1e18);
    const minerFee = new BigNumber(this.state.outputs.minerFee).multipliedBy(1e18);
    const internalFee = new BigNumber(this.state.outputs.internalFee).multipliedBy(1e18);
    return {
      asset: 'eth',
      tx: {
        from: this.props.ethAccount,
        to: this.state.destination.value,
        amount: amount.toFixed(0),
        nonce: this.props.ethNonce,
        gasPrice: this.state.gasPrice,
        feeBucket: this.state.selectedFeeBucket.label,
        minerFee: minerFee.toFixed(0),
        internalFeeAmount: internalFee.isEqualTo(0) ? '0' : internalFee.toFixed(0),
        internalFeeDestination: internalFee.isEqualTo(0) ? '' : ACTIVE_INTERNAL_FEE_ADDRESS
      }
    };
  }

  shouldComponentUpdate(nextProps, nextState){
    // Prevents unnecessary updates
    const hashOpts = {ignoreUnknown: true};
    // Enumerating things that could have changed and that would require an update
    const balanceChanged = this.props.balance !== undefined && !this.props.balance.isEqualTo(nextProps.balance);
    const propsChanged = hash(this.props, hashOpts) !== hash(nextProps, hashOpts);
    const stateChanged = hash(this.state) !== hash(nextState);
    let shouldUpdate = balanceChanged || propsChanged || stateChanged || this.overrideUpdate;
    if(shouldUpdate === undefined){
      console.warn(`ShouldUpdate balance: ${balanceChanged}, props: ${propsChanged}, state: ${stateChanged}, override: ${this.overrideUpdate}`);
      shouldUpdate = true;
    }
    this.overrideUpdate = false;
    return shouldUpdate;
  }

  render(){
    const { classes } = this.props;
    const enableFeeSelector = this.props.isloaded &&
        this.hasEnough(this.state.amount.value, 0) &&
        this.state.amount.valid;
    let toSend = this.state.outputs.destination;
    let totalFees = this.state.outputs.minerFee + this.state.outputs.internalFee;
    const totalSpent = toSend + totalFees;
    const invalidAmount = (!this.state.amount.valid || this.state.insufficientBalance) &&
        this.state.amount.value !== '' &&
        this.state.outputs.destination !== 0;

    const { price } = this.props.coin;

    // Specifying output text
    const txtFees = `${totalFees.toFixed(8)}   (USD ${(totalFees * price).toFixed(2)})`;
    const txtTotal = `${totalSpent.toFixed(8)}   (USD ${(totalSpent * price).toFixed(2)})`;
    return(
      <React.Fragment>
        <form>
          <FeeDetailsModal
            visible={this.state.feeDetailsVisible}
            outputs={this.state.outputs}
            price={price}
            onDismiss={() => this.setState({feeDetailsVisible: false})}/>
          <TextField
            id="outlined-address"
            label="Pay To"
            className={classes.textField}
            value={this.state.destination.value}
            onChange={this.handleDestinationChange}
            margin="normal"
            variant="outlined"
            error={!this.state.destination.valid && this.state.destination.value !== ''}
            disabled={!this.props.isloaded}
            fullWidth={true}
          />
        <CryptoInput
            id="outlined-amount"
            label="Amount"
            precision={18}
            className={classes.textField}
            value={this.state.amount.value}
            onChange={this.handleAmountChange}
            margin="normal"
            variant="outlined"
            fullWidth={true}
            type="text"
            error={invalidAmount}
            disabled={!this.state.destination.valid || this.state.destination === ''}
          />
          <FeeSelector
            handleFeeSelection={this.handleFeeSelection}
            enabled={enableFeeSelector}
            fee_buckets={this.props.feeBuckets}/>
          <TextField
            label="Fees"
            className={classes.textField}
            margin="normal"
            InputProps={{
              readOnly: true
            }}
            onClick={() => this.setState({feeDetailsVisible: true})}
            value={txtFees}
            fullWidth={true}
            variant="filled"
          />
          <TextField
            id="output-total-spent"
            label="Total Spent"
            className={classes.textField}
            margin="normal"
            InputProps={{
              readOnly: true,
            }}
            value={txtTotal}
            fullWidth={true}
            variant="filled"
            error={this.state.insufficientBalance}
          />
        </form>
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(EthTxBuilder);
