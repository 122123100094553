import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';

const feeDetailsModal = (props) => {
  const {price} = props;
  let minerFee = props.outputs.minerFee;
  let internalFee = props.outputs.internalFee || 0;
  let precision = 8;

  const txtMinerFee = `${minerFee.toFixed(precision)}   (USD ${(minerFee * price).toFixed(2)})`;
  const txtInternalFee = `${internalFee.toFixed(precision)}   (USD ${(internalFee * price).toFixed(2)})`;
  return (
    <Dialog
        open={props.visible}
        aria-labelledby="simple-dialog-title">
      <DialogTitle id="simple-dialog-title">Fees details</DialogTitle>
      <DialogContent>
        <TextField
          label="Miner fee"
          margin="normal"
          InputProps={{
            readOnly: true
          }}
          value={txtMinerFee}
          fullWidth={true}
          variant="filled"
        />
        <TextField
          label="SelfTrust fee"
          margin="normal"
          InputProps={{
            readOnly: true
          }}
          value={txtInternalFee}
          fullWidth={true}
          variant="filled"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onDismiss} color="primary">
          Dismiss
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default feeDetailsModal;
