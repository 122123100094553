import React from 'react';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import ChallengeQRCode from './ChallengeQRCode/ChallengeQRCode';
import QRCodeScanner from '../QRCodeScanner/QRCodeScanner';
import MultipartQRCode from '../MultipartQRCode/MultipartQRCode';
import { QRCodesTypes } from '../../constants';
import { withStyles } from '@material-ui/core/styles';

const STEP_QRCODE = 0;
const STEP_SCANNER = 1;

const styles = theme => ({
  dataContainer: {
    width: '100%',
    height: '75vh',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  buttonContainer: {
    width: '100%',
    height: '10vh',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    padding: theme.spacing(1)
  },
  button: {
    height: 'fit-content'
  },
  [theme.breakpoints.up('md')]: {
    button: {width: '30%'}
  },
  [theme.breakpoints.down('md')]: {
    button: {width: '60%'}
  }
})

class Authentication extends React.Component {

  state = {
    currentStep: STEP_QRCODE
  }

  getCurrentStep = () => {
    const { currentStep } = this.state;
    switch(currentStep){
      case STEP_QRCODE:
        const { challenge, message } = this.props;
        let extra = {};
        if(challenge.type === QRCodesTypes.TYPE_CANCEL_TRANSFER ||
          challenge.type === QRCodesTypes.TYPE_RENEW_TRANSFER) {
          // Type is TYPE_CANCEL_TRANSFER and we unpack the extra data, containing
          // the details about the transfer
          extra = {
            ...this.props.extraData
          }
        }
        return (
          <React.Fragment>
            <p className="text-alert" style={{marginBottom: '24px'}}>
              {message}
            </p>
            <MultipartQRCode
              type={challenge.type}
              data={{
                ...challenge,
                ...extra
              }}/>
          </React.Fragment>
        )
      case STEP_SCANNER:
        return <QRCodeScanner onResult={this.onResult}/>
    }
  }

  handleNext = () => {
    this.setState({currentStep: STEP_SCANNER});
  }

  handleBack = () => {
    this.setState({currentStep: STEP_QRCODE});
  }

  /**
  * Function called whenever the QR code data is ready.
  */
  onResult = data => {
    const { type } = this.props.challenge;
    const parsedData = JSON.parse(data);
    this.props.onChallengeSigned(type, parsedData);
  }

  render(){
    const { classes, message } = this.props;
    const { currentStep } = this.state;
    const stepContent = this.getCurrentStep();
    return (
      <React.Fragment>
        <div className={classes.dataContainer}>
          {stepContent}
        </div>
        <div className={classes.buttonContainer}>
          <Button
            variant="contained"
            color="primary"
            onClick={currentStep === STEP_QRCODE ? this.handleNext : this.handleBack}
            className={classes.button}>
            {currentStep === STEP_QRCODE ? 'Next' : 'Back'}
          </Button>
        </div>
      </React.Fragment>
    )
  }
}

Authentication.propTypes = {
  message: PropTypes.string
}
Authentication.defaultProps = {
  message: 'Scan the following QR code(s) from the SelfTrust Offline app.'
}

export default withStyles(styles)(Authentication);
