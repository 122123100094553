import React, { Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import dateFormat from 'dateformat';

const styles = theme => ({
  paragraph: {
    margin: '1em',
    textIndent: '-1em',
    paddingLeft: '1em',
    paddingTop: '1em'
  },
  title: {
    margin: '1em'
  }
});

/**
* Simple component that is used to display a success message to the user once
* the transmission setup procedure is over.
*/
const success = props => {
  const { classes } = props;
  return(
    <Fragment>
      <Typography color="primary" align="center" className={classes.paragraph}>Success!</Typography>
      <Typography color="primary" align="left" className={classes.paragraph}>
        1) On {dateFormat(props.expirationDate, 'mmmm d, yyyy "at" h:MM Z')}, {props.heirEmail} shall receive a post-inheritance code—unless you renew or cancel your transmission before.
      </Typography>
      <Typography color="primary" align="left" className={classes.paragraph}>
        2) To inherit your wallet, {props.heirEmail} must combine the post-inheritance code with a <strong>pre-inheritance code</strong>—that you must obtain and transmit now by clicking NEXT on the SelfTrust Offline app.
      </Typography>
    </Fragment>
  )
}

export default withStyles(styles)(success);
