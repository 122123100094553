import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  welcomeButton: {
    margin: theme.spacing(0.5),
    fontSize: '11px',
    background: 'transparent',
    fontWeight: 'bold',
    '&:hover': {
      background: theme.palette.primary.light,
      color: theme.palette.primary.contrastText
    },
    '&:focus': {
      outline: 'none',
      boxShadow: `0 0 0 3px ${theme.palette.primary.light}`
    }
  },
  filled: {
    border: '0px',
    backgroundColor: '#FFF',
    color: theme.palette.primary.main
  },
  outlined: {
    border: '1px solid #ffffff',
    color: 'white'
  },
  [theme.breakpoints.up('md')]: {
    welcomeButton: {
      height: '36px',
      borderRadius: '18px',
      minWidth: theme.spacing(15)
    }
  },
  [theme.breakpoints.down('md')]: {
    welcomeButton: {
      height: '25px',
      borderRadius: '12px',
      minWidth: '94px'
    }
  }
})

const welcomeButton = props => {
  const {classes, children, type, onClick} = props;
  let typeClass = classes.outlined;
  if(type === 'filled'){
    typeClass = classes.filled;
  }
  return (
    <button
      onClick={() => onClick()}
      className={`${classes.welcomeButton} ${typeClass}`}>
      {children.toUpperCase()}
    </button>
  )
}

welcomeButton.propTypes = {
  type: PropTypes.oneOf(['outlined', 'filled'])
}

export default withStyles(styles)(welcomeButton);
