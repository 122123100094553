import React from 'react';
import PropTypes from 'prop-types';
import '../BroadcastResult.css';

const checkMark = (props) => {
  return (
    <div className="f-modal-alert modal-success">
      <div className="f-modal-icon f-modal-success animate">
        <span className="f-modal-line f-modal-tip animateSuccessTip"></span>
        <span className="f-modal-line f-modal-long animateSuccessLong"></span>
        <div className="f-modal-placeholder"></div>
        <div className="f-modal-fix"></div>
      </div>
      <h2>Success!</h2>
      <a href={props.blockchainLink} target='_blank' rel='noopener noreferrer'>Check your payment on the Blockchain</a>
      <p>(Can take up to a few minutes to show up)</p>
    </div>
  )
};

checkMark.propTypes = {
  blockchainLink: PropTypes.string.isRequired
}

export default checkMark;
