import React, {Component} from 'react';
import BaseStepper from '../../../Layout/BaseStepper/BaseStepper';
import BitcoinTxBuilder from './TxBuilder/BitcoinTxBuilder/BitcoinTxBuilder';
import EthTxBuilder from './TxBuilder/EthTxBuilder/EthTxBuilder';
import ERC20Builder from './TxBuilder/ERC20Builder/ERC20Builder';
import QRCodeStep from './QRCodeStep/QRCodeStep';
import ScanStep from './ScanStep/ScanStep';
import './Send.css';

class Send extends Component {
  constructor(props){
    super(props);
    this.state = {
      txBundle: null,
      signedTx: null,
      sent: false,
      success: false
    }
    // Fee estimation buckets, translated into the expected number of
    // blocks that the transaction will have to wait before being included
    // in the blockchain
    this.BTC_FEE_BUCKETS = [
      {key: 2, blocks: 2, label: 'Fast (~0-60 min)'},
      {key: 6, blocks: 6, label: 'Regular  (>60 min)'},
      {key: 100, blocks: 100, label: 'Slow  (>24 h)'}
    ];

    this.ETH_FEE_BUCKETS = [
      {key: 'fastest', label: 'Fastest (15 to 30 seconds)'},
      {key: 'fast', label: 'Fast (< 1 min)'},
      {key: 'standard', label: 'Standard (< 5 min)'},
      {key: 'safeLow', label: 'Safe Low (< 30 min)'}
    ];
  }

  onStepResult = async (index, result) => {
    if(index === 0){
      if(result === null) return;
      const txBundle = result;
      // Only update the state the txBundle is null (first time) or if the
      // serialized transaction is different than the one we have.
      if(this.state.txBundle === null || txBundle.hash !== this.state.txBundle.hash){
        try{
          if(txBundle.asset === 'btc'){
            // try{
            const promises = result.utxos.map(async (utxo) => {
              // Retrieving UTXO derivation path from the indexed db
              return this.props.db.utxos.where({hash: utxo.hash, index: utxo.index}).first();
            });
            // Waiting for the promises
            let storedUtxos = null;
            try{
              storedUtxos = await Promise.all(promises);
              // Extracting the actual derivation paths
              txBundle.derivation = storedUtxos.map(storedUtxo => storedUtxo.path)
              txBundle.scripts = result.utxos.map(utxo => utxo.script);
            }catch(error){
              console.error('Caught error while waiting for promises to resolve. Msg: ', error);
              return;
            }
          }
          // Setting the correct price in the txBundle
          // TODO: Temporal fix put in place for ERC-20 tokens, remove this later.
          txBundle.price = this.props.coin.price ? this.props.coin.price.toFixed(2) : 1;
          // Updating the state
          this.setState((prevState, currentProps) => {
            prevState.txBundle = txBundle;
            prevState.hash = null;
            prevState.sent = false;
            return prevState;
          });
        }catch(error){
          console.error('Caught error while trying to handle step result. index: ', index, ', error: ', error);
        }
        console.log('txBundle: ', txBundle);
      }
    }else if(index === 2){
      if(result !== null){
        this.setState({signedTx: result});
      }
    }
  }

  render(){
    const { coin } = this.props;
    let builderForm = null;
    if(coin.symbol === 'btc'){
      builderForm = (
        <BitcoinTxBuilder
          name='Build'
          index={0}
          feeBuckets={this.BTC_FEE_BUCKETS}
          coin={coin}
          utxos={this.props.utxos}
          isloaded={this.props.isLoaded}
          changeaddr={this.props.changeaddr} />
      )
    }else if(coin.symbol === 'eth'){
      builderForm = (
        <EthTxBuilder
          name='Build'
          index={0}
          ethAccount={this.props.ethAccount}
          ethNonce={this.props.ethNonce}
          coin={coin}
          isloaded={this.props.isLoaded}/>
      )
    }else{
      builderForm = (
        <ERC20Builder
          name='Build'
          index={0}
          ethAccount={this.props.ethAccount}
          ethNonce={this.props.ethNonce}
          coin={coin}
          eth={this.props.coins.eth}
          isloaded={this.props.isLoaded}/>
      )
    }
    return (
      <React.Fragment>
        <section className="home_banner_area">
          <div className="container box_1620">
            <BaseStepper
              {...this.props}
              nextlabel='Next'
              onstepresult={this.onStepResult}>
              {builderForm}
              <QRCodeStep
                name='Sign'
                index={1}
                payload={this.state.txBundle}/>
              <ScanStep name='Pay'
                index={2}
                txBundle={this.state.txBundle}
                signedtx={this.state.signedTx}
                receipt={this.state.receipt}
                hash={this.state.hash}
                sent={this.state.sent}
                coin={coin}
                handleEthTxBroadcast={this.props.handleEthTxBroadcast}
                handleBtcTxBroadcast={this.props.handleBtcTxBroadcast}
                success={this.state.success}/>
            </BaseStepper>
          </div>
        </section>
      </React.Fragment>
    )
  }
}

export default Send;
