import React from 'react';
import MultipartQRCode from '../../MultipartQRCode/MultipartQRCode';
import { QRCodesTypes } from '../../../constants';

class ChallengeQRCode extends React.Component {

    render(){
      const { challenge } = this.props;
      return (
        <React.Fragment>
          <p className="text-alert" style={{marginBottom: '24px'}}>
            Scan the following QR code(s) from the SelfTrust Offline app.
          </p>
          <MultipartQRCode
            type={QRCodesTypes.TYPE_CANCEL_TRANSFER}
            data={{challenge: challenge}}/>
        </React.Fragment>
      )
    }
}

export default ChallengeQRCode;
