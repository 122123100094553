import React from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  title: {
    fontWeight: 'bold'
  },
  listItem: {
    margin: '10px',
    textAlign: 'left',
    color: theme.palette.text.secondary
  },
  [theme.breakpoints.up('sm')]:{
    ul: {
      paddingInlineStart: '0px'
    }
  },
  [theme.breakpoints.down('sm')]:{
    '@media (orientation: portrait)': {
      ul: {
        paddingInlineStart: '20%'
      }
    }
  }
})

const feature = props => {
  const {title, items, icon, classes} = props;
  return(
    <div className={classes.root}>
      <img src={icon} alt={`Image for ${title}`}/>
      <Typography className={classes.title} color='textSecondary' variant='h6'>{title}</Typography>
      <ul className={classes.ul}>
        {items.map(item => <li key={item}><Typography className={classes.listItem}>{item}</Typography></li>)}
      </ul>
    </div>
  )
}

export default withStyles(styles)(feature);
