import React from 'react';
import { FormattedMessage } from 'react-intl';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';

import './Faq.css';

// Faq Page component
class Faq extends React.Component {

  onBackClicked = () => {
    this.props.history.goBack();
  }

  render(){
    return (
      <React.Fragment>
        <AppBar color="primary" position="static">
          <Toolbar>
            <IconButton className="btn-back-toolbar" onClick={this.onBackClicked}>
              <i style={{color: 'white' }} className="fas fa-arrow-left"></i>
            </IconButton>
            <Typography variant='h5' align='left' style={{color:'white'}}>FAQ</Typography>
          </Toolbar>
        </AppBar>
        <div>
          <div id="list-benefit">
            <ul>
              <li>
                <p><i className="fas fa-circle"></i><FormattedMessage id="faq.feature_item_1" /></p>
              </li>
              <li>
                <p><i className="fas fa-circle"></i><FormattedMessage id="faq.feature_item_2" /></p>
              </li>
              <li>
                <p><i className="fas fa-circle"></i>Multicoins.**</p>
              </li>
              <li>
                <p><i className="fas fa-circle"></i>Standard.***</p>
              </li>
              <li>
                <p><i className="fas fa-circle"></i>Directly Transmissible to Your Heirs.</p>
              </li>
            </ul>
            <span className="benefit-detail">* For transactions under usd 100. ** Bitcoin, Ether, ERC20. *** BIP39 & BIP44compatible</span>
          </div>
        <section className="home_banner_area page-login">
          <div className="container box_1620 expansion-panel">
            <ExpansionPanel>
              <ExpansionPanelSummary>
                <Typography className="title-expansion-panel" color="primary">1) What?</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
                  sit amet blandit leo lobortis eget.
                </Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel>
              <ExpansionPanelSummary>
                <Typography className="title-expansion-panel" color="primary">2) Why?</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
                  sit amet blandit leo lobortis eget.
                </Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel>
              <ExpansionPanelSummary>
                <Typography className="title-expansion-panel" color="primary">3) How?</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography>
                  a) Download the SelfTrust Offline app on an Android device, then disable <br />
                  all connectivity.<br />
                  b) Install the SelfTrust Offline app.<br />
                  (…)
                </Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            </div>
          </section>
        </div>
      </React.Fragment>
    );
  }
}
export default Faq;
