const INFURA_API_KEY = '89f35e7059884b8cac39e0a8513dc60c';
export const INFURA_ROPSTEN = `https://ropsten.infura.io/v3/${INFURA_API_KEY}`;
export const INFURA_MAINNET = `https://mainnet.infura.io/v3/${INFURA_API_KEY}`;

const INFURA_WSS_MAINNET = `wss://mainnet.infura.io/ws/v3/${INFURA_API_KEY}`;
const INFURA_WSS_ROPSTEN = `wss://ropsten.infura.io/ws/v3/${INFURA_API_KEY}`;

// Etherscan API KEY
export const ETHERSCAN_API_KEY = 'RCHENX639JR2N5YRFE2RE7ARJWS6MDGMH8';

// Default number of transactions loaded in the TxHistory component
export const TX_HISTORY_PAGE_SIZE = 50;

// Batch size for ethereum tx history
export const ETH_TX_BATCH_SIZE = 100;

// Default BIP-44 derivation path for Ethereum
export const DEFAULT_ETH_PATH = 'M/44H/60H/0H/0';

// Default block explorers
export const BLOCK_EXPLORERS = {
  BTC: 'https://blockstream.info/',
  ETH: {
    MAIN: 'https://etherscan.io/',
    TEST: 'https://ropsten.etherscan.io/'
  }
}

// Gas limits
export const ETH_GAS_LIMIT = {
  ETH_TRANSFER: 21000,
  TOKEN_TRANSFER: 200000
}

// BTC SelfTrust fee address
// TODO: Obtain this from the webservice
export const INTERNAL_FEE_ADDRESS = '1CRvuWBeFebrHxL1EDUHmiNg9x2613C2sS';

// ETH SelfTrust fee address
// TODO: Obtain this from the webservice
export const ACTIVE_INTERNAL_FEE_ADDRESS = '0xDA9CEAFAAafd3016771D799c377A067F5DbF6632';

// Current and past SelfTrust fee addresses.
export const POSIBLE_INTERNAL_FEE_ADDRESSES = [
  ACTIVE_INTERNAL_FEE_ADDRESS,
  '0x6Ae35492ec5096763E2918c65779e0238df14021'
];

// Infura Http URL
export const INFURA_URL = INFURA_MAINNET;

// Infura Websocket URL
export const INFURA_WSS_URL = INFURA_WSS_MAINNET;

// Confirmation threshold
export const CONFIRMATION_THRESHOLD = {
  BTC: 3,
  ETH: 12
}

// Defining QR code sizes
export const QR_CODE_SIZES = {
  DESKTOP: 230,
  MOBILE: 180
}

// Defining QR code types
 export const QRCodesTypes = {
   TYPE_UNSIGNED_TX: 0,
   TYPE_ADDRESS_VERIFICATION: 1,
   TYPE_TRANSFER_PROPOSAL: 2,
   TYPE_TRANSFER_CODE: 3,
   TYPE_CANCEL_TRANSFER: 4,
   TYPE_EDIT_TRANSFER: 5,
   TYPE_RENEW_TRANSFER: 6
 }

// Defines the most up to date APK url.
export const APK_URL = 'https://play.google.com/apps/testing/me.selftrust.offline';

// URL of Github releases
export const GITHUB_APK_URL = 'https://github.com/franckn5/APK-SignTx/releases';

// Default app timeout value
export const APP_TIMEOUT_VALUE = 10 * 60 * 1000;

// Key used to store the current state of the main screen's Alert component
export const KEY_HAS_ALERT_BEEN_DISMISSED = 'key_has_alert_been_dismissed';

// Key used to store the wallet's id
export const KEY_WALLET_ID = 'key.wallet_id';

// Fee rate expressed in sat/byte
export const DEFAULT_TRANSMISSION_FEE_RATE = 1; //TODO: Dynamically obtain the right sat/byte value
