import React, { Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

const styles = theme => ({
  paragraphContainer: {
    height: '80vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  buttonContainer: {
    height: '10vh',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  paragraph: {
    marginTop: '10px',
    marginBottom: '10px'
  },
  '@media (max-width: 400px)': {
    button: {
      width: '80%',
      height: 'fit-content'
    }
  },
  '@media (min-width: 400px)': {
    button: {
      width: '30%',
      height: 'fit-content',
      margin: theme.spacing(1)
    }
  }
})

const noTransmission = props => {
  const { classes } = props;
  return (
    <Fragment>
      <div className={classes.paragraphContainer}>
        <Typography className={classes.paragraph} color="primary" component="p">
          No Transmission.
        </Typography>
        <Typography className={classes.paragraph} color="primary" component="p">
          Click START to transmit your wallet.
        </Typography>
      </div>
      <div className={classes.buttonContainer}>
        <Button
          variant="contained"
          color="primary"
          className={classes.button} onClick={props.onStartClicked}>
          Start
        </Button>
      </div>
    </Fragment>
  )
}

export default withStyles(styles)(noTransmission);
