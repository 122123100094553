import React from 'react';
import AccessTime from '@material-ui/icons/AccessTime';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Authentication from '../../../../components/Authentication/Authentication';
import dateFormat from 'dateformat';
import STService from '../../../../network/axios-verifier';
import { withStyles } from '@material-ui/core/styles';
import { QRCodesTypes } from '../../../../constants';

const styles = theme => ({
  icon: {
    color: theme.palette.primary.main,
    width: '100%',
    marginTop: '0.5em',
    marginBottom: '0.5em'
  },
  text: {
    color: theme.palette.primary.main,
    margin: '1em'
  },
  dataContainer: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'left',
    alignItems: 'flex-start',
    margin: '10%'
  },
  buttonContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-around',
    padding: theme.spacing(2)
  },
  normalText: {
    color: 'green'
  },
  warningText: {
    color: 'red'
  },
  '@media (max-width: 400px)': {
    button: {
      width: '30%',
      margin: theme.spacing(1)
    }
  },
  '@media (min-width: 400px)': {
    button: {
      width: '20%',
      margin: theme.spacing(1)
    }
  }
});

const MONTH_IN_MS = 1000 * 60 * 60 * 24 * 30;
const DAY_IN_MS = 1000 * 60 * 60 * 24;

class TransmissionDetails extends React.Component {

  state = {
    challenge: null,
    type: -1
  }

  handleCancel = () => {
    this.props.onCancelClicked();
  }

  handleEdit = () => {
    this.props.onEditClicked();
  }

  handleRenew = () => {
    this.props.onRenewClicked();
  }

  render(){
    const { classes, transferData } = this.props;
    if(this.state.challenge){
      return (
        <Authentication
          extraData={transferData}
          onChallengeSigned={this.onChallengeSigned}
          challenge={{...this.state}}/>
      )
    }else{
      const expirationDate = new Date(transferData.dueDate);
      const now = new Date();
      const remainingTime = expirationDate - now;
      if(remainingTime > 0){
        const remainingMonths = Math.floor(remainingTime / MONTH_IN_MS);
        const remainingDays = Math.floor((remainingTime - remainingMonths * MONTH_IN_MS) / DAY_IN_MS);
        let timeLeftStyle = classes.normalText;
        if(remainingMonths < 1) timeLeftStyle = classes.warningText;
        return (
          <div className={classes.container}>
            <Typography color="inherit" variant="h5" component="h3" className={classes.text}>
              <strong>Transmission Scheduled</strong>
            </Typography>
            <AccessTime className={classes.icon}/>
            <Typography className={classes.text}>
              {dateFormat(expirationDate, '"On" mmm d yyyy "at" h:MM TT')}
            </Typography>
            <Typography color="inherit" component="p" className={timeLeftStyle}>
              {remainingMonths} months, {remainingDays} days left
            </Typography>
            <Typography className={classes.text}>
              Transmit my wallet to:
            </Typography>
            <Typography className={classes.text}>
              {transferData.heir.email}
            </Typography>
            <Typography className={classes.text}>
              If I do not renew or cancel this transmission by then—when contacted at:
            </Typography>
            <Typography className={classes.text}>
              {transferData.testator.email}
            </Typography>
            <div className={classes.buttonContainer}>
              <Button
                variant="contained"
                color="primary"
                onClick={this.handleEdit}
                className={classes.button}>
                Edit
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={this.handleRenew}
                className={classes.button}>
                Renew
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={this.handleCancel}
                className={classes.button}>
                Cancel
              </Button>
            </div>
          </div>
        )
      }else{
        return (
          <div className={classes.container}>
            <Typography className={classes.text}>
              This wallet was already transmitted.
            </Typography>
            <Typography className={classes.text}>
              For security reasons, we recommend you migrate your funds to a new SelfTrust wallet.
            </Typography>
            <div className={classes.buttonContainer}>
              <Button
                color="primary"
                onClick={this.handleCancel}
                className={classes.button}>
                Cancel
              </Button>
            </div>
          </div>
        )
      }
    }
  }
}

export default withStyles(styles)(TransmissionDetails);
