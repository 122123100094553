import React from 'react';
import PropTypes from 'prop-types';
import '../BroadcastResult.css';

const errorCross = props => {
  return (
    <div className="f-modal-alert modal-error">
      <div className="f-modal-icon f-modal-error animate">
          <span className="f-modal-x-mark">
              <span className="f-modal-line f-modal-left animateXLeft"></span>
              <span className="f-modal-line f-modal-right animateXRight"></span>
          </span>
        <div className="f-modal-placeholder"></div>
        <div className="f-modal-fix"></div>
      </div>
      <h2>Error</h2>
      <p>{props.message}</p>
    </div>
  )
}

errorCross.propTypes = {
  message: PropTypes.string.isRequired
}

export default errorCross;
