import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import 'cryptocoins-icons/webfont/cryptocoins.css';
import Erc20Wallet from '../../../../js/erc20';
import { withStyles } from '@material-ui/core/styles';
import './TxHistory.css';

const styles = theme => ({
  itemRoot: {
    width: '100%',
    height: '52px',
    display: 'flex',
    justifyContent: 'space-between'
  },
  logo: {
    width: '50px%'
  },
  itemBody: {
    width: '60%',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: '1'
  },
  date: {
    width: '20%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    lineHeight: '1em'
  }
});

const ellipsize = text => {
  return text.substring(0, 7)
    + '...' + text.substring(text.length - 5);
}

// Return Transaction list component
const txHistory = props => {
  const { classes } = props;
  const txArray = [];
  const tokenMap = Erc20Wallet.getTokenLogos();
  if(props.transactions){
    const tmpArray = props.transactions.map((tx, index) => {
      const date = tx.time !== 0 ? new Date(tx.time * 1000) : new Date();
      const monthOption = {month: 'long'};
      const dayOption = {day: 'numeric'};
      const yearOption = {year: 'numeric'};
      const precision = 8;
      const threshold = tx.name === 'btc' ? 3 : 12;
      let cryptoValue = tx.name === 'btc' ? parseFloat(tx.value) / 1e8 : parseFloat(tx.value) / 1e18;
      let coinLogo = null;
      if(tx.name === 'btc' || tx.name === 'eth'){
        // BTC and ETH use just the cryptocoins.css
        coinLogo = <i className={`historyCC cc ${tx.name.toUpperCase()}`} title={tx.name}></i>
      }else{
        // For ERC-20 tokens we refer to the SVG resources loaded from eth-contract-metadata
        let token = tokenMap.get(tx.name.toUpperCase());
        // In case we couldn't find a match, we just asign this token the default test logo
        if(!token) {
          console.warn('Token returned is null, using default logo for unknown tokens');
          token = {logo: 'test.svg'};
        }
        const logoImagePath = require('../../../../assets/img/tokens/' + token.logo);
        coinLogo = <i className='historyCC cc'><img src={logoImagePath}/></i>
      }
      // Setting a positive sign, negative sign, or empty space before the amount
      let sign = tx.isIncoming ? '+' : '-';
      let txStatus = 'Confirmed';
      if(tx.confirmations < threshold){
        txStatus = `Pending - [${tx.confirmations} / ${threshold}]`;
      }
      if(cryptoValue === 0) sign = <>&nbsp;</>;
      if(cryptoValue < Math.pow(10, -precision)){
        // If the value is so low that it would be rendered as zero, we want to
        // display it as the lowest value we can with the current UI precision
        cryptoValue = Math.pow(10, -precision);
      }
      return (
        <ListItem className="mdl-list__item mdl-list__item--three-line"
          key={index}
          onClick={() => props.onTxClicked ? props.onTxClicked(index) : null}
          button>
          <div className={classes.itemRoot}>
            <div className={classes.logo}>{coinLogo}</div>
            <div className={classes.itemBody}>
              <span className="mdc-list-item__primary-text">{sign}{cryptoValue.toFixed(precision)}</span>
              <span className="mdl-list__item-text-body">
                {txStatus}
              </span>
              <Typography variant="caption" style={{color:'gray'}}>{ellipsize(tx.hash)}</Typography>
            </div>
            <div className={classes.date}>
              <span className="mdc-list-m">{date.toLocaleDateString(date, monthOption)}</span>
              <span className="mdc-list-d">{date.toLocaleDateString(date, dayOption)}</span>
              <span className="mdc-list-y">{date.toLocaleDateString(date, yearOption)}</span>
            </div>
          </div>
        </ListItem>
      )
    });
    txArray.push(...tmpArray);
  }
  return (
    <div id="tab-2" className="tab-content">
    <ul className="demo-list-three mdl-list">
      {txArray}
    </ul>
    </div>
  );
}
export default withStyles(styles)(txHistory);
