import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import FileCopy from '@material-ui/icons/FileCopy';
import { PulseLoader } from 'react-spinners';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const copyAddress = props => {
  const loader = (<PulseLoader sizeUnit={"px"} size={8} loading={true} color={'#c3c3c3'}/>);
  return (
    <div className="address-tag">
      <div className="address-item">
        {props.address ? props.address : loader}
      </div>
      <div className="copy-item">
        <CopyToClipboard text={props.address ? props.address : ''} onCopy={props.copyHandler}>
          <IconButton size="small">
            <FileCopy/>
          </IconButton>
        </CopyToClipboard>
      </div>
    </div>
  )
}

copyAddress.propTypes = {
  address: PropTypes.string,
  copyHandler: PropTypes.func
}

export default copyAddress;
