import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  selfTrustButton: {
    margin: theme.spacing(0.5),
    fontSize: '11px',
    background: 'transparent',
    fontWeight: 'bold',
    '&:hover': {
      background: theme.palette.primary.main,
      color: theme.palette.primary.contrastText
    },
    '&:focus': {
      outline: 'none',
      boxShadow: `0 0 0 3px ${theme.palette.primary.light}`
    }
  },
  [theme.breakpoints.up('md')]: {
    selfTrustButton: {
      borderRadius: theme.spacing(12),
      minWidth: theme.spacing(25)
    }
  },
  [theme.breakpoints.down('md')]: {
    selfTrustButton: {
      borderRadius: theme.spacing(6),
      minWidth: theme.spacing(18)
    }
  }
})

const selfTrustButton = props => {
  const {
    classes,
    children,
    startIcon,
    disabled,
    onClick } = props;
  return (
    <Button
      disabled={disabled !== undefined ? disabled : false}
      variant="contained"
      startIcon={startIcon}
      onClick={() => onClick()}
      className={classes.selfTrustButton}>
      {children.toUpperCase()}
    </Button>
  )
}

export default withStyles(styles)(selfTrustButton);
