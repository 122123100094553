import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import bitcoin from 'bitcoinjs-lib';
import QRCodeScanner from '../../../../../components/QRCodeScanner/QRCodeScanner';
import STService from '../../../../../network/axios-verifier';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5)
  },
  progressContainer: {
    textAlign: 'center',
    margin: '30%'
  },
  progress: {
    margin: theme.spacing(2),
  }
});

class ScanStep extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      scannedData: null,
      broadcasted: false,
      success: null,
      txHash: null,
      transmissionData: null
    }
  }

  /**
  * Callback function to be executed when all QR code segments have been read
  * and assembled by the QRCodeScanner component.
  */
  handleQRCodeData = codeData => {
    // Adding the transmission type attribute to the QR code data
    const payload = JSON.parse(codeData);
    const tx = bitcoin.Transaction.fromHex(payload.tx);
    payload.transmissionType = this.props.transmissionType;
    STService.patch(`/transmission/${this.props.walletId}?renew=true`, payload, {headers: {'content-type':'application/json'}})
      .then(resp => {
        if(resp.status === 200){
          this.props.onTransmissionRenewed(resp.data);
        }else{
          this.setState({broadcasted: true, success: false});
        }
      })
      .catch(error => {
        console.error('Error while sending transmission package to the server. Error: ', error)
        this.setState({broadcasted: true, success: false});
      });
    this.setState({scannedData: codeData});
  }

  render(){
    const { classes } = this.props;
    if(this.state.scannedData){
      if(this.state.broadcasted){
        if(this.state.success){
          const { dueDate, heir } = this.state.transmissionData;
          const expirationDate = new Date(dueDate);
          return <p>Transmission renewed!</p>
        }else{
          return <h1>Error after sending transmission data to the server</h1>
        }
      }else{
        return (
          <div className={classes.progressContainer}>
            <Typography variant='subtitle1'>Please wait</Typography>
            <CircularProgress className={classes.progress} />
          </div>
        )
      }
    }else{
      return <QRCodeScanner
        onResult={this.handleQRCodeData}
        message='Scan the QR codes from the SelfTrust Offline app.'/>
    }
  }
}

export default withStyles(styles)(ScanStep);
