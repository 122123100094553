import React, { Component } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TransmissionSetup from './TransmissionSetup/TransmissionSetup';
import TransmissionDetails from './TransmissionDetails/TransmissionDetails';
import NoTransmission from './NoTransmission/NoTransmission';
import TransmissionChange from './TransmissionChange/TransmissionChange';
import TransmissionRenewal from './TransmissionRenewal/TransmissionRenewal';
import Authentication from '../../../components/Authentication/Authentication';
import { QRCodesTypes } from '../../../constants';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from "react-router";
import STService from '../../../network/axios-verifier';
import { KEY_WALLET_ID } from '../../../constants';
import hash from 'object-hash';
import NoFunds from './NoFunds/NoFunds';

const SCREEN_NO_TRANSMISSION = 0;
const SCREEN_TRANSMISSION_DETAILS = 1;
const SCREEN_REQUESTING_AUTH = 2;
const SCREEN_SETUP = 3;
const SCREEN_CHANGE = 4;
const SCREEN_RENEW = 5;

const styles = theme => ({
  container: {
    height: 'calc(100vh - 120px)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  authenticationContainer: {
    [theme.breakpoints.down('sm')]: {
      width: '90%'
    },
    [theme.breakpoints.up('sm')]: {
      width: '50%'
    },
    [theme.breakpoints.up('md')]: {
      width: '30%'
    }
  },
  '@media (max-width: 400px)': {
    button: {
      width: '80%'
    }
  },
  '@media (min-width: 400px)': {
    button: {
      width: '30%',
      margin: theme.spacing(1)
    }
  }
})

/**
* Component used to display the current transmission state of this wallet.
*/
class Transmission extends Component {

  state = {
    currentScreen: SCREEN_NO_TRANSMISSION,
    transferData: null,
    initialized: false,
    isSettingUp: false,
    isResumed: false,
    edit: false,
    renew: false,
    signature: null,
    challenge: null,
    type: null
  }

  onBackClicked = () => {
    const { currentScreen } = this.state;
    switch(currentScreen) {
      case SCREEN_TRANSMISSION_DETAILS:
      case SCREEN_NO_TRANSMISSION:
        this.props.history.replace('/main/settings');
        break;
      case SCREEN_REQUESTING_AUTH:
      case SCREEN_RENEW:
      case SCREEN_CHANGE:
        this.setState({
          currentScreen: SCREEN_TRANSMISSION_DETAILS,
          signature: null,
          type: null
        });
        break;
      case SCREEN_SETUP:
        let newScreen = SCREEN_TRANSMISSION_DETAILS;
        const { isSettingUp } = this.state;
        if(isSettingUp){
          this.props.history.replace('/main/settings');
        }else{
          let { id } = this.props;
          this.refreshData(id);
        }
    }
  }

  componentDidMount(){
    let { id } = this.props;
    // If the wallet id was not passed via props, we retrieve it from the local storage
    if(!id) id = localStorage.getItem(KEY_WALLET_ID);
    this.refreshData(id);
  }

  refreshData = id => {
    // TODO: Investigate why sometimes this id is null. Hint: It happens usually after a wipeout.
    if(!id) {
      console.log('Fetching wallet id from local storage');
      id = localStorage.getItem(KEY_WALLET_ID);
    }
    console.log('refreshData. id: ', id);
    STService.get('/transmission/' + id)
      .then(resp => {
        if(resp.status === 200){
          if(resp.data && resp.data.heir)
            this.handleTransmissionData(resp.data);
          else
            console.warn('Got no transmission data');
        }else{
          this.handleNetworkError(resp);
        }
      })
      .catch(error => this.handleNetworkError(error));
  }

  handleTransmissionData = transmissionDetails => {
    let screen = SCREEN_TRANSMISSION_DETAILS;
    if(transmissionDetails && !transmissionDetails.complete){
      // In case we have an incomplete transmission set up
      screen = SCREEN_SETUP;
    }
    this.setState({
      initialized: true,
      currentScreen : screen,
      transferData: transmissionDetails,
      isSettingUp: !transmissionDetails.complete,
      isResumed: true,
      edit: false
    });
  }

  handleNetworkError = error => {
    console.warn('Got error while trying to retrieve transmission data.', error);
    this.setState({initialized: true});
  }

  onStartClicked = event => {
    this.setState({
      currentScreen: SCREEN_SETUP,
      isSettingUp: true
    });
  }

  shouldComponentUpdate(nextProps, nextState){
    const { props } = this;
    const stateChanged = hash(this.state) !== hash(nextState);
    const walletIdChanged = props.walletId !== nextProps.walletId;
    const utxoSetChanged = hash(props.utxos) !== hash(nextProps.utxos);
    const changeAddressChanged = props.changeAddress !== nextProps.changeAddress;
    const currentPrice = props.coins.btc ? (props.coins.btc.price ? props.coins.btc.price : 0) : 0;
    const nextPrice = nextProps.coins.btc ? (nextProps.coins.btc.price ? nextProps.coins.btc.price : 0) : 0;
    const priceChanged = currentPrice !== nextPrice;
    return stateChanged || walletIdChanged || utxoSetChanged || priceChanged;
  }

  /**
  * Function called once the cancellation procedure is over and we have
  * confirmation that a transmission was cancelled.
  */
  onTransmissionCancelled = () => {
    this.setState({
      currentScreen: SCREEN_NO_TRANSMISSION,
      transferData: null,
      isSettingUp: false,
      isResumed: false,
      edit: false,
      signature: null,
      challenge: '',
      type: null
    });
  }

  onEditClicked = () => {
    STService.post('/challenges', {}).then(resp => {
      if(resp.status === 201){
        const { data } = resp;
        this.setState({
          currentScreen: SCREEN_REQUESTING_AUTH,
          challenge: data,
          type: QRCodesTypes.TYPE_EDIT_TRANSFER
        });
      }
    })
    .catch(error => {
      alert('Network error while trying to send a POST request to start the edit procedure. error: ', error);
    });
  }

  /**
  * Function called once the user has requested a transmission cancellation.
  * The only action performed here is to change the toolbar message.
  */
  onCancelClicked = () => {
    STService.post('/challenges').then(resp => {
      if(resp.status === 201){
        const { data } = resp;
        this.setState({
          currentScreen: SCREEN_REQUESTING_AUTH,
          challenge: data,
          type: QRCodesTypes.TYPE_CANCEL_TRANSFER
        });
      }
    })
    .catch(error => {
      alert('Network error while trying to send DELETE request.');
    });
  }

  onRenewClicked = () => {
    this.setState({
      currentScreen: SCREEN_RENEW,
      renew: true
    });
  }

  onEditChallenge = (challenge, r, s) => {
    this.setState({
      currentScreen: SCREEN_CHANGE,
      edit: true,
      challenge: challenge,
      signature: {r: r, s: s}
    });
  }

  onTransmissionChanged = () => {
    this.refreshData(this.props.walletId);
  }

  onTransmissionRenewed = transferData => {
    this.setState({
      currentScreen: SCREEN_TRANSMISSION_DETAILS,
      transferData: transferData,
      renew: false
    });
  }

  componentWillUpdate(){
    const { currentScreen, transferData } = this.state;
    if(currentScreen === SCREEN_TRANSMISSION_DETAILS && transferData === null){
      this.refreshData(this.props.walletId);
    }
  }


    /**
    * Signed challenge payload
    * @typedef {Object} SignedChallenge
    * @property {string} challenge - The original challenge.
    * @property {string} r - The R component of the EC digital signature.
    * @property {string} s - The S component of the EC digital signature.
    */

    /**
    * Function called whenever the Authentication scanner component scans a
    * signed challenge.
    *
    * @param {number} type - The type of challenge signed.
    * @param {SignedChallenge} data - The signed challenge.
    */
    onChallengeSigned = (type, data) => {
      switch(type){
        case QRCodesTypes.TYPE_CANCEL_TRANSFER:
          console.log('About to send a transmission cancellation');
          const URL = `/transmission/${this.props.walletId}?c=${data.challenge}&r=${data.r}&s=${data.s}`;
          STService.delete(URL).then(resp => {
            if(resp.status === 200){
              this.onTransmissionCancelled();
            }else{
              console.error('DELETE call returned non-ok result');
              this.setState({challenge: null, type: -1});
            }
          })
          .catch(error => {
            console.error('Network error while trying to send authenticated DELETE request');
            this.setState({challenge: null, type: -1});
          })
          break;
        case QRCodesTypes.TYPE_EDIT_TRANSFER:
          STService.get(`/transmission/${this.props.walletId}`)
            .then(resp => {
              const { challenge, r, s } = data;
              this.onEditChallenge(challenge, r, s);
            })
            .catch(error => {
              console.error('Network error while trying to send an authenticated GET request');
              this.setState({challenge: null, type: -1});
            })
          break;
      }
    }

  render() {
    const { classes, coins } = this.props;
    const { transferData, edit, renew, currentScreen, initialized } = this.state;
    const btcPrice = coins.btc ? (coins.btc.price ? coins.btc.price : 0) : 0;
    let mainContent = null;
    if(!initialized){
      mainContent = <p></p>
    }else if(currentScreen === SCREEN_NO_TRANSMISSION){
      mainContent = (
        <div className={classes.container}>
          <NoTransmission onStartClicked={this.onStartClicked}/>
        </div>
      )
    }else if(currentScreen === SCREEN_REQUESTING_AUTH){
      const { challenge, type } = this.state;
      mainContent = (
        <div className={classes.container}>
          <div className={classes.authenticationContainer}>
            <Authentication
              message='From the SelfTrust Offline app, please scan these QR codes to authenticate your request.'
              extraData={transferData}
              onChallengeSigned={this.onChallengeSigned}
              challenge={{challenge: challenge, type: type}}/>
          </div>
        </div>
      )
    }else if(currentScreen === SCREEN_SETUP){
      if(this.props.utxos.length > 0){
        mainContent = (
          <TransmissionSetup
            test={this.props.test}
            isResumed={this.state.isResumed}
            transferData={transferData}
            btcPrice={btcPrice}
            walletId={this.props.walletId}
            utxos={this.props.utxos}
            changeAddress={this.props.changeAddress}/>
        );
      }else{
        mainContent = <NoFunds/>
      }
    }else if(currentScreen === SCREEN_CHANGE){
      mainContent = (
        <TransmissionChange
          onTransmissionChanged={this.onTransmissionChanged}
          walletId={this.props.walletId}
          challenge={this.state.challenge}
          signature={this.state.signature}
        />
      )
    }else if(currentScreen === SCREEN_RENEW){
      mainContent = (
        <TransmissionRenewal
          transferData={transferData}
          btcPrice={btcPrice}
          utxos={this.props.utxos}
          walletId={this.props.walletId}
          onTransmissionRenewed={this.onTransmissionRenewed}
          changeAddress={this.props.changeAddress}
        />
      )
    }else if(currentScreen === SCREEN_TRANSMISSION_DETAILS){
      if(transferData){
        mainContent = (
          <div className={classes.container}>
            <TransmissionDetails
              walletId={this.props.walletId}
              transferData={transferData}
              onEditClicked={this.onEditClicked}
              onRenewClicked={this.onRenewClicked}
              onCancelClicked={this.onCancelClicked}/>
          </div>
        )
      }else{
        mainContent = <p>Loading..</p>
      }
    }
    return (
      <React.Fragment>
        <AppBar color="primary" position="static">
          <Toolbar>
            <IconButton className="btn-back-toolbar" onClick={this.onBackClicked}>
              <i style={{color: 'white' }} className="fas fa-arrow-left"></i>
            </IconButton>
            <Typography variant='h5' align='left' style={{color:'white'}}>Transmission</Typography>
          </Toolbar>
        </AppBar>
        {mainContent}
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(withRouter(Transmission));
