import React from 'react';
import ReactDOM from 'react-dom';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import SelfTrustTheme from './themes/selftrust.json';
import 'console-remote-client';

// Checking for dev or production environment
if(process.env.NODE_ENV !== 'development'){
  // If we are in test-production, at least while testing, we want to enable
  // remote console capabilities. For this we use the console-remote-client
  // dependency. For more see: http://console.re

  // This line sets up the channel name
  console.re.settings({
    channel: 'selftrust-test-logs'
  });
  // // We need to disable the 'client mode' in order to prevent an infinite loop.
  console.re.client = false;
  // // Finally, we replace all console methods for console.re.[log,error,warn] functions.
  console.log = function(){
    console.re.log(...arguments);
  }
  console.error = function(){
    console.re.error(...arguments);
  }
  console.warn = function(){
    console.re.warn(...arguments);
  }
}

const theme = createMuiTheme(SelfTrustTheme);

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <App />
  </MuiThemeProvider>
, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
