import { CORS_PROXY_URL } from './proxy';
import { ETHERSCAN_API_KEY } from '../constants';

const axios = require('axios');

const ETHERSCAN_API_URL = 'https://api.etherscan.io';

const PROXIED_ETHERSCAN_API_URL = CORS_PROXY_URL + '/' + ETHERSCAN_API_URL;

const instance = axios.create({
  baseURL: PROXIED_ETHERSCAN_API_URL,
  headers: {'x-requested-with':'XMLHttpRequest'}
});

/**
* Object that implements all required API calls.
*/
const EtherscanApi = {
  /**
  * Function used to obtain a list of transactions for a given Ethereum account.
  *
  * @param {string} addr - The account address.
  * @param {number} start - The starting block.
  * @param {number} end - The ending block.
  * @param {number} page - Page number, starts with 1.
  * @param {number} offset - The length of each page.
  */
  getTransactions: (addr, start, end, page, offset) => {
    return instance.get('/api', {
      params: {
        module: 'account',
        action: 'txlist',
        address: addr,
        startBlock: start,
        endBlock: end,
        sort: 'asc',
        page: page,
        offset: offset,
        apiKey: ETHERSCAN_API_KEY
      }
    })
  }
}

export default EtherscanApi;
