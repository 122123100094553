import React, {Component} from 'react';
import FeeSelector from './FeeSelector/FeeSelector';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import FeeDetailsModal from '../../../../../../components/FeeDetailsModal/FeeDetailsModal';
import CryptoInput from '../../../../../../components/CryptoInput/CryptoInput';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import axiosVerifier from '../../../../../../network/axios-verifier';
import bitcoin from 'bitcoinjs-lib';
import hash from 'object-hash';
import BigNumber from 'bignumber.js';
import TxBuilderCommon from '../TxBuilderCommon';
import { INTERNAL_FEE_ADDRESS } from '../../../../../../constants';

BigNumber.set({DECIMAL_PLACES: 8});

// The minimum number of UTXOs we have to have available in order to kickstart
// the UTXO consolidation logic.
const MIN_CONSOLIDATION_THRESHOLD = 3;

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5)
  }
});

// TODO: Obtain these values dynamically
const MIN_BTC_TRANSFER = 0.00000800

// Function used to check whether a given string is an address
function checkAddress(address){
    var result = {
        error: undefined,
        isBase58: undefined,
        isBech32: undefined
    }
    try{
        bitcoin.address.fromBase58Check(address);
        result.isBase58 = true;
    }catch(error){
        result.isBase58 = false;
    }
    try{
        bitcoin.address.fromBech32(address);
        result.isBech32 = true;
    }catch(error){
        result.isBech32 = false;
    }
    if(!result.isBase58 && !result.isBech32){
        result.error = 'Not a valid address';
    }
    return result;
}


class BitcoinTxBuilder extends Component {
  constructor(props){
    super(props);
    this.state = {
      destination: {value: '', valid: false},
      amount: {value: '', valid: false},
      blocks: undefined,
      outputs: {
        destination: 0,
        internalFee: 0,
        minerFee: 0
      },
      insufficientBalance: false,
      missingInternalFee: false,
      feeDetailsVisible: false,
      clickCounter: props.clickCounter,
      displayError: false,
      verificationError: '',
      internalFeeParams: null
    }

    // Map between user-selected confirmation target (in blocks) and the
    // actual BTC fee value.
    this.feeMap = new Map();

    this.shouldUpdate = false;

    // Used to discard an execution line that was delayed because we had
    // to check with the verification server and which might not be valid anymore
    this.checkCounter = 0;
  }

  /**
  * Function that will specifically test that a value will not be considered
  * dust by the network.
  */
  verifyNonDust = value => {
    const floatValue = parseFloat(value);
    return floatValue >= MIN_BTC_TRANSFER;
  }

  handleDestinationChange = event => {
    let typedValue = event.target.value;
    const addressAnalysis = checkAddress(typedValue);
    // Currently we only support base58, but we could also allow for bech32 here
    const isValid = addressAnalysis.isBase58;
    this.setState({destination: {value: typedValue, valid: isValid}});
  }

  handleAmountChange = event => {
    // Skipping mouse scroll events on desktop
    if(event.nativeEvent.inputType === undefined && !isMobile) return;
    const typedValue = event.target.value;
    const value = +typedValue;
    const isDust = !this.verifyNonDust(typedValue);
    const stateClone = Object.assign({}, this.state);
    const { price } = this.props.coin;
    const { internalFeeParams } = this.state;
    stateClone.outputs.internalFee = TxBuilderCommon.calculateInternalFee(value, price, 8, internalFeeParams);
    if(isDust && value > 0){
      // In case the user entered a dust value, we must display an error
      stateClone.displayError = true;
      stateClone.verificationError = 'Transfer amount must be higher';
    }else{
      // The entered value is not dust
      stateClone.outputs.destination = value;
    }
    if(this.state.blocks){
      // If the miner fee has already been selected, we have to recalculate it
      const minerFee = this.calculateMinerFee(value + this.state.outputs.internalFee, this.state.blocks);
      const hasEnough = this.hasEnough(typedValue, minerFee / 1e8);
      // The 'amount' field is not valid if a dust or we don't have enough
      stateClone.amount = {value: typedValue, valid: (!isDust && hasEnough)};
      stateClone.outputs.minerFee = parseInt(minerFee) / 1e8;
      stateClone.insufficientBalance = !hasEnough;
      this.shouldUpdate = true;
      this.setState(stateClone);
    }else{
      // The 'amount' field is not valid if a dust
      stateClone.amount = {value: typedValue, valid: !isDust};
      // If the miner fee has not yet been selected, we just update the state here.
      this.setState(stateClone);
    }
  }

  getLargestConfirmationTarget = () => {
    let targetBlocks = 1;
    const it = this.feeMap.keys();
    let result = it.next();
    while(!result.done){
      result = it.next();
      if(result.value > targetBlocks) targetBlocks = result.value;
    }
    return targetBlocks;
  }

  /**
  * Function used to calculate the required miner fee. It returns either the real
  * fee that will be paid or the value of the fee that would be required in order
  * to have a transaction confirm in the number of blocks specified by the
  * 'targetBlocks' argument.
  * @param {number} value - The value, in bitcoin, that the user wants to send.
  * @param {number} targetBlocks - The number in blocks that the user wants this transaction to confirm.
  * @returns {number} The required fee in satoshis.
  */
  calculateMinerFee = (value, targetBlocks) => {
    let hasEnough = false;
    let satsRequired = parseInt(value * 1e8);
    let realFee = 0;
    let safeIndex = 0;
    let desiredFee = 226; // Desired fee, in satoshis. 226 is the minimum assuming a 1 input 2 output tx
    let inputs = [];

    while(!hasEnough && inputs.length < this.props.utxos.length && safeIndex < 10){
      inputs = this.selectInputsForMinerFee(satsRequired);
      const outputs = this.getOutputs(inputs, desiredFee);

      const blocks = targetBlocks !== undefined ? targetBlocks : this.getLargestConfirmationTarget();
      let feeBucketValue = this.feeMap.get(blocks);
      console.log(`[feeBucketValue: ${feeBucketValue}]`);
      // Obtaining the selected fee amount in sat/byte metric
      const satPerByte = Math.max(Math.abs((feeBucketValue * 1e8) / 1000.0), 1.0);
      // Calculating the size contributions of inputs & outputs
      const inputSize = inputs.length * 148;
      const outputSize = outputs.length * 34;
      // Calculating the estimated total size of the transaction
      const txSize = inputSize + outputSize + 11;
      // Finally obtaining the right fee value estimated in satoshis
      desiredFee = satPerByte * txSize;
      console.log(`[ins: ${inputs.length}, outs: ${outputs.length}, txSize: ${txSize}]`);
      // console.log(`[inputs: ${inputs.length}, outputs: ${outputs.length}, utxos: ${this.props.utxos.length}]`);
      // console.log(`[sat/byte: ${satPerByte}, estimated tx size: ${txSize}]`);
      // Calculating the REAL miner fee obtained by the input selection
      const inputSum = inputs.reduce((accum, current) => accum + current.value, 0);
      const outputSum = outputs.reduce((accum, current) => accum + current.amount, 0);
      realFee = inputSum - outputSum;
      // console.log(`[real fee: ${realFee}, desired fee: ${desiredFee}]`);
      if(realFee >= desiredFee){
        hasEnough = true;
      }else{
        // If we still don't have enough, we add the difference to the 'satsRequired' variable
        satsRequired = satsRequired + (desiredFee - realFee);
      }
      // Incrementing to avoid an infinite loop
      safeIndex++;
    }
    // console.log(`[Obtained fee: ${realFee}]`);
    if(hasEnough)
      return realFee;
    else
      return desiredFee;
  }

  /**
  * Object containing a proposed transaction.
  *
  * @typedef {Object} TxBundle
  * @property {boolean} hasInternal - Indicates whether this transaction will have an internal fee or not.
  * @property {Object[]} utxos - Array of selected UTXOs.
  * @property {string} destination - Destination address.
  * @property {Object} outputs - Object that contains the values for the usual 3 outputs.
  * @property {string} tx - Serialized version of the proposed transaction, expressed as an hex string.
  * @property {number[]} input - Array of numbers, that corresponds to every UTXO value.
  * @property {string} bucketLabel - The label to show with the selected fee level.
  */

  /**
  * Function used to generate a new {@link TxBundle}.
  */
  buildTransaction = () => {
    const inputs = this.selectInputs();
    console.log('inputs: ', inputs);
    const outputs = this.getOutputs(inputs);
    console.log('outputs: ', outputs);
    const txBuilder = new bitcoin.TransactionBuilder(bitcoin.networks.bitcoin);
    for(var i = 0; i < inputs.length; i++){
      var input = inputs[i];
      const hash = input.hash;
      const index = parseInt(input.index);
      const script = Buffer.from(input.script, 'hex');
      txBuilder.addInput(hash, index, 0xffffffff, script);
    }
    for(i = 0; i < outputs.length; i++){
        const output = outputs[i];
        txBuilder.addOutput(output.address, output.amount);
    }
    let feeBucketLabel = '';
    this.props.feeBuckets.forEach(bucket => {
      if(bucket.blocks === this.state.blocks){
        feeBucketLabel = bucket.label;
      }
    });
    return {
      hasInternal: this.state.outputs.internalFee > 0, // Whether or not this proposed tx will have an internal system fee
      utxos: inputs, // UTXOs selected for this transaction
      destination: this.state.destination.value, // Destination addresses
      outputs: this.state.outputs, // Break down of each output
      tx: txBuilder.buildIncomplete().toHex(), // Serialized transaction
      input: inputs.map((input) => input.value), // Input value array
      bucketLabel: feeBucketLabel // The selected fee bucket label
    };
  }

  /**
  * Function that will create an 'outputs' object, given a set of inputs and
  * optionally a desired miner fee.
  *
  * @param {Object[]} inputs - Selected inputs.
  * @param {number} [desiredFee] -  The desired miner fee, expressed in satoshis.
  */
  getOutputs = (inputs, desiredFee) => {
    let satInternalFee = parseInt(this.state.outputs.internalFee * 1e8);
    const satMinerFee = desiredFee ? desiredFee : parseInt(this.state.outputs.minerFee * 1e8);
    const satToSend = parseInt(this.state.outputs.destination * 1e8);
    const changeAmount = inputs.reduce((accum, input) => accum + input.value, 0) - satInternalFee - satToSend - satMinerFee;
    const MIN_SAT_TRANSFER = parseInt(MIN_BTC_TRANSFER * 1e8);

    const outputs = [];
    if(this.state.destination.value !== null
        && this.state.destination.value !== '')
      outputs.push({address: this.state.destination.value, amount: satToSend});
    if(satInternalFee > 0){
      outputs.push({address: INTERNAL_FEE_ADDRESS, amount: satInternalFee});
    }
    if(changeAmount > MIN_SAT_TRANSFER){
      // If the change output is below the 'dust' level, just avoid creating it
      outputs.push({address: this.props.changeaddr, amount: changeAmount});
    }
    return outputs;
  }

  /**
  * Function used to select inputs when we're trying to determine the miner fee.
  * @param {number} satsRequired - The required value, in satoshis.
  */
  selectInputsForMinerFee = (satsRequired) => {
    const sortedUtxos = this.props.utxos.sort((a,b) => b.value - a.value);
    const selectedUtxos = [];
    let sum = 0, index = 0;
    while(sum < satsRequired && index < sortedUtxos.length){
      selectedUtxos.push(sortedUtxos[index]);
      sum += sortedUtxos[index].value;
      index++;
    }
    return this.addConsolidation(sortedUtxos, selectedUtxos);
  }

  selectInputs = () => {
    const {destination, minerFee, internalFee} = this.state.outputs;
    const required = parseInt((destination + minerFee + internalFee) * 1e8);
    const sortedUtxos = this.props.utxos.sort((a,b) => b.value - a.value);
    const selectedUtxos = [];
    let sum = 0, index = 0;
    while(sum < required && index < sortedUtxos.length){
      selectedUtxos.push(sortedUtxos[index]);
      sum += sortedUtxos[index].value;
      index++;
    }
    return this.addConsolidation(sortedUtxos, selectedUtxos);
  }

  addConsolidation = (sortedUtxos, selectedUtxos) => {
    // Check whether to add inputs for on-going UTXO consolidation
    if(sortedUtxos.length > MIN_CONSOLIDATION_THRESHOLD && selectedUtxos.length === 1){
      const utxosToConsolidate = [];
      // Taking the last 2 UTXOs and consolidating them even if that is not required
      utxosToConsolidate.push(sortedUtxos[sortedUtxos.length - 1]);
      utxosToConsolidate.push(sortedUtxos[sortedUtxos.length - 2]);
      selectedUtxos.push(...utxosToConsolidate);
    }
    return selectedUtxos;
  }


  // Handles the user-selected fee bucket (denominated in block until confirmation)
  handleFeeSelection = (blocks) => {
    if(this.feeMap.has(blocks)){
      let feeBucketValue = this.feeMap.get(blocks);
      // if(blocks === 100){
      //   // Why? because Franck wants the last bucket be a third the value of the second to last
      //   feeBucketValue = Math.max(this.feeMap.get(48) / 3, 0.00001);
      //   console.log(`Overriding fee value. original: ${this.feeMap.get(blocks) * 1e8 / 1000.}, modified: ${feeBucketValue * 1e8 / 1000.}`);
      // }
      // Calculating the total fee in satoshis
      const {destination, internalFee} = this.state.outputs;
      const satFeeValue = this.calculateMinerFee(destination + internalFee, blocks);
      const hasEnough = this.hasEnough(this.state.amount.value, satFeeValue / 1e8);
      // Obtaining the selected fee amount in sat/byte metric
      const satPerByte = Math.max(Math.abs((feeBucketValue * 1e8) / 1000.0), 1.0);
      console.log(`[sat fee: ${parseInt(satFeeValue)}, sat/byte: ${satPerByte}]`);
      // Converting the satoshis value to BTC
      const feeValue = parseInt(satFeeValue) / 1e8;
      // Updating state
      let updatedOutputs = Object.assign({}, this.state.outputs);
      updatedOutputs.minerFee = feeValue;
      this.setState({
        insufficientBalance: !hasEnough,
        blocks: blocks,
        outputs: updatedOutputs
      })
    }else{
      console.error('Failed to obtain the fee value for the current selection. Blocks: ', blocks, ', Fee map: ', this.feeMap);
    }
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    // We should not check the form if the previous state had displayError set as true
    if(!prevState.displayError){
      this.checkForm();
    }
    // If we don't yet have the SelfTrust fee parameters, we should load them
    if(this.state.internalFeeParams === null){
      axiosVerifier.get('/parameters/fee')
        .then(response => {
          if(response.data && response.data.params){
            this.setState({internalFeeParams: response.data.params});
          }
        })
        .catch(error => {
          console.error('Got error while trying to fetch the SelfTrust fee parameters');
          this.setState({
            missingInternalFee: true,
            displayError: true,
            verificationError: 'Got error while trying to fetch the SelfTrust fee parameters',
            internalFeeParams: undefined
          });
        });
      // We set the 'internalFeeParams' to zero to indicate that a fetch procedure is already
      // underway. This prevents repeated API calls in case 'componentDidUpdate' is called
      // several times before the network call is fulfilled.
      this.setState({internalFeeParams: 0});
    }
  }

  checkForm = async () => {
    let txBundle = null;
    let isValid = false;
    // No need to check the form if we're currently displaying an error message
    if(!this.state.displayError){
      // Decide whether or not to allow the NEXT button to be enabled
      isValid = this.state.outputs.minerFee !== 0 &&
                      this.state.outputs.destination !== 0 &&
                      this.state.destination.valid &&
                      this.state.blocks &&
                      !this.state.insufficientBalance;
      // Incrementing the number of times this function has been called
      this.checkCounter++;
      if(isValid) {
        // Storing the check counter internally
        const internalRequestCounter = this.checkCounter;
        // If the form is valid, we must proceed to build a proposed transaction
        // and send it to the verification server
        txBundle = this.buildTransaction();
        try{
          const resp = await axiosVerifier.post('/verify', {tx: txBundle.tx, asset: 'btc'});
          if(internalRequestCounter !== this.checkCounter){
            // If this counters are not equal, it means that the user entered another
            // value before this network call could be finished and thus its result
            // is no longer relevant.
            console.warn('Discarding superceeded execution');
            return;
          }
          if(resp.data.r && resp.data.s){
            txBundle.signature = {r:resp.data.r, s: resp.data.s};
            txBundle.hash = hash(txBundle);
          }else {
            // In case we didn't get a signature, we received an error,
            // so we display its message as a snackbar
            console.error('Verification error: ', resp);
            this.setState({displayError: true, verificationError: resp.data.message});
          }
        }catch(error){
          console.error('Error while trying to verify proposed transaction.', error);
          this.setState({displayError: true, verificationError: 'Verification failed due to network error.'});
        }finally{
          this.performingRequest = false;
          txBundle.asset = 'btc';
        }
      }
    }
    this.props.handleStepResult(this.props.index, isValid, txBundle);
  }

  // Updates the fee map, which keeps track of the actual cost of each
  // block confirmation target.
  handleFeeMapUpdate = (feeMap) => {
    this.feeMap = feeMap;
    if(this.state.blocks !== 0){
      this.setState({
        'outputs.minerFee': this.feeMap.get(this.state.blocks)
      });
    }
  }

  hasEnough = (toSend, minerFee) => {
    toSend = isNaN(parseFloat(toSend)) ? 0 : parseFloat(toSend);
    // Specifying all our costs
    const satInternalFee = parseInt(this.state.outputs.internalFee * 1e8);
    const satMinerFee = parseInt(minerFee * 1e8);
    const satToSend = parseInt(toSend * 1e8);

    // Checking our current balance
    let availableSatBalance = 0;
    if(this.props.utxos && this.props.utxos.length > 0)
      availableSatBalance = this.props.utxos.map((utxo) => {return utxo.value})
                                            .reduce((accumulator, currentValue) => accumulator + currentValue);

    // Adding all the costs and checking if we would have enough funds
    // to build a transaction with the current configuration.
    const totalCost = satToSend + satInternalFee + satMinerFee
    const hasEnough = (availableSatBalance - totalCost) >= 0;
    return hasEnough;
  }

  static getDerivedStateFromProps(props, state){
    let result = null;
    if(props.clickCounter !== state.clickCounter){
      state.clickCounter = props.clickCounter;
      state.destination = {value: '', valid: false};
      state.amount = {value: '', valid: false};
      state.blocks = 0;
      state.outputs = { destination: 0, internalFee:0, minerFee: 0};
      result = state;
    }
    return result;
  }

  onSnackbarClosed = () => {
    setTimeout(() => {
      this.setState({displayError: false});
    }, 1000);
  }

  shouldComponentUpdate(nextProps, nextState){
    const propsChanged = hash(this.props) !== hash(nextProps);
    const stateChanged = hash(this.state) !== hash(nextState);;
    const update = propsChanged || stateChanged || this.shouldUpdate;
    this.shouldUpdate = false;
    return update;
  }

  render(){
    const { classes } = this.props;
    const enableFeeSelector = this.props.isloaded && this.hasEnough(this.state.amount.value, 0) && this.state.amount.valid;
    const validDestination = this.state.destination.valid;
    const minerFee = new BigNumber(this.state.outputs.minerFee * 1e8);
    const { price } = this.props.coin;

    const internalFee = new BigNumber(this.state.outputs.internalFee).multipliedBy(1e8);
    const toSend = new BigNumber(this.state.outputs.destination).multipliedBy(1e8);
    const totalSpent = minerFee.plus(internalFee).plus(toSend);
    const totalFees = minerFee.plus(internalFee);

    // Specifying output text
    const txtFees = `${totalFees.dividedBy(1e8).toFixed(8)}  (USD ${(totalFees.multipliedBy(price).dividedBy(1e8)).toFixed(2)})`;
    const txtTotal = `${totalSpent.dividedBy(1e8).toFixed(8)}  (USD ${(totalSpent.multipliedBy(price).dividedBy(1e8)).toFixed(2)})`;

    // If there was an error while obtaining the SelfTrust fee, display it to the user
    // A page reload will probably solve it.
    const missingInternalFee = this.state.missingInternalFee ? <p style={{color:'salmon'}}>Missing SelfTrust fee</p> : null;
    return (
      <React.Fragment>
        {missingInternalFee}
        <FeeDetailsModal
          visible={this.state.feeDetailsVisible}
          outputs={this.state.outputs}
          price={price}
          onDismiss={() => this.setState({feeDetailsVisible: false})}/>
        <form className={classes.container} noValidate autoComplete="off">
          <TextField
            id="outlined-address"
            label="Pay To"
            className={classes.textField}
            value={this.state.destination.value}
            onChange={this.handleDestinationChange}
            margin="normal"
            variant="outlined"
            error={!this.state.destination.valid && this.state.destination.value !== ''}
            disabled={!this.props.isloaded}
            fullWidth={true}
          />
          <CryptoInput
            id="outlined-amount"
            label="Amount"
            precision={8}
            className={classes.textField}
            value={this.state.amount.value}
            onChange={this.handleAmountChange}
            margin="normal"
            variant="outlined"
            fullWidth={true}
            type="text"
            error={(!this.state.amount.valid || this.state.insufficientBalance) && this.state.amount.value !== '' && this.state.outputs.destination !== 0}
            disabled={!this.state.destination.valid || this.state.destination === ''}
          />
          <FeeSelector
            handleFeeSelection={this.handleFeeSelection}
            handleFeeMapUpdate={this.handleFeeMapUpdate}
            enabled={enableFeeSelector}
            fee_buckets={this.props.feeBuckets}/>
          <TextField
            label="Fees"
            className={classes.textField}
            margin="normal"
            InputProps={{
              readOnly: true
            }}
            onClick={() => this.setState({feeDetailsVisible: true})}
            value={txtFees}
            fullWidth={true}
            variant="filled"
          />
          <TextField
            id="output-total-spent"
            label="Total Spent"
            className={classes.textField}
            margin="normal"
            InputProps={{
              readOnly: true,
            }}
            value={txtTotal}
            fullWidth={true}
            variant="filled"
            error={this.state.insufficientBalance}
          />
        </form>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          open={this.state.displayError}
          autoHideDuration={1500}
          onClose={this.onSnackbarClosed}
          message={<span id='message-id'>{this.state.verificationError}</span>}
          action={[
            <IconButton
              key='close'
              aria-label='Close'
              color='inherit'
              onClick={this.onSnackbarClosed}>
              <i style={{color: 'white' }} className="fas fa-times"></i>
            </IconButton>]
          }
        />
      </React.Fragment>
    )
  }
}

BitcoinTxBuilder.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(BitcoinTxBuilder);
