import React from 'react';
import QrReader from 'react-qr-reader';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { isIOS, isSafari } from 'react-device-detect';

const styles = theme => ({
  message: {
    textAlign: 'center',
    margin: '30px auto 30px',
    color: theme.palette.primary.main
  },
  scanRoot: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  [theme.breakpoints.down('sm')]:{
    scanContainer: {
      width: '90%'
    }
  },
  [theme.breakpoints.up('sm')]: {
    scanContainer: {
      width: '300px'
    }
  }
})

/**
* Component used to handle a multipart QR code as the ones generated by the
* ST Offline component.
*
* The expected multipart QR code format is the v1, or the one in which the
* first two bytes of the scanned payload contain respectively the segment
* index and the segment count.
*/
class QRCodeScanner extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      segments: [] // Will hold QR code segments
    }
  }

  handleError = (err) => {
    console.error(err);
  }

  handleScan = (data) => {
    if (data != null) {
      // Extract index, count and payload info from the data
      const segmentIndex = parseInt(data.slice(0,2));
      const segmentCount = parseInt(data.slice(2,4));
      if(isNaN(segmentIndex) || isNaN(segmentCount)) return;
      console.log(`segment index: ${segmentIndex}, segment count: ${segmentCount}`);
      const payload = data.slice(4);
      if(this.state.segments.length === 0){
        // This is the first of many QR codes to be read
        const segments = [ ...Array(segmentCount)].map(i => null);
        segments[segmentIndex - 1] = payload;
        this.setState({
          segments: segments
        })
      }else{
        // This is the Nth segment
        this.setState((prevState, currentProps) => {
          prevState.segments[segmentIndex - 1] = payload;
          return prevState;
        });
      }
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot){
    const { segments, broadcasted } = this.state;
    const doneScanning = segments.length > 0 && !segments.includes(null);
    if(doneScanning){
      const dataPayload = this.state.segments.join('');
      this.props.onResult(dataPayload);
    }
  }

  render(){
    const { classes } = this.props;
    let content = (
      <QrReader className={classes.scanContainer}
          delay={500}
          resolution={900}
          onError={this.handleError}
          onScan={(data) => this.handleScan(data)}
          />
    )
    if(isIOS && !isSafari){
      content = <h4 style={{color:'red'}}>On iOS only the Safari browser is supported, please switch to it.</h4>
    }
    return (
      <React.Fragment>
        <Typography className={classes.message}>{this.props.message}</Typography>
        <div className={classes.scanRoot}>
          {content}
        </div>
      </React.Fragment>
    )
  }
}

QRCodeScanner.propTypes = {
  onResult: PropTypes.func.isRequired,
  message: PropTypes.string
}

export default withStyles(styles)(QRCodeScanner);
