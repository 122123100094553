import { CORS_PROXY_URL } from './proxy';
const axios = require('axios');

// Full node URL
const BCOIN_PROD_DOMAIN = 'https://selftrust.me';
const BCOIN_DEV_DOMAIN = CORS_PROXY_URL + '/' + BCOIN_PROD_DOMAIN;

const instance = axios.create({
  baseURL: process.env.NODE_ENV === 'development' ? BCOIN_DEV_DOMAIN : BCOIN_PROD_DOMAIN,
  timeout: 30000,
  auth: {
    username: 'x',
    password: 'zERzylGw5TjZ8G3KtKwN'
  }
});

export default instance;
